// @flow

import React, { type Component } from 'react';
import {
  EmbeddedList,
  KeyboardField,
  TabbedModal,
  type EditModalProps
} from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import _ from 'underscore';
import CanonTypeCategorizationModal from './CanonTypeCategorizationModal';
import CitationModal from './CitationModal';
import withDescriptions from './DataFieldDescription';
import DataFieldDropdown from './DataFieldDropdown';
import DescriptionLabel from './DescriptionLabel';
import FootnoteModal from './FootnoteModal';
import IssueCategorizationModal from './IssueCategorizationModal';
import layouts from '../utilities/customKeyboardLayouts';
import LocationModal from './LocationModal';
import ParticipationModal from './ParticipationModal';
import RationaleCategorizationModal from './RationaleCategorizationModal';
import TimespanCategorizationModal from './TimespanCategorizationModal';
import TimespanInfo from './TimespanInfo';

import type { Canon } from '../types/Canon';

type Props = EditModalProps & {
  children: Component<{}>,
  item: Canon,
  t: (key: string) => string
};

const CanonModal = (props: Props) => (
  <TabbedModal
    closeIcon
    onClose={props.onClose}
    as={Form}
    centered={false}
    className='canon-modal wide-modal'
    header={props.item.id
      ? props.t('CanonModal.title.edit')
      : props.t('CanonModal.title.add')}
    inlineTabs={false}
    open
    noValidate
    size='small'
  >
    <TabbedModal.Tab
      name={props.t('Common.tabs.details')}
    >
      <Form.Input
        autoFocus
        error={props.isError('title_english')}
        label={(
          <DescriptionLabel
            description={props.descriptions.title}
            htmlFor='title_english'
            label={props.t('CanonModal.labels.title.english')}
          />
        )}
        onChange={props.onTextInputChange.bind(this, 'title_english')}
        required={props.isRequired('title_english')}
        value={props.item.title_english || ''}
      />
      <KeyboardField
        error={props.isError('title_arabic')}
        id='title_arabic'
        label={props.t('CanonModal.labels.title.arabic')}
        layout={Arabic}
        name='title_arabic'
        onChange={props.onTextInputChange.bind(this, 'title_arabic')}
        required={props.isRequired('title_arabic')}
        value={props.item.title_arabic || ''}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </KeyboardField>
      <KeyboardField
        error={props.isError('title_transliteration')}
        id='title_transliteration'
        label={props.t('CanonModal.labels.title.transliteration')}
        layout={layouts.transliterationIjmes}
        name='title_transliteration'
        onChange={props.onTextInputChange.bind(this, 'title_transliteration')}
        required={props.isRequired('title_transliteration')}
        value={props.item.title_transliteration || ''}
      />
      <Form.Input
        error={props.isError('legal_formula_english')}
        label={(
          <DescriptionLabel
            description={props.descriptions.legal_formula}
            htmlFor='legal_formula_english'
            label={props.t('CanonModal.labels.legalFormula.english')}
          />
        )}
        onChange={props.onTextInputChange.bind(this, 'legal_formula_english')}
        required={props.isRequired('legal_formula_english')}
        value={props.item.legal_formula_english || ''}
      />
      <KeyboardField
        error={props.isError('legal_formula_arabic')}
        id='legal_formula_arabic'
        label={props.t('CanonModal.labels.legalFormula.arabic')}
        layout={Arabic}
        name='legal_formula_arabic'
        onChange={props.onTextInputChange.bind(this, 'legal_formula_arabic')}
        required={props.isRequired('legal_formula_arabic')}
        value={props.item.legal_formula_arabic || ''}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </KeyboardField>
      <KeyboardField
        error={props.isError('legal_formula_transliteration')}
        id='legal_formula_transliteration'
        label={props.t('CanonModal.labels.legalFormula.transliteration')}
        layout={layouts.transliterationIjmes}
        name='legal_formula_transliteration'
        onChange={props.onTextInputChange.bind(this, 'legal_formula_transliteration')}
        required={props.isRequired('legal_formula_transliteration')}
        value={props.item.legal_formula_transliteration || ''}
      />
      <DataFieldDropdown
        {...props}
        column='title_origin'
        label={(
          <DescriptionLabel
            description={props.descriptions.title_origin}
            htmlFor='title_origin'
            label={props.t('CanonModal.labels.titleOrigin')}
          />
        )}
        table='canons'
      />
      <Form.TextArea
        error={props.isError('meaning')}
        label={(
          <DescriptionLabel
            description={props.descriptions.meaning}
            htmlFor='meaning'
            label={props.t('CanonModal.labels.meaning')}
          />
        )}
        onChange={props.onTextInputChange.bind(this, 'meaning')}
        required={props.isRequired('meaning')}
        value={props.item.meaning || ''}
      />
      <DataFieldDropdown
        {...props}
        column='function'
        label={(
          <DescriptionLabel
            description={props.descriptions.function}
            htmlFor='function'
            label={props.t('CanonModal.labels.function')}
          />
        )}
        table='canons'
      />
      <DataFieldDropdown
        {...props}
        column='origin'
        label={(
          <DescriptionLabel
            description={props.descriptions.origin}
            htmlFor='origin'
            label={props.t('CanonModal.labels.origin')}
          />
        )}
        multiple
        table='canons'
      />
      <Form.TextArea
        error={props.isError('origin_detail')}
        label={(
          <DescriptionLabel
            description={props.descriptions.origin_detail}
            htmlFor='origin_detail'
            label={props.t('CanonModal.labels.originDetail')}
          />
        )}
        onChange={props.onTextInputChange.bind(this, 'origin_detail')}
        required={props.isRequired('origin_detail')}
        value={props.item.origin_detail || ''}
      />
      <Form.TextArea
        error={props.isError('application')}
        label={(
          <DescriptionLabel
            description={props.descriptions.application}
            htmlFor='application'
            label={props.t('CanonModal.labels.application')}
          />
        )}
        onChange={props.onTextInputChange.bind(this, 'application')}
        required={props.isRequired('application')}
        value={props.item.application || ''}
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.issues')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.issues.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.issues.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.issues.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }, {
          name: 'assigner',
          label: props.t('Common.issues.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Issue' })}
        modal={{
          component: IssueCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Canon'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('CanonModal.tabs.types')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('CanonModal.canonTypes.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('CanonModal.canonTypes.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('CanonModal.canonTypes.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }, {
          name: 'assigner',
          label: props.t('CanonModal.canonTypes.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'CanonType' })}
        modal={{
          component: CanonTypeCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Canon'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.rationales')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.rationales.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.rationales.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.rationales.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }, {
          name: 'assigner',
          label: props.t('Common.rationales.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Rationale' })}
        modal={{
          component: RationaleCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Canon'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.citations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'source',
          label: props.t('Common.citations.columns.source'),
          resolve: (c) => c.source.searchable_name
        }, {
          name: 'page_reference',
          label: props.t('Common.citations.columns.pageReference')
        }, {
          name: 'volume_reference',
          label: props.t('Common.citations.columns.volumeReference')
        }, {
          name: 'citation_type',
          label: props.t('Common.citations.columns.citationType')
        }]}
        items={props.item.citations}
        modal={{
          component: CitationModal
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'citations')}
        onSave={props.onSaveChildAssociation.bind(this, 'citations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.participations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.participations.columns.name'),
          resolve: (p) => (p.participant_type === 'Person' ? p.participant.searchable_name : p.participant.name_english)
        }, {
          name: 'participant_type',
          label: props.t('Common.participations.columns.type')
        }, {
          name: 'role',
          label: props.t('Common.participations.columns.role')
        }]}
        items={props.item.participations}
        modal={{
          component: ParticipationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Canon'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'participations')}
        onSave={props.onSaveChildAssociation.bind(this, 'participations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.locations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'place_english',
          label: props.t('Common.locations.columns.place.english'),
          resolve: (l) => l.place.name_english
        }, {
          className: 'arabic',
          name: 'place_arabic',
          label: props.t('Common.locations.columns.place.arabic'),
          resolve: (l) => l.place.name_arabic,
          hidden: true
        }, {
          name: 'place_transliteration',
          label: props.t('Common.locations.columns.place.transliteration'),
          resolve: (l) => l.place.name_transliteration,
          hidden: true
        }, {
          name: 'role',
          label: props.t('Common.locations.columns.role')
        }, {
          name: 'precision',
          label: props.t('Common.locations.columns.precision')
        }, {
          name: 'certainty',
          label: props.t('Common.locations.columns.certainty')
        }]}
        items={props.item.locations}
        modal={{
          component: LocationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Canon'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'locations')}
        onSave={props.onSaveChildAssociation.bind(this, 'locations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.timespans')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.timespans.columns.name'),
          render: (c) => <TimespanInfo timespan={c.category} />
        }, {
          name: 'assigner',
          label: props.t('Common.timespans.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Timespan' })}
        modal={{
          component: TimespanCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Canon'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.footnotes')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'label',
          label: props.t('Common.footnotes.columns.label')
        }, {
          name: 'content',
          label: props.t('Common.footnotes.columns.content')
        }]}
        items={props.item.footnotes}
        modal={{
          component: FootnoteModal
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'footnotes')}
        onSave={props.onSaveChildAssociation.bind(this, 'footnotes')}
        showRecordCount
      />
    </TabbedModal.Tab>
    { props.children }
  </TabbedModal>
);

export default withDescriptions(withTranslation()(CanonModal), 'canons');
