// @flow

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, Menu } from 'semantic-ui-react';
import CanonsList from '../components/CanonsList';
import CanonsService from '../services/Canons';

type Props = {
  t: (key: string) => string
};

const Tabs = {
  all: 0,
  primary: 1
};

/**
 * This component displays two lists: A list of all canons, and a list of any "primary" canons. A "primary" canon is
 * defined as any canon which is a member of a group of canons (variant or hierarchy) with a position of "0".
 *
 * In order to create a "primary" canon, the canon record must be added, then the membership to the group must be
 * created. Since a "primary" canon cannot be created directly from this view, we'll suppress the "Add" button from
 * the top of the list of "primary" canons.
 *
 * Instead of conditionally rendering the lists, both lists will be rendered and the inactive list will be hidden from
 * the DOM. This is done to retrieve the count of canons while loading the list, in order to prevent additional API
 * requests for counts.
 *
 * Similarly, when a record is removed from the "All" list, we'll also want to reload the "Primary" list to synchronize
 * the counts. This is done with the "saved" prop and state variables. The ListTable component will automatically handle
 * reloading the data when a record is added to or removed from the list, but we'll used the allSaved and primarySaved
 * state variables to trigger a save on the other list.
 *
 * @param props
 *
 * @returns {*}
 *
 * @constructor
 */
const Canons = (props: Props) => {
  const [tabIndex, setTabIndex] = useState(Tabs.all);
  const [allCount, setAllCount] = useState(0);
  const [allSaved, setAllSaved] = useState(0);
  const [primaryCount, setPrimaryCount] = useState(0);
  const [primarySaved, setPrimarySaved] = useState(0);

  return (
    <div
      className='canons'
    >
      <Menu
        tabular
      >
        <Menu.Item
          active={tabIndex === Tabs.all}
          name='all'
          onClick={() => setTabIndex(Tabs.all)}
        >
          { props.t('Canons.tabs.all') }
          <Label
            color='blue'
            content={allCount}
          />
        </Menu.Item>
        <Menu.Item
          active={tabIndex === Tabs.primary}
          name='primary'
          onClick={() => setTabIndex(Tabs.primary)}
        >
          { props.t('Canons.tabs.primary') }
          <Label
            color='red'
            content={primaryCount}
          />
        </Menu.Item>
      </Menu>
      <CanonsList
        onDelete={(canon) => (
          CanonsService
            .delete(canon)
            .then(() => setAllSaved((prevSaved) => prevSaved + 1))
        )}
        onLoad={(params) => (
          CanonsService
            .fetchAll(params)
            .then((response) => {
              setAllCount(response.data.list.count);
              return response;
            })
        )}
        onSave={(canon) => (
          CanonsService
            .save(canon)
            .then(() => setAllSaved((prevSaved) => prevSaved + 1))
        )}
        saved={primarySaved} // See class comment
        visible={tabIndex === Tabs.all}
      />
      <CanonsList
        allowAdd={false}
        onDelete={(canon) => (
          CanonsService
            .delete(canon)
            .then(() => setPrimarySaved((prevSaved) => prevSaved + 1))
        )}
        onLoad={(params) => (
          CanonsService
            .fetchAll({ ...params, primary: true })
            .then((response) => {
              setPrimaryCount(response.data.list.count);
              return response;
            })
        )}
        onSave={(canon) => (
          CanonsService
            .save(canon)
            .then(() => setPrimarySaved((prevSaved) => prevSaved + 1))
        )}
        saved={allSaved} // See class comment
        visible={tabIndex === Tabs.primary}
      />
    </div>
  );
};

export default withTranslation()(Canons);
