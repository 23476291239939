// @flow

import _ from 'underscore';

import type { Organization } from '../types/Organization';
import type { Source } from '../types/Source';

/**
 * Class for handling transforming holdings records.
 */
class Holdings {
  PAYLOAD_KEYS = [
    'id',
    'identifier',
    'identifier_type',
    'organization_id',
    'role',
    'source_id',
    '_destroy'
  ];

  /**
   * Returns the holdings for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param item
   *
   * @returns {{holdings: *}}
   */
  toPayload(item: Organization | Source) {
    return {
      holdings: _.map(item.holdings, (o) => _.pick(o, this.PAYLOAD_KEYS))
    };
  }
}

export default new Holdings();
