// @flow

import React from 'react';
import {
  ArrowButtons,
  AssociatedDropdown,
  FuzzyDate,
  type EditModalProps
} from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import CitationsService from '../services/Citations';
import Citations from '../transforms/Citations';
import FuzzyDateTransform from '../transforms/FuzzyDate';
import People from '../services/People';
import Person from '../transforms/Person';
import PersonModal from './PersonModal';
import Positions from '../services/Positions';
import Position from '../transforms/Position';
import PositionModal from './PositionModal';

import type { Occupation } from '../types/Occupation';

type Props = EditModalProps & {
  citeable_id: number,
  citeable_type: string,
  item: Occupation
};

const OccupationModal = (props: Props) => (
  <Modal
    as={Form}
    className='place-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('OccupationModal.title.edit')
        : props.t('OccupationModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('position_id')}
        label={props.t('OccupationModal.labels.position')}
        required={props.isRequired('position_id')}
      >
        <AssociatedDropdown
          collectionName='positions'
          modal={{
            component: PositionModal,
            onSave: (position) => Positions.save(position).then(({ data }) => data.position)
          }}
          onSearch={(search) => Positions.fetchAll({ search, sort_by: 'name->>english' })}
          onSelection={props.onAssociationInputChange.bind(this, 'position_id', 'position')}
          renderOption={Position.toDropdown.bind(this)}
          searchQuery={props.item.position && props.item.position.name_english}
          value={props.item.position_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('appointer_id')}
        label={props.t('OccupationModal.labels.appointer')}
        required={props.isRequired('appointer_id')}
      >
        <AssociatedDropdown
          collectionName='people'
          modal={{
            component: PersonModal,
            onSave: (person) => People.save(person).then(({ data }) => data.person)
          }}
          onSearch={(search) => People.fetchAll({ search, sort_by: 'searchable_name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'appointer_id', 'appointer')}
          renderOption={Person.toDropdown.bind(this)}
          searchQuery={props.item.appointer && props.item.appointer.searchable_name}
          value={props.item.appointer_id}
        />
      </Form.Input>
      <Form.Group
        widths='equal'
      >
        <Form.Input
          error={props.isError('start_date_ah')}
          label={props.t('OccupationModal.labels.startDateAh')}
          required={props.isRequired('start_date_ah')}
        >
          <FuzzyDate
            calendar='hijri'
            date={FuzzyDateTransform.toFuzzyDate(props.item.start_date_ah)}
            onChange={(data) => props.onSetState({
              start_date_ah: FuzzyDateTransform.toRelated(props.item, 'start_date_ah', data)
            })}
            title={props.t('OccupationModal.labels.startDateAh')}
          />
        </Form.Input>
        <ArrowButtons
          onLeft={() => props.onSetState({
            start_date_ah: FuzzyDateTransform.toCopy(props.item.start_date_ce)
          })}
          onRight={() => props.onSetState({
            start_date_ce: FuzzyDateTransform.toCopy(props.item.start_date_ah)
          })}
        />
        <Form.Input
          error={props.isError('start_date_ce')}
          label={props.t('OccupationModal.labels.startDateCe')}
          required={props.isRequired('start_date_ce')}
        >
          <FuzzyDate
            date={FuzzyDateTransform.toFuzzyDate(props.item.start_date_ce)}
            onChange={(data) => props.onSetState({
              start_date_ce: FuzzyDateTransform.toRelated(props.item, 'start_date_ce', data)
            })}
            title={props.t('OccupationModal.labels.startDateCe')}
          />
        </Form.Input>
      </Form.Group>
      <Form.Group
        widths='equal'
      >
        <Form.Input
          error={props.isError('end_date_ah')}
          label={props.t('OccupationModal.labels.endDateAh')}
          required={props.isRequired('end_date_ah')}
        >
          <FuzzyDate
            calendar='hijri'
            date={FuzzyDateTransform.toFuzzyDate(props.item.end_date_ah)}
            onChange={(data) => props.onSetState({
              end_date_ah: FuzzyDateTransform.toRelated(props.item, 'end_date_ah', data)
            })}
            title={props.t('OccupationModal.labels.endDateAh')}
          />
        </Form.Input>
        <ArrowButtons
          onLeft={() => props.onSetState({
            end_date_ah: FuzzyDateTransform.toCopy(props.item.end_date_ce)
          })}
          onRight={() => props.onSetState({
            end_date_ce: FuzzyDateTransform.toCopy(props.item.end_date_ah)
          })}
        />
        <Form.Input
          error={props.isError('end_date_ce')}
          label={props.t('OccupationModal.labels.endDateCe')}
          required={props.isRequired('end_date_ce')}
        >
          <FuzzyDate
            date={FuzzyDateTransform.toFuzzyDate(props.item.end_date_ce)}
            onChange={(data) => props.onSetState({
              end_date_ce: FuzzyDateTransform.toRelated(props.item, 'end_date_ce', data)
            })}
            title={props.t('OccupationModal.labels.endDateCe')}
          />
        </Form.Input>
      </Form.Group>
      <Form.TextArea
        error={props.isError('narrative')}
        label={props.t('OccupationModal.labels.narrative')}
        onChange={props.onTextInputChange.bind(this, 'narrative')}
        required={props.isRequired('narrative')}
        value={props.item.narrative || ''}
      />
      { props.citeable_id && props.citeable_type && (
        <Form.Input
          error={props.isError('citation_id')}
          label={props.t('OccupationModal.labels.citation')}
          required={props.isRequired('citation_id')}
        >
          <AssociatedDropdown
            collectionName='citations'
            onSearch={(search) => CitationsService.fetchAll({
              search,
              sort_by: 'sources.searchable_name',
              citeable_id: props.citeable_id,
              citeable_type: props.citeable_type
            })}
            onSelection={props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
            renderOption={Citations.toDropdown.bind(this)}
            searchQuery={props.item.citation
            && props.item.citation.source
            && props.item.citation.source.searchable_name}
            value={props.item.citation_id}
          />
        </Form.Input>
      )}
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(OccupationModal);
