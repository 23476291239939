import React from 'react';
import _ from 'underscore';

const formatArrayValue = (arr) => (_.isEmpty(arr)
  ? ''
  : arr.map((el) => {
    // if el is falsy or only contains whitespace, render nothing
    if (el) {
      if (el.toString().replace(/\s/g, '').length) {
        return (
          <p>
            {arr.length > 1 ? '\u27a3 ' : ''}
            {el}
          </p>
        );
      }
      return '';
    }
    return '';
  }));

export default formatArrayValue;
