// @flow

import React, { Component } from 'react';
import { AssociatedDropdown, type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import CanonModal from './CanonModal';
import Canons from '../services/Canons';
import Canon from '../transforms/Canon';
import CaseModal from './CaseModal';
import Cases from '../services/Cases';
import Case from '../transforms/Case';
import CitationsService from '../services/Citations';
import Citations from '../transforms/Citations';

import type { Reference } from '../types/Reference';

type Props = EditModalProps & {
  item: Reference
};

type State = {
  renderType: boolean
};

const REFERENT_TYPE_CANON = 'Canon';
const REFERENT_TYPE_CASE = 'Case';

class ReferenceModal extends Component<Props, State> {
  /**
   * Constructs a new ReferenceModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      renderType: !props.item.referent_type
    };
  }

  /**
   * Renders the ReferenceModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        as={Form}
        className='reference-modal'
        closeIcon
        onClose={this.props.onClose}
        open
        size='small'
      >
        <Modal.Header
          content={this.props.item.id
            ? this.props.t('ReferenceModal.title.edit')
            : this.props.t('ReferenceModal.title.add')}
        />
        <Modal.Content>
          { this.renderReferentTypeDropdown() }
          { this.renderCanonDropdown() }
          { this.renderCaseDropdown() }
          { this.renderExplicitCheckbox() }
          { this.renderCitationDropdown() }
        </Modal.Content>
        { this.props.children }
      </Modal>
    );
  }

  /**
   * Renders the canon dropdown.
   *
   * @returns {null|*}
   */
  renderCanonDropdown() {
    if (this.props.item.referent_type !== REFERENT_TYPE_CANON) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('referent_id')}
        label={this.props.t('ReferenceModal.labels.canon')}
        required={this.props.isRequired('referent_id')}
      >
        <AssociatedDropdown
          className='arabic'
          collectionName='canons'
          modal={{
            component: CanonModal,
            onSave: (canon) => Canons.save(canon).then(({ data }) => data.canon)
          }}
          onSearch={(search) => Canons.fetchAll({ search, sort_by: 'legal_formula->>english' })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'referent_id', 'referent')}
          renderOption={Canon.toDropdown.bind(this)}
          searchQuery={this.props.item.referent && this.props.item.referent.legal_formula_arabic}
          value={this.props.item.referent_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the case dropdown.
   *
   * @returns {null|*}
   */
  renderCaseDropdown() {
    if (this.props.item.referent_type !== REFERENT_TYPE_CASE) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('referent_id')}
        label={this.props.t('ReferenceModal.labels.case')}
        required={this.props.isRequired('referent_id')}
      >
        <AssociatedDropdown
          collectionName='cases'
          modal={{
            component: CaseModal,
            onSave: (c) => Cases.save(c).then(({ data }) => data.case)
          }}
          onSearch={(search) => Cases.fetchAll({ search, sort_by: 'searchable_name' })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'referent_id', 'referent')}
          renderOption={Case.toDropdown.bind(this)}
          searchQuery={this.props.item.referent && this.props.item.referent.searchable_name}
          value={this.props.item.referent_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the citation dropdown component.
   *
   * @returns {null|*}
   */
  renderCitationDropdown() {
    if (!(this.props.citeable_id && this.props.citeable_type) || !this.props.item.referent_type) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('citation_id')}
        label={this.props.t('ReferenceModal.labels.citation')}
        required={this.props.isRequired('citation_id')}
      >
        <AssociatedDropdown
          collectionName='citations'
          onSearch={(search) => CitationsService.fetchAll({
            search,
            sort_by: 'sources.searchable_name',
            citeable_id: this.props.citeable_id,
            citeable_type: this.props.citeable_type
          })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
          renderOption={Citations.toDropdown.bind(this)}
          searchQuery={this.props.item.citation
            && this.props.item.citation.source
            && this.props.item.citation.source.searchable_name}
          value={this.props.item.citation_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the explicit checkbox.
   *
   * @returns {null|*}
   */
  renderExplicitCheckbox() {
    if (!this.props.item.referent_type) {
      return null;
    }

    return (
      <Form.Checkbox
        checked={this.props.item.explicit}
        label={this.props.t('ReferenceModal.labels.explicit')}
        onChange={this.props.onCheckboxInputChange.bind(this, 'explicit')}
      />
    );
  }

  /**
   * Renders the referent_type dropdown.
   *
   * @returns {null|*}
   */
  renderReferentTypeDropdown() {
    if (!this.state.renderType) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('referent_type')}
        label={this.props.t('ReferenceModal.labels.type')}
        required={this.props.isRequired('referent_type')}
      >
        <Dropdown
          fluid
          selection
          options={[{
            key: REFERENT_TYPE_CANON,
            value: REFERENT_TYPE_CANON,
            text: REFERENT_TYPE_CANON
          }, {
            key: REFERENT_TYPE_CASE,
            value: REFERENT_TYPE_CASE,
            text: REFERENT_TYPE_CASE
          }]}
          onChange={this.props.onTextInputChange.bind(this, 'referent_type')}
          value={this.props.referent_type}
        />
      </Form.Input>
    );
  }
}

export default withTranslation()(ReferenceModal);
