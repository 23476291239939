// @flow

import BaseService from './BaseService';
import Fatwa from '../transforms/Fatwa';

/**
 * Service class for all fatwa based API calls.
 */
class Fatwas extends BaseService {
  /**
   * Returns the fatwas base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/fatwas';
  }

  /**
   * Returns the fatwas transform object.
   *
   * @returns {Case}
   */
  getTransform() {
    return Fatwa;
  }
}

export default new Fatwas();
