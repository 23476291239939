// @flow

import React from 'react';
import {
  AssociatedDropdown,
  EmbeddedList,
  Selectize,
  type EditModalProps
} from 'react-components';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Icon,
  Modal
} from 'semantic-ui-react';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import MembershipModal from './MembershipModal';
import People from '../services/People';
import Person from '../transforms/Person';
import './GroupModal.css';

import type { GroupModalProps } from './GroupModal';
import type { Group } from '../types/Group';

type Props = EditModalProps & GroupModalProps & {
  item: Group
};

const PersonGroupModal = (props: Props) => (
  <Modal
    as={Form}
    className={`person-group-modal ${props.className ? props.className : ''}`}
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('PersonGroupModal.title.edit')
        : props.t('PersonGroupModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('name_english')}
        label={props.t('PersonGroupModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name_english')}
        required={props.isRequired('name_english')}
        value={props.item.name_english || ''}
      />
      <DataFieldDropdown
        {...props}
        column='group_type'
        label={props.t('PersonGroupModal.labels.type')}
        table='groups'
      />
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        buttons={[{
          render: () => (
            <Button
              basic
              content={props.t('Common.buttons.multiAdd')}
              icon='plus'
              key='source'
              onClick={props.onMultiAdd.bind(this)}
            />
          )
        }]}
        columns={[{
          className: 'drag-drop-icon',
          name: 'move',
          label: '',
          render: () => <Icon color='grey' name='bars' />
        }, {
          name: 'name',
          label: props.t('PersonGroupModal.memberships.columns.name'),
          resolve: (m) => m.memberable.searchable_name
        }, {
          name: 'assigner',
          label: props.t('PersonGroupModal.memberships.columns.assigner')
        }]}
        items={props.item.memberships}
        modal={{
          component: MembershipModal,
          props: {
            defaults: {
              assigner: 'Researcher',
              memberable_type: 'Person'
            },
            renderDropdown: (modalProps) => (
              <AssociatedDropdown
                collectionName='people'
                onSearch={(search) => People.fetchAll({ search, sort_by: 'searchable_name' })}
                onSelection={modalProps.onAssociationInputChange.bind(this, 'memberable_id', 'memberable')}
                renderOption={Person.toDropdown.bind(this)}
                searchQuery={modalProps.item.memberable && modalProps.item.memberable.searchable_name}
                value={modalProps.item.memberable_id}
              />
            )
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'memberships')}
        onDrag={props.onDrag.bind(this)}
        onSave={props.onSaveChildAssociation.bind(this, 'memberships')}
        showRecordCount
      />
      {
        props.showMultiAdd && (
          <Selectize
            collectionName='people'
            onClose={props.onCloseMultiAdd.bind(this)}
            onLoad={(params) => People.fetchAll(_.extend(params, { sort_by: 'searchable_name' }))}
            onSave={props.onSaveMultiAdd.bind(this, 'Person')}
            renderItem={(person) => person.searchable_name}
            selectedItems={_.map(props.item.memberships, (m) => m.memberable)}
            title={props.t('PersonGroupModal.title.selectPeople')}
          />
        )
      }
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(PersonGroupModal);
