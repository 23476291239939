// @flow

import BaseService from './BaseService';
import Issue from '../transforms/Issue';

/**
 * Service class for all issue based API calls.
 */
class Issues extends BaseService {
  /**
   * Returns the issues base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/issues';
  }

  /**
   * Returns the issues transform object.
   *
   * @returns {Issue}
   */
  getTransform() {
    return Issue;
  }
}

export default new Issues();
