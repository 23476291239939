// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import BibliographyDetail from '../components/BibliographyDetail';
import BibliographyService from '../services/Bibliography';

type Props = {
    match: {params: {id: number}}
  };

const Bibliography = (props: Props) => (
  <BibliographyDetail
    item={{ id: props.match.params.id }}
    onInitialize={(id) => BibliographyService.fetchOne(id).then(({ data }) => data.bibliography)}
  />
);

export default (withRouter(Bibliography));
