// @flow

import React, { Component, type ComponentType } from 'react';
import _ from 'underscore';
import DataFields from '../services/DataFields';

type State = {
  descriptions: any
};

/**
 * Function to return a new class which will render the descriptions for individual data fields. When the component
 * mounts, the /api/data_fields endpoint is called and the map of column names to descriptions is stored on the state.
 *
 * This function can be used as follows:
 *
 * <code>
 *   const MyComponent = (props) => (
 *     <div>{ props.descriptions.myField }</div>
 *   );
 *
 *   export default withDescriptions(MyComponent);
 * </code>
 *
 * @param WrappedComponent
 * @param table
 *
 * @returns {{new(*): {componentDidMount(): void, render(): *}, prototype: {componentDidMount(): void, render(): *}}}
 */
const withDescriptions = (WrappedComponent: ComponentType<any>, table: string) => (
  class extends Component<{}, State> {
    /**
     * Constructs a new class to hold the descriptions for the passed <code>table</code>.
     *
     * @param props
     */
    constructor(props: any) {
      super(props);

      this.state = {
        descriptions: {}
      };
    }

    /**
     * Loads the data fields for the passed table and sets the descriptions on the state.
     */
    componentDidMount() {
      DataFields
        .fetchAll({ table })
        .then(({ data }) => {
          const descriptions = {};

          _.each(data.data_fields, (df) => {
            descriptions[df.column_name] = df.description;
          });

          this.setState({ descriptions });
        });
    }

    /**
     * Renders the wrapped component including the descriptions as a prop.
     *
     * @returns {*}
     */
    render() {
      return (
        <WrappedComponent
          {...this.props}
          descriptions={this.state.descriptions}
        />
      );
    }
  }
);

export default withDescriptions;
