import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import App from './pages/App';

import 'semantic-ui-css/semantic.css';
import './config/Api';
import './index.css';
import '../node_modules/react-components/build/main.css';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);
