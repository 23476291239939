// @flow

import React, { type ComponentType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, type RouterHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

type Props = WithTranslation & {
  history: RouterHistory,
  location: {
    pathname: string
  },
  component: ComponentType<any>,
};

const ListPage = (props: Props) => {
  const ListPageComponent = props.component;
  const key = props.location.pathname.replace(/entry/g, '').replace(/\//g, '');

  return (
    <>
      <Header
        as='h1'
      >
        { props.t(`Entry.menu.${key}`) }
      </Header>
      <ListPageComponent />
    </>
  );
};

export default withTranslation()(withRouter(ListPage));
