// @flow

import React, { Component } from 'react';
import {
  Container,
  Grid,
  Icon,
  Segment
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { withRouter, type RouterHistory } from 'react-router-dom';
import './Rights.css';

type Props = {
  history: RouterHistory,
  t: (key: string) => string
}

type State = {
  loginFailed: boolean,
  password: ?string,
  showModal: boolean,
  username: ?string
}

class Rights extends Component<Props, State> {
  /**
   * Constructs a new Public view.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  /**
   * Renders the Public view.
   *
   * @returns {*}
   */
  render() {
    return (
      <Segment
        className='rights'
        textAlign='center'
        vertical
      >
        <Container
          className='content'
        >
          <Segment
            padded='very'
          >
            <Grid
              columns={1}
            >
              <Grid.Row
                verticalAlign='middle'
              >
                <Grid.Column
                  as='h1'
                  textAlign='left'
                >
                  <Icon
                    bordered
                    circular
                    inverted
                    name='balance'
                    size='small'
                  />
                  Intellectual Property Rights
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  as='div'
                  textAlign='justified'
                >
                  <p>The SHARIAsource Platform, including Courts & Canons (CnC), and its inherent features and functionality (including but not limited to all information, software, text, displays, images, video, and audio, together with the design, selection and arrangement thereof) ['Software'], excluding Creative Material as described in the Contributor Agreement in the <a href="https://www.dropbox.com/preview/PIL%20DROPBOX/1.%20PIL%20Admin/10.%20Policies%2C%20Guides/PIL%20Terms%20of%20Use.pdf?role=work">PIL Terms of Use</a>, are owned by Harvard University, and SHARIAsource at Harvard Law School; its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws This platform is protected by a Creative Commons Attribution-Non-Commercial (CC BY-NC) 4.0 International License.</p> 
                  <p>Consistent with the Contributor Agreement in the <a href="https://www.dropbox.com/preview/PIL%20DROPBOX/1.%20PIL%20Admin/10.%20Policies%2C%20Guides/PIL%20Terms%20of%20Use.pdf?role=work">PIL Terms of Use</a> and the <a href="https://www.dropbox.com/preview/PIL%20DROPBOX/1.%20PIL%20Admin/10.%20Policies%2C%20Guides/PIL%20Copyright%20Policy.pdf?role=work">PIL Copyright Policy</a>, Users and Contributors are free to copy and redistribute both the Software and the Creative Material in any medium or format and are free to remix, transform, and build upon each. Neither SHARIAsource nor the User or Contributor can revoke these freedoms as long as you follow the CC BY-NC license terms. You must give appropriate credit, provide a link to the license, and indicate if changes were made to the original Creative Material. You may do so in any reasonable manner, but not in any way that suggests SHARIAsource or any particular User or Contributor endorses you or your use. You also may not use the Software or Creative Material for commercial purposes. Finally, you may not apply legal terms or technological measures that legally restrict others from using the Creative Material in a manner permissible under the CC BY-NC.</p>     
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Segment>
    );
  }
}

export default withTranslation()(withRouter(Rights));
