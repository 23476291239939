// @flow

import _ from 'underscore';
import type { Participateable } from '../types/concerns/Participateable';

/**
 * Class for handling transforming participation records.
 */
class Participations {
  PAYLOAD_KEYS = [
    'id',
    'participant_id',
    'participant_type',
    'position_id',
    'citation_id',
    'role',
    'description',
    'certainty',
    '_destroy'
  ];

  /**
   * Returns the participations for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param participateable
   *
   * @returns {{participations: *}}
   */
  toPayload(participateable: Participateable) {
    return {
      participations: _.map(participateable.participations, (p) => _.pick(p, this.PAYLOAD_KEYS))
    };
  }
}

export default new Participations();
