// @flow

import BaseService from './BaseService';

/**
 * Service class for all genre people based API calls.
 */
class GenrePeople extends BaseService {
  /**
   * Returns the genre people base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/genre_people';
  }
}

export default new GenrePeople();
