// @flow

import _ from "underscore";
import axios from "axios";
import React, { useState } from "react";
import { ListTable } from "react-components";
import { withTranslation } from "react-i18next";
import { Button, Form, Modal, Select } from "semantic-ui-react";
import ImportSourceStepper from "./ImportSourceStepper";
import "./AddExternalSourceModal.css";
import formatArrayValue from "../utilities/formatArrayValue";

const concatenateArray = (value) =>
  _.isArray(value) ? value.join(" | ") : value;
const convertToSourceObject = (externalSource) => {
  const pubDate =
    !_.isEmpty(externalSource.pub_date_ssim) && externalSource.pub_date_ssim
      ? new Date(externalSource.pub_date_ssim[0], 0, 1)
      : null;

  return {
    title: concatenateArray(externalSource.title_ssm),
    short_title: concatenateArray(externalSource.title_ssm),
    language: concatenateArray(externalSource.language_ssim),
    source_type: concatenateArray(externalSource.format).toLowerCase(),
    call_number: concatenateArray(externalSource.lc_callnum_ssm),
    isbn: concatenateArray(externalSource.isbn_tsim),
    issn: concatenateArray(externalSource.issn_ssim),
    series_title: concatenateArray(externalSource.title_series_tsim),
    series_editor: null,
    edition: concatenateArray(externalSource.edition_ssim),
    publication_year: concatenateArray(externalSource.pub_date_ssim),
    publication_date_ce: pubDate
      ? {
          start_date: pubDate,
          end_date: pubDate,
        }
      : null,
    stacklife_id: externalSource.id,
  };
};

type Props = {
  open: boolean,
  onClose: () => void,
  t: (key: string) => string,
};

const AddExternalSourceModal = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [finalSearchTerm, setFinalSearchTerm] = useState("");
  const [selectedSearchCategory, setSelectedSearchCategory] =
    useState("all_fields");
  const [finalSearchCategory, setFinalSearchCategory] = useState("");
  const [selectedExternalSource, setSelectedExternalSource] = useState({});
  const [sourceToImport, setSourceToImport] = useState({});
  const [isImportStepperOpen, setIsImportStepperOpen] = useState(false);
  const [rawExternalSource, setRawExternalSource] = useState({});
  const [selectedPerson, setSelectedPerson] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [resultCount, setResultCount] = useState(0);

  const searchCategories = [
    {
      key: "all_fields",
      text: props.t("AddExternalSourceModal.labels.allFields"),
      value: "all_fields",
    },
    {
      key: "title",
      text: props.t("AddExternalSourceModal.labels.title"),
      value: "title",
    },
    {
      key: "author",
      text: props.t("AddExternalSourceModal.labels.author"),
      value: "author",
    },
    {
      key: "subject",
      text: props.t("AddExternalSourceModal.labels.subject"),
      value: "subject",
    },
  ];

  const handleSearch = () => {
    if (searchTerm) {
      setFinalSearchTerm(searchTerm);
      setFinalSearchCategory(selectedSearchCategory);
    }
  };

  const stackLifeApiBaseUrl = process.env.REACT_APP_STACKLIFE_SEARCH_API_URL
    ? process.env.REACT_APP_STACKLIFE_SEARCH_API_URL
    : "";

  const handleImportSource = () => {
    setIsImportStepperOpen(true);
    const url = new URL(`${stackLifeApiBaseUrl}${selectedExternalSource.id}`);
    const params = {};
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setRawExternalSource(data.document);
        setSourceToImport(convertToSourceObject(data.document));
      });
  };

  const handleClose = () => {
    setSearchTerm("");
    setSelectedExternalSource({});
    setFinalSearchTerm("");
    setSelectedPerson({});
    props.onClose();
  };

  return (
    <>
      <Modal
        className="add-external-source-modal"
        onClose={handleClose}
        open={props.open}
        as={Form}
        dimmer="inverted"
      >
        <Modal.Header>{props.t("AddExternalSourceModal.header")}</Modal.Header>
        <Modal.Content className="content">
          <Form.Input
            type="text"
            autoFocus
            action
            placeholder={props.t(
              "AddExternalSourceModal.labels.searchPlaceholder"
            )}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
          >
            <input />
            <Select
              compact
              options={searchCategories}
              value={selectedSearchCategory}
              onChange={(e, { value }) => {
                setSelectedSearchCategory(value);
              }}
            />
            <Button onClick={handleSearch} color={searchTerm ? "green" : ""}>
              {props.t("AddExternalSourceModal.buttons.search")}
            </Button>
          </Form.Input>
          {finalSearchTerm && (
            <ListTable
              searchable={false}
              selectable
              buttons={[
                {
                  render: () => (
                    <p>
                      {resultCount}{" "}
                      {props.t(
                        `AddExternalSourceModal.${
                          resultCount !== 1 ? "results" : "result"
                        }`
                      )}
                    </p>
                  ),
                },
              ]}
              onRowSelect={({ checked }, source) => {
                if (checked) {
                  setSelectedExternalSource(source);
                } else {
                  setSelectedExternalSource({});
                  setSourceToImport({});
                }
              }}
              selectedRows={[selectedExternalSource]}
              columns={[
                {
                  name: "title_ssm",
                  label: props.t("AddExternalSourceModal.columns.title"),
                  render: (es) => formatArrayValue(es.title_ssm),
                  sortable: false,
                },
                {
                  name: "author_ssm",
                  label: props.t("AddExternalSourceModal.columns.author"),
                  render: (es) => formatArrayValue(es.author_ssm),
                  sortable: false,
                },
                {
                  name: "author_death_date_isim",
                  label: props.t(
                    "AddExternalSourceModal.columns.authorDeathDate"
                  ),
                  render: (es) => formatArrayValue(es.author_death_date_isim),
                  sortable: false,
                },
                {
                  name: "pub_date_ssim",
                  label: props.t(
                    "AddExternalSourceModal.columns.publicationDate"
                  ),
                  render: (es) => formatArrayValue(es.pub_date_ssim),
                  sortable: false,
                },
                {
                  name: "published_ssim",
                  label: props.t(
                    "AddExternalSourceModal.columns.publicationPlace"
                  ),
                  render: (es) => formatArrayValue(es.published_ssim),
                  sortable: false,
                },
              ]}
              key={finalSearchTerm + finalSearchCategory}
              // set key prop so table re-renders on each new search
              collectionName="documents"
              onLoad={(params) => {
                if (finalSearchTerm) {
                  const url = new URL(stackLifeApiBaseUrl);
                  const searchParams = new URLSearchParams({
                    q: finalSearchTerm,
                    search_field: selectedSearchCategory,
                    page: params.page,
                  });
                  url.search = searchParams.toString();
                  return axios.get(url).then((resp) => {
                    setResultCount(resp.data.list.count);
                    return resp;
                  });
                }
                return Promise.resolve({
                  data: { documents: [], list: { pages: 0 } },
                });
              }}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={_.isEmpty(selectedExternalSource)}
            onClick={handleImportSource}
            primary
            loading={isImportStepperOpen && _.isEmpty(sourceToImport)}
          >
            {props.t("AddExternalSourceModal.buttons.startImport")}
          </Button>
          <Button color="blue" basic onClick={handleClose}>
            {props.t("Common.buttons.cancel")}
          </Button>
        </Modal.Actions>
      </Modal>
      {isImportStepperOpen && !_.isEmpty(sourceToImport) && (
        <ImportSourceStepper
          onClose={() => {
            setIsImportStepperOpen(false);
          }}
          onResetSearch={() => setFinalSearchTerm("")}
          open={isImportStepperOpen}
          rawExternalSource={rawExternalSource}
          selectedPerson={selectedPerson}
          selectedPlace={selectedPlace}
          setSelectedPerson={setSelectedPerson}
          setSelectedPlace={setSelectedPlace}
          setSourceToImport={setSourceToImport}
          sourceToImport={sourceToImport}
        />
      )}
    </>
  );
};

export default withTranslation()(AddExternalSourceModal);
