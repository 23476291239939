// @flow

import React from 'react';
import { Form } from 'semantic-ui-react';
import { RemoteDropdown, type EditModalProps } from 'react-components';
import _ from 'underscore';
import Session from '../services/Session';
import DataFieldOptions from '../services/DataFieldOptions';
import DataFieldOption from '../transforms/DataFieldOption';

type Props = EditModalProps & {
  allowAdditions?: boolean,
  column: string,
  label: string,
  multiple?: boolean,
  table: string
};

const DataFieldDropdown = (props: Props) => (
  <Form.Input
    error={props.isError(props.column)}
    label={props.label}
    required={props.isRequired(props.column)}
  >
    <RemoteDropdown
      allowAdditions={Session.isAdmin() && props.allowAdditions}
      collectionName='data_field_options'
      fluid
      multiple={props.multiple}
      onAddItem={(value) => DataFieldOptions.save({ table: props.table, column: props.column, value })}
      onLoad={(params) => DataFieldOptions.fetchAll(_.extend(params, {
        table: props.table,
        column: props.column,
        sort_by: 'value'
      }))}
      onSelection={(value) => props.onTextInputChange(props.column, null, { value })}
      renderOption={DataFieldOption.toDropdown.bind(this)}
      value={props.item[props.column] || ''}
    />
  </Form.Input>
);

DataFieldDropdown.defaultProps = {
  allowAdditions: true,
  multiple: false
};

export default DataFieldDropdown;
