// @flow

import "./Entry.css";

import { Container, Grid, Header, Menu } from "semantic-ui-react";
import {
  Link,
  Redirect,
  Route,
  type RouterHistory,
  Switch,
  withRouter,
} from "react-router-dom";

import React from "react";
import _ from "underscore";
import { withTranslation } from "react-i18next";
import Authentication from "../services/Authentication";
import Bibliographies from "./Bibliographies";
import CanonGroups from "./CanonGroups";
import CanonTypes from "./CanonTypes";
import Canons from "./Canons";
import Cases from "./Cases";
import Fatwas from "./Fatwas";
import Genre from "./Genre";
import Genres from "./Genres";
import Issues from "./Issues";
import ListPage from "../components/ListPage";
import People from "./People";
import PersonGroups from "./PersonGroups";
import Places from "./Places";
import Positions from "./Positions";
import Organizations from "./Organizations";
import Rationales from "./Rationales";
import Rulings from "./Rulings";
import Session from "../services/Session";
import Sources from "./Sources";
import Timespans from "./Timespans";
import Bibliography from "./Bibliography";
import Projects from "./Projects";

type Props = {
  history: RouterHistory,
  location: {
    pathname: string,
  },
  t: (key: string) => string,
};

const MENU = {
  laws: {
    canons: "canons",
    cases: "cases",
    fatwas: "fatwas",
    rulings: "rulings",
  },
  research: {
    genres: "genres",
    bibliographies: "bibliographies",
    projects: "projects",
  },
  resources: {
    people: "people",
    sources: "sources",
    places: "places",
    timespans: "timespans",
    organizations: "organizations",
  },
  categories: {
    issues: "issues",
    types: "types",
    rationales: "rationales",
    positions: "positions",
  },
  collections: {
    canons: "groups/canons",
    people: "groups/people",
  },
};

const Entry = ({ history, location, t }: Props) => {
  /**
   * Logs the user out and removes the current session.
   */
  const onLogoutButton = () => {
    Authentication.logout().then(() => {
      Session.destroy();
      history.push("/");
    });
  };

  /**
   * Renders the administrator menu item.
   *
   * @returns {null|*}
   */
  const renderAdminMenuItem = () => {
    if (!Session.isAdmin()) {
      return null;
    }

    return (
      <Menu.Item
        content={t("Entry.menu.admin")}
        icon="database"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "/admin";
        }}
      />
    );
  };

  /**
   * Renders the MenuItem for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderMenuItem = (key, path) => (
    <Menu.Item
      active={!!location.pathname.match(`/entry/${path}`)}
      as={Link}
      key={key}
      to={`/entry/${path}/`}
    >
      {t(`Entry.menu.${key}`)}
    </Menu.Item>
  );

  /**
   * Renders the side menu bar based on the <code>MENU</code> object.
   *
   * @returns {*}
   */
  const renderMenus = () => (
    <>
      {_.map(Object.keys(MENU), (section) => (
        <Menu.Menu key={section}>
          <Menu.Item>
            <Header content={t(`Entry.menu.${section}`)} inverted />
          </Menu.Item>
          {_.map(Object.keys(MENU[section]), (key) =>
            renderMenuItem(key, MENU[section][key])
          )}
        </Menu.Menu>
      ))}
    </>
  );

  /**
   * Renders the Entry page.
   *
   * @returns {*}
   */
  return (
    <Grid className="root-container" padded="horizontally" stretched>
      <Grid.Column width={3}>
        <Menu fixed="left" inverted vertical>
          <Menu.Item>
            <Header content={t("Entry.title")} icon="balance" inverted />
          </Menu.Item>
          <Menu.Menu>
            {renderAdminMenuItem()}
            <Menu.Item
              content={t("Entry.menu.logout")}
              icon="log out"
              onClick={onLogoutButton.bind(this)}
            />
          </Menu.Menu>
          {renderMenus()}
        </Menu>
      </Grid.Column>
      <Grid.Column className="content-container" width={13}>
        <Container className="main-container">
          <Switch>
            <Route path="/entry/types/">
              <ListPage component={CanonTypes} />
            </Route>
            <Route path="/entry/canons/">
              <ListPage component={Canons} />
            </Route>
            <Route path="/entry/cases/">
              <ListPage component={Cases} />
            </Route>
            <Route path="/entry/fatwas/">
              <ListPage component={Fatwas} />
            </Route>
            <Route path="/entry/genres/:id" component={Genre} />
            <Route path="/entry/genres">
              <ListPage component={Genres} />
            </Route>
            <Route path="/entry/projects">
              <ListPage component={Projects} />
            </Route>
            <Route path="/entry/bibliographies/:id" component={Bibliography} />
            <Route path="/entry/bibliographies">
              <ListPage component={Bibliographies} />
            </Route>
            <Route path="/entry/groups/canons">
              <ListPage component={CanonGroups} />
            </Route>
            <Route path="/entry/groups/people">
              <ListPage component={PersonGroups} />
            </Route>
            <Route path="/entry/issues/">
              <ListPage component={Issues} />
            </Route>
            <Route path="/entry/people/">
              <ListPage component={People} />
            </Route>
            <Route path="/entry/places/">
              <ListPage component={Places} />
            </Route>
            <Route path="/entry/positions/">
              <ListPage component={Positions} />
            </Route>
            <Route path="/entry/organizations/">
              <ListPage component={Organizations} />
            </Route>
            <Route path="/entry/rationales">
              <ListPage component={Rationales} />
            </Route>
            <Route path="/entry/rulings/">
              <ListPage component={Rulings} />
            </Route>
            <Route path="/entry/sources/">
              <ListPage component={Sources} />
            </Route>
            <Route path="/entry/timespans/">
              <ListPage component={Timespans} />
            </Route>
            <Redirect to="/entry/canons" />
          </Switch>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

export default withTranslation()(withRouter(Entry));
