// @flow

import React from 'react';
import { AssociatedDropdown, type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import CitationsService from '../services/Citations';
import Citations from '../transforms/Citations';
import DataFieldDropdown from './DataFieldDropdown';
import PlaceModal from './PlaceModal';
import Places from '../services/Places';
import Place from '../transforms/Place';

import type { Location } from '../types/Location';

type Props = EditModalProps & {
  citeable_id: number,
  citeable_type: string,
  item: Location
};

const LocationModal = (props: Props) => (
  <Modal
    as={Form}
    className='location-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('LocationModal.title.edit')
        : props.t('LocationModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('place_id')}
        label={props.t('LocationModal.labels.place')}
        required={props.isRequired('place_id')}
      >
        <AssociatedDropdown
          collectionName='places'
          modal={{
            component: PlaceModal,
            onSave: (place) => Places.save(place).then(({ data }) => data.place)
          }}
          onSearch={(search) => Places.fetchAll({ search, sort_by: 'name->>english' })}
          onSelection={props.onAssociationInputChange.bind(this, 'place_id', 'place')}
          renderOption={Place.toDropdown.bind(this)}
          searchQuery={props.item.place && props.item.place.name_english}
          value={props.item.place_id}
        />
      </Form.Input>
      <DataFieldDropdown
        {...props}
        column='role'
        label={props.t('LocationModal.labels.role')}
        table='locations'
      />
      <Form.Input
        error={props.isError('precision')}
        label={props.t('LocationModal.labels.precision')}
        onChange={props.onTextInputChange.bind(this, 'precision')}
        required={props.isRequired('precision')}
        value={props.item.precision || ''}
      />
      <DataFieldDropdown
        {...props}
        allowAdditions={false}
        column='certainty'
        label={props.t('LocationModal.labels.certainty')}
        table='locations'
      />
      { props.citeable_id && props.citeable_type && (
        <Form.Input
          error={props.isError('citation_id')}
          label={props.t('LocationModal.labels.citation')}
          required={props.isRequired('citation_id')}
        >
          <AssociatedDropdown
            collectionName='citations'
            onSearch={(search) => CitationsService.fetchAll({
              search,
              sort_by: 'sources.searchable_name',
              citeable_id: props.citeable_id,
              citeable_type: props.citeable_type
            })}
            onSelection={props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
            renderOption={Citations.toDropdown.bind(this)}
            searchQuery={props.item.citation
            && props.item.citation.source
            && props.item.citation.source.searchable_name}
            value={props.item.citation_id}
          />
        </Form.Input>
      )}
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(LocationModal);
