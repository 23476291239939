// @flow

import BaseService from './BaseService';
import CanonType from '../transforms/CanonType';

/**
 * Service class for all canon type based API calls.
 */
class CanonTypes extends BaseService {
  /**
   * Returns the canon types base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/canon_types';
  }

  /**
   * Returns the canon type transform object.
   *
   * @returns {CanonType}
   */
  getTransform() {
    return CanonType;
  }
}

export default new CanonTypes();
