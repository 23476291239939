// @flow

import _ from 'underscore';
import type { Group } from '../types/Group';

/**
 * Class for handling transforming memberships records.
 */
class Memberships {
  PAYLOAD_KEYS = [
    'id',
    'memberable_id',
    'memberable_type',
    'order',
    'assigner',
    '_destroy'
  ];

  /**
   * Returns the memberships for the passed group to be sent to the server on POST/PUT requests.
   *
   * @param group
   *
   * @returns {{categorizations: *}}
   */
  toPayload(group: Group) {
    return {
      memberships: _.map(group.memberships, (g, index) => ({
        ..._.pick(g, this.PAYLOAD_KEYS),
        order: index
      }))
    };
  }
}

export default new Memberships();
