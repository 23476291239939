// @flow

import BaseService from './BaseService';
import Timespan from '../transforms/Timespan';

/**
 * Service class for all timespan based API calls.
 */
class Timespans extends BaseService {
  /**
   * Returns the timespans base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/timespans';
  }

  /**
   * Returns the timespans transform object.
   *
   * @returns {Timespan}
   */
  getTransform() {
    return Timespan;
  }
}

export default new Timespans();
