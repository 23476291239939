// @flow

import React from 'react';
import { AccordionList, useEditContainer, type EditContainerProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import {
  Dimmer, Header, Icon, Loader, Segment
} from 'semantic-ui-react';
import _, { isEmpty } from 'underscore';
import SourcesService from '../services/Sources';
import SourceItem from './AccordionListItem';

type Props = EditContainerProps

const BibliographyDetail = (props: Props) => {
  if (props.loading) {
    return (
      <Dimmer
        active
        inverted
      >
        <Loader
          content={props.t('Common.messages.loading')}
        />
      </Dimmer>
    );
  }

  const { item: bibliography } = props;
  const updatedDate = new Date(bibliography.updated_at);

  return (
    <>
      <Header
        as='h1'
        content={bibliography.name_english}
      />
      <div style={{ display: 'flex' }}>
        <p>
          {bibliography.public ? props.t('Bibliography.public') : props.t('Bibliography.private')}
          {` (${bibliography.owner})`}
        </p>
        {bibliography.updated_at
          && (
          <p style={{ marginLeft: 15, color: 'gray' }}>
            {`
              ${props.t('Bibliography.lastUpdatedAt')} 
              ${updatedDate.toLocaleDateString()} 
              - ${updatedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            `}
          </p>
          )}
      </div>
      {bibliography.description
            && (
            <Segment style={{ marginBottom: 40, marginTop: 30 }}>
              <p style={{ fontWeight: 'bold' }}>{props.t('Bibliography.description')}</p>
              <p>{bibliography.description}</p>
            </Segment>
            )}
      <Header
        content={props.t('Bibliography.tableTitle')}
      />
      <AccordionList
        canAddItem={() => false}
        canEditItem={() => false}
        canDeleteItem={() => false}
        canCopyItem={() => false}
        className='selectable-sources-accordion'
        collectionName='sources'
        getChildItems={(items, item) => item.child_source_relations && item.child_source_relations.map((rel) => ({
          relation_type: rel.relation_type,
          activeIndex: `child-${rel.id}`,
          ...rel.child_source
        }))}
        hideAddRootButton
        getRootItems={(items) => items}
        lazyLoad={false}
        onRowSelect={() => {}}
        onSearch={(search, page) => SourcesService.fetchAll({
          search,
          page,
          source_ids: !isEmpty(bibliography.bibliographies_sources)
            ? bibliography.bibliographies_sources.map((bs) => bs.source_id)
            : [null]
        })}
        onSave={(source) => SourcesService.save(source)}
        pagination
        renderItem={(item) => (
          <SourceItem columns={[
            { value: item.searchable_name, label: item.relation_type, width: 10 },
            { value: item.source_type, label: '', width: 4 },
            {
              value: _.contains(bibliography.bibliographies_sources.map((bs) => bs.source_id), item.id)
                ? <Icon name='check' /> : '',
              width: 2,
              label: ''
            },
          ]}
          />
        )}
        showRecordCount
        showToggle={(item) => (item.child_source_relations ? item.child_source_relations.length > 0 : 0)}
      />
    </>
  );
};

export default withTranslation()(useEditContainer(BibliographyDetail));
