// @flow

import BaseService from './BaseService';

/**
 * Service class for all genre archive based API calls.
 */
class GenreArchives extends BaseService {
  /**
   * Returns the genre places base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/genre_archives';
  }
}

export default new GenreArchives();
