// @flow

import BaseService from './BaseService';
import Canon from '../transforms/Canon';

/**
 * Service class for all canon based API calls.
 */
class Canons extends BaseService {
  /**
   * Returns the canon base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/canons';
  }

  /**
   * Returns the canons transform object.
   *
   * @returns {Canon}
   */
  getTransform() {
    return Canon;
  }
}

export default new Canons();
