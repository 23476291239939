// @flow

import React from 'react';
import {
  AssociatedDropdown,
  EmbeddedList,
  Selectize,
  type EditModalProps
} from 'react-components';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Icon,
  Modal
} from 'semantic-ui-react';
import _ from 'underscore';
import Canons from '../services/Canons';
import Canon from '../transforms/Canon';
import DataFieldDropdown from './DataFieldDropdown';
import MembershipModal from './MembershipModal';
import TranslateableField from './TranslateableField';
import './GroupModal.css';

import type { GroupModalProps } from './GroupModal';
import type { Group } from '../types/Group';

type Props = EditModalProps & GroupModalProps & {
  item: Group
};

const CanonGroupModal = (props: Props) => (
  <Modal
    as={Form}
    className={`canon-group-modal wide-modal ${props.className ? props.className : ''}`}
    open
    size='small'
    closeIcon
    onClose={props.onClose}
  >
    <Modal.Header
      content={props.item.id
        ? props.t('CanonGroupModal.title.edit')
        : props.t('CanonGroupModal.title.add')}
    />
    <Modal.Content>
      <DataFieldDropdown
        {...props}
        column='group_type'
        label={props.t('CanonGroupModal.labels.type')}
        table='groups'
      />
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        buttons={[{
          render: () => (
            <Button
              basic
              content={props.t('Common.buttons.multiAdd')}
              icon='plus'
              key='source'
              onClick={props.onMultiAdd.bind(this)}
            />
          )
        }]}
        columns={[{
          className: 'drag-drop-icon',
          name: 'move',
          label: '',
          render: () => <Icon color='grey' name='bars' />
        }, {
          name: 'title_english',
          label: props.t('CanonGroupModal.memberships.columns.title.english'),
          resolve: (m) => m.memberable.title_english
        }, {
          className: 'arabic',
          name: 'title_arabic',
          label: props.t('CanonGroupModal.memberships.columns.title.arabic'),
          resolve: (m) => m.memberable.title_arabic,
          hidden: true
        }, {
          name: 'title_transliteration',
          label: props.t('CanonGroupModal.memberships.columns.title.transliteration'),
          resolve: (m) => m.memberable.title_transliteration,
          hidden: true
        }, {
          className: 'arabic',
          name: 'legal_formula_arabic',
          label: props.t('CanonGroupModal.memberships.columns.legalFormula.arabic'),
          resolve: (m) => m.memberable.legal_formula_arabic,
        }, {
          name: 'legal_formual_english',
          label: props.t('CanonGroupModal.memberships.columns.legalFormula.english'),
          resolve: (m) => m.legal_formula.title_english,
          hidden: true
        }, {
          name: 'legal_formula_transliteration',
          label: props.t('CanonGroupModal.memberships.columns.legalFormula.transliteration'),
          resolve: (m) => m.memberable.legal_formula_transliteration,
          hidden: true
        }, {
          name: 'assigner',
          label: props.t('CanonGroupModal.memberships.columns.assigner')
        }]}
        items={props.item.memberships}
        modal={{
          component: MembershipModal,
          props: {
            defaults: {
              assigner: 'Researcher',
              memberable_type: 'Canon'
            },
            renderDropdown: (modalProps) => (
              <AssociatedDropdown
                className='arabic'
                collectionName='canons'
                onSearch={(search) => Canons.fetchAll({ search, sort_by: 'legal_formula->>english' })}
                onSelection={modalProps.onAssociationInputChange.bind(this, 'memberable_id', 'memberable')}
                renderOption={Canon.toDropdown.bind(this)}
                searchQuery={modalProps.item.memberable && modalProps.item.memberable.legal_formula_arabic}
                value={modalProps.item.memberable_id}
              />
            )
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'memberships')}
        onDrag={props.onDrag.bind(this)}
        onSave={props.onSaveChildAssociation.bind(this, 'memberships')}
        showRecordCount
      />
      {
        props.showMultiAdd && (
          <Selectize
            collectionName='canons'
            onClose={props.onCloseMultiAdd.bind(this)}
            onLoad={(params) => Canons.fetchAll(_.extend(params, { sort_by: 'title->>english' }))}
            onSave={props.onSaveMultiAdd.bind(this, 'Canon')}
            renderItem={(canon) => (
              <div
                className='arabic'
              >
                <TranslateableField
                  config={{
                    content: 'arabic',
                    popupContent: 'transliteration',
                    popupHeader: 'english'
                  }}
                  property='legal_formula'
                  translateable={canon}
                />
              </div>
            )}
            selectedItems={_.map(props.item.memberships, (m) => m.memberable)}
            title={props.t('CanonGroupModal.title.selectCanons')}
          />
        )
      }
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(CanonGroupModal);
