// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, Header, Modal, Step
} from 'semantic-ui-react';

type Props = {
    open: boolean,
    onClose: ()=>void,
    t: (key: string) => string,
    steps: Array<{
      name: string,
      title: string,
      description: string,
      header: string,
      renderContent: ()=>void,
      backButtonProps: Object,
      nextButtonProps: Object
    }>,
    activeStep: string,
    className: string,
};

const StepperModal = (props: Props) => {
  const foundStep = props.steps.find((s) => s.name === props.activeStep);
  const currentStep = foundStep || {
    name: '',
    title: '',
    description: '',
    header: '',
    renderContent: () => {},
    backButtonProps: {},
    nextButtonProps: {},
  };
  const stepMaxWidth = `${100 / props.steps.length}%`;
  return (
    <Modal
      size='large'
      open={props.open}
      className={props.className}
    >
      <Step.Group style={{ margin: 20 }}>
        {props.steps.map((step) => {
          const isActiveStep = currentStep.name === step.name;
          return (
            <Step
              key={step.name}
              active={isActiveStep}
              style={{ maxWidth: stepMaxWidth }}
            >
              <Step.Content>
                <Step.Title>{step.title}</Step.Title>
                <Step.Description>{step.description}</Step.Description>
              </Step.Content>
            </Step>
          );
        })}
      </Step.Group>

      <Modal.Content>
        <Header size='medium' content={currentStep.header} />
        {currentStep.renderContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color='blue'
          content={props.t('StepperModal.buttons.back')}
          {...currentStep.backButtonProps}
        />
        <Button
          primary
          content={props.t('StepperModal.buttons.next')}
          {...currentStep.nextButtonProps}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(StepperModal);
