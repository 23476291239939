// @flow

import React from 'react';
import _ from 'underscore';
import type { Place as PlaceType } from '../types/Place';
import TranslateableField from '../components/TranslateableField';

/**
 * Class for handling transforming place records.
 */
class Place {
  PAYLOAD_KEYS = [
    'name_arabic',
    'name_english',
    'name_transliteration',
    'lat',
    'lng'
  ];

  /**
   * Returns the passed place as a dropdown option.
   *
   * @param place
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(place: PlaceType) {
    return {
      key: place.id,
      value: place.id,
      text: <TranslateableField property='name' translateable={place} />
    };
  }

  /**
   * Returns the place object to be sent to the server on POST/PUT requests.
   *
   * @param place
   *
   * @returns {*}
   */
  toPayload(place: PlaceType) {
    return { place: _.pick(place, this.PAYLOAD_KEYS) };
  }
}

export default new Place();
