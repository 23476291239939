// @flow

import React, { useState } from "react";
import { AccordionDataList } from "react-components";
import { withTranslation } from "react-i18next";
import uuid from "react-uuid";
import AddExternalSourceModal from "../components/AddExternalSourceModal";
import BibliographyService from "../services/Bibliography";
import SelectBibliographyModal from "../components/SelectBibliographyModal";
import Session from "../services/Session";
import Source from "../transforms/Source";
import SourcesFiltersModal from "../components/SourcesFiltersModal";
import SourceItem from "../components/AccordionListItem";
import SourceModal from "../components/SourceModal";
import SourcesService from "../services/Sources";

type Props = {
  t: (key: string) => string,
};

const Sources = (props: Props) => {
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectable, setSelectable] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isAddExternalModalOpen, setIsAddExternalModalOpen] = useState(false);

  return (
    <>
      <AccordionDataList
        actions={[
          {
            name: "edit",
            accept: () => {
              return Session.isAdmin();
            },
          },
          {
            name: "copy",
            accept: () => Session.isAdmin(),
          },
          {
            name: "delete",
            accept: () => Session.isAdmin(),
          },
        ]}
        buttons={[
          {
            accept: () => !selectable,
            basic: true,
            color: "teal",
            content: props.t("Sources.buttons.addFromStackLife"),
            icon: "add",
            onClick: () => setIsAddExternalModalOpen(true),
          },
          {
            accept: () => !selectedSources.length,
            active: selectable,
            basic: true,
            icon: "tasks",
            onClick: () => setSelectable((prevSelectable) => !prevSelectable),
          },
          {
            accept: () => !!selectedSources.length,
            basic: true,
            content: props.t("Sources.buttons.addSelectedToBibliography"),
            icon: "thumbtack",
            onClick: () => setIsSelectModalOpen(true),
          },
          {
            accept: () => !!selectedSources.length,
            basic: true,
            color: "red",
            content: props.t("Sources.buttons.clearSelected"),
            icon: "times",
            onClick: () => setSelectedSources([]),
          },
        ]}
        className="sources"
        collectionName="sources"
        getChildItems={(items, item) =>
          item.child_source_relations &&
          item.child_source_relations.map((rel) => ({
            relation_type: rel.relation_type,
            activeIndex: `child-${rel.id}`,
            ...rel.child_source,
          }))
        }
        getRootItems={(items) => items}
        filters={{
          component: SourcesFiltersModal,
          props: {
            author_id: "",
            genre: "",
            place_id: "",
            publication_date_ah: null,
            publication_date_ce: null,
            source_type: "",
            timespan_id: "",
          },
        }}
        modal={{
          component: SourceModal,
          props: {
            onInitialize: async (id) => {
              const { data } = await SourcesService.fetchOne(id);
              console.log(id, data);
              return data.source;
            },
          },
        }}
        onCopy={(b) =>
          SourcesService.fetchOne(b.id).then(({ data }) =>
            Source.toCopy(data.source)
          )
        }
        onDelete={(item) =>
          SourcesService.delete(item).then(({ data }) => data.source)
        }
        onLoad={(params) => SourcesService.fetchAll(params)}
        onRowSelect={({ checked }, source) => {
          if (checked) {
            setSelectedSources([...selectedSources, source]);
          } else {
            setSelectedSources(
              selectedSources.filter((r) => r.id !== source.id)
            );
          }
        }}
        onSave={(item) =>
          SourcesService.save(item).then(({ data }) => data.source)
        }
        renderItem={(item) => (
          <SourceItem
            columns={[
              {
                value: item.searchable_name,
                label: item.relation_type,
                width: 12,
              },
              { value: item.source_type, label: "", width: 4 },
            ]}
          />
        )}
        selectable={selectable}
        selectedRows={selectedSources}
        showRecordCount
        showToggle={(item) =>
          item.child_source_relations
            ? item.child_source_relations.length > 0
            : 0
        }
        sort={[
          {
            key: "searchable_name",
            value: "searchable_name",
            text: "Name",
          },
          {
            key: "source_type",
            value: "source_type",
            text: "Type",
          },
        ]}
      />
      <br />
      <br />
      <SelectBibliographyModal
        open={isSelectModalOpen}
        onCancel={() => setIsSelectModalOpen(false)}
        onClose={() => setIsSelectModalOpen(false)}
        onSave={(bibliography) => {
          const existingSources = bibliography.bibliographies_sources
            ? bibliography.bibliographies_sources
            : [];
          const newSources = selectedSources.reduce((sources, s) => {
            const existingSource = existingSources.find(
              (es) => es.source_id === s.id
            );
            if (existingSource) {
              return sources;
            }
            return [...sources, { bibliography_id: uuid(), source_id: s.id }];
          }, []);

          BibliographyService.save({
            ...bibliography,
            bibliographies_sources: newSources,
          });
        }}
      />
      <AddExternalSourceModal
        open={isAddExternalModalOpen}
        onClose={() => setIsAddExternalModalOpen(false)}
      />
    </>
  );
};

export default withTranslation()(Sources);
