// @flow

import React from 'react';
import { AccordionList, TagsList } from 'react-components';
import { Grid } from 'semantic-ui-react';
import _ from 'underscore';
import CanonTypeModal from '../components/CanonTypeModal';
import CanonTypesService from '../services/CanonTypes';
import './CanonTypes.css';

const ASSIGNER_COLORS = {
  Researcher: { background: '#0F1B26', color: 'white' },
  Source: { background: '#528CBF', color: 'white' }
};

const MAX_NODE_LEVEL = 1;

const CanonTypes = () => (
  <AccordionList
    canAddItem={(canonType) => canonType.node_level < MAX_NODE_LEVEL}
    className='canon-types'
    collectionName='canon_types'
    getChildItems={(canonTypes, canonType) => _.where(canonTypes, { parent_canon_type_id: canonType.id })}
    getRootItems={(canonTypes) => _.where(canonTypes, { parent_canon_type_id: null })}
    modal={{
      component: CanonTypeModal,
      onAddItem: (canonType) => ({ parent_canon_type_id: canonType.id, parent_canon_type: canonType })
    }}
    onDelete={(canonType) => CanonTypesService.delete(canonType).then(({ data }) => data.canon_type)}
    onSave={(canonType) => CanonTypesService.save(canonType).then(({ data }) => data.canon_type)}
    onSearch={(parentId, search) => CanonTypesService.fetchAll({ parent_canon_type_id: parentId, search })}
    renderItem={(canonType) => (
      <Grid
        columns={2}
      >
        <Grid.Column
          verticalAlign='middle'
        >
          <TagsList
            config={ASSIGNER_COLORS}
            tags={canonType.assigner}
          />
          { canonType.name_english }
        </Grid.Column>
        <Grid.Column
          className='arabic'
          verticalAlign='middle'
        >
          { canonType.name_arabic }
        </Grid.Column>
      </Grid>
    )}
    showToggle={(canonType) => canonType.node_level < MAX_NODE_LEVEL}
  />
);

export default CanonTypes;
