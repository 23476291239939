// @flow

import _ from 'underscore';
import type { Rationale as RationaleType } from '../types/Rationale';

/**
 * Class for handling transforming rationale records.
 */
class Rationale {
  PAYLOAD_KEYS = [
    'name_arabic',
    'name_english',
    'name_transliteration'
  ];

  /**
   * Returns the rationale object to be sent to the server on POST/PUT requests.
   *
   * @param rationale
   *
   * @returns {*}
   */
  toPayload(rationale: RationaleType) {
    return { rationale: _.pick(rationale, this.PAYLOAD_KEYS) };
  }
}

export default new Rationale();
