// @flow

import _ from 'underscore';
import type { Categorizeable } from '../types/concerns/Categorizeable';

/**
 * Class for handling transforming categorization records.
 */
class Categorizations {
  PAYLOAD_KEYS = [
    'id',
    'category_id',
    'category_type',
    'citation_id',
    'assigner',
    '_destroy'
  ];

  /**
   * Returns the categorizations for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param categorizable
   *
   * @returns {{categorizations: *}}
   */
  toPayload(categorizable: Categorizeable) {
    return {
      categorizations: _.map(categorizable.categorizations, (c) => _.pick(c, this.PAYLOAD_KEYS))
    };
  }
}

export default new Categorizations();
