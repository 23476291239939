// @flow

import _ from 'underscore';
import Categorizations from './Categorizations';
import Citations from './Citations';
import Footnotes from './Footnotes';
import FuzzyDate from './FuzzyDate';
import Locations from './Locations';
import Occupations from './Occupations';

import type { Person as PersonType } from '../types/Person';

/**
 * Class for handling transforming person records.
 */
class Person {
  PAYLOAD_KEYS = [
    'short_name',
    'full_name',
    'laqab_kitab',
    'kunya',
    'nasab',
    'nisba',
    'alid',
    'mawla',
    'slave',
    'vocation',
    'family_vocation',
    'gender',
    'bio',
    'books_authored',
    'group',
    'theological_school',
    'social_class',
    'modern'
  ];

  /**
   * Returns the passed person as a dropdown option.
   *
   * @param person
   *
   * @returns {{text: *, value: number, key: number}}
   */
  toDropdown(person: PersonType) {
    return {
      key: person.id,
      value: person.id,
      text: person.searchable_name
    };
  }

  /**
   * Returns the person object to be sent to the server on POST/PUT requests.
   *
   * @param person
   *
   * @returns {*}
   */
  toPayload(person: PersonType) {
    return {
      person: {
        ..._.pick(person, this.PAYLOAD_KEYS),
        ...FuzzyDate.toPayload(person, 'birth_date_ah'),
        ...FuzzyDate.toPayload(person, 'birth_date_ce'),
        ...FuzzyDate.toPayload(person, 'death_date_ah'),
        ...FuzzyDate.toPayload(person, 'death_date_ce'),
        ...Categorizations.toPayload(person),
        ...Citations.toPayload(person),
        ...Footnotes.toPayload(person),
        ...Locations.toPayload(person),
        ...Occupations.toPayload(person)
      }
    };
  }
}

export default new Person();
