// @flow

import _ from 'underscore';
import Categorizations from './Categorizations';
import Citations from './Citations';
import Footnotes from './Footnotes';
import Locations from './Locations';
import Participations from './Participations';
import References from './References';

import type { Fatwa as FatwaType } from '../types/Fatwa';

/**
 * Class for handling transforming fatwa records.
 */
class Fatwa {
  PAYLOAD_KEYS = [
    'question',
    'format',
    'answer',
    'basis',
    'subsequent_action',
    'fiqh_ruling_result',
    'original_text',
    'translation',
    'significance',
    'link'
  ];

  /**
   * Returns the fatwa object to be sent to the server on POST/PUT requests.
   *
   * @param fatwa
   *
   * @returns {*}
   */
  toPayload(fatwa: FatwaType) {
    return {
      fatwa: {
        ..._.pick(fatwa, this.PAYLOAD_KEYS),
        ...Categorizations.toPayload(fatwa),
        ...Citations.toPayload(fatwa),
        ...Footnotes.toPayload(fatwa),
        ...Locations.toPayload(fatwa),
        ...Participations.toPayload(fatwa),
        ...References.toPayload(fatwa)
      }
    };
  }
}

export default new Fatwa();
