// @flow

import React from 'react';
import { type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';
import './PeopleFiltersModal.css';

type Props = EditModalProps & {
  item: {
    period: Array<string>
  }
};

const PlacesFiltersModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='places-filters-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.t('PlacesFiltersModal.title')}
    />
    <Modal.Content>
      <DataFieldDropdown
        {...props}
        allowAdditions={false}
        column='period'
        label={props.t('PlacesFiltersModal.labels.period')}
        multiple
        table='places'
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(PlacesFiltersModal);
