// @flow

import React from 'react';
import { DescriptorField } from 'react-components';
import { Popup } from 'semantic-ui-react';

type Props = {
  config?: {
    content: string,
    popupContent: string,
    popupHeader: string
  },
  property: string,
  translateable: any
};

const SUFFIX_ARABIC = 'arabic';
const SUFFIX_ENGLISH = 'english';
const SUFFIX_TRANSLITERATION = 'transliteration';

const TranslateableField = ({ config = {}, property, translateable }: Props) => (
  <DescriptorField
    content={translateable[`${property}_${config.content}`]}
    popupContent={(
      <>
        <Popup.Header
          className={config.popupHeader === SUFFIX_ARABIC ? 'arabic' : ''}
        >
          { translateable[`${property}_${config.popupHeader}`] }
        </Popup.Header>
        <Popup.Content
          className={config.popupContent === SUFFIX_ARABIC ? 'arabic' : ''}
        >
          { translateable[`${property}_${config.popupContent}`] }
        </Popup.Content>
      </>
    )}
  />
);

TranslateableField.defaultProps = {
  config: {
    content: SUFFIX_ENGLISH,
    popupContent: SUFFIX_TRANSLITERATION,
    popupHeader: SUFFIX_ARABIC
  }
};

export default TranslateableField;
