// @flow

import React from 'react';
import { FuzzyDate, type EditModalProps, AssociatedDropdown } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Checkbox, Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';
import Places from '../services/Places';
import FuzzyDateTransform from '../transforms/FuzzyDate';
import Place from '../transforms/Place';
import './PeopleFiltersModal.css';

import type { FuzzyDate as FuzzyDateType } from '../types/FuzzyDate';

type Props = EditModalProps & {
  item: {
    modern: boolean,
    birth_date_ah: FuzzyDateType,
    birth_date_ce: FuzzyDateType,
    death_date_ah: FuzzyDateType,
    death_date_ce: FuzzyDateType
  }
};

const PeopleFiltersModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='people-filters-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.t('PeopleFiltersModal.title')}
    />
    <Modal.Content>
      <Form.Input
        label={props.t('PeopleFiltersModal.labels.type')}
      >
        <Checkbox
          checked={props.item.modern === undefined || props.item.modern === ''}
          label={props.t('PeopleFiltersModal.types.all')}
          radio
          onChange={props.onTextInputChange.bind(this, 'modern')}
          value=''
        />
        <Checkbox
          checked={props.item.modern === 'true'}
          label={props.t('PeopleFiltersModal.types.modern')}
          radio
          onChange={props.onTextInputChange.bind(this, 'modern')}
          value='true'
        />
        <Checkbox
          checked={props.item.modern === 'false'}
          label={props.t('PeopleFiltersModal.types.preModern')}
          radio
          onChange={props.onTextInputChange.bind(this, 'modern')}
          value='false'
        />
      </Form.Input>
      <DataFieldDropdown
        {...props}
        allowAdditions={false}
        column='genre'
        label={props.t('PeopleFiltersModal.labels.genre')}
        table='sources'
      />
      <Form.Input
        label={props.t('PeopleFiltersModal.labels.place')}
      >
        <AssociatedDropdown
          collectionName='places'
          onSearch={(search) => Places.fetchAll({ search, sort_by: 'name->>english' })}
          onSelection={props.onAssociationInputChange.bind(this, 'place_id', 'place')}
          renderOption={Place.toDropdown.bind(this)}
          searchQuery={props.item.place && props.item.place.name_english}
          value={props.item.place_id || ''}
        />
      </Form.Input>
      <Form.Input
        label={props.t('PeopleFiltersModal.labels.birthDateAh')}
      >
        <FuzzyDate
          calendar='hijri'
          description={false}
          date={FuzzyDateTransform.toFuzzyDate(props.item.birth_date_ah)}
          onChange={(data) => props.onSetState({
            birth_date_ah: FuzzyDateTransform.toFilterDate(props.item, 'birth_date_ah', data)
          })}
          title={props.t('PeopleFiltersModal.labels.birthDateAh')}
        />
      </Form.Input>
      <Form.Input
        label={props.t('PeopleFiltersModal.labels.birthDateCe')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.birth_date_ce)}
          description={false}
          onChange={(data) => props.onSetState({
            birth_date_ce: FuzzyDateTransform.toFilterDate(props.item, 'birth_date_ce', data)
          })}
          title={props.t('PeopleFiltersModal.labels.birthDateCe')}
        />
      </Form.Input>
      <Form.Input
        label={props.t('PeopleFiltersModal.labels.deathDateAh')}
      >
        <FuzzyDate
          calendar='hijri'
          date={FuzzyDateTransform.toFuzzyDate(props.item.death_date_ah)}
          description={false}
          onChange={(data) => props.onSetState({
            death_date_ah: FuzzyDateTransform.toFilterDate(props.item, 'death_date_ah', data)
          })}
          title={props.t('PeopleFiltersModal.labels.deathDateAh')}
        />
      </Form.Input>
      <Form.Input
        label={props.t('PeopleFiltersModal.labels.deathDateCe')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.death_date_ce)}
          description={false}
          onChange={(data) => props.onSetState({
            death_date_ce: FuzzyDateTransform.toFilterDate(props.item, 'death_date_ce', data)
          })}
          title={props.t('PeopleFiltersModal.labels.deathDateCe')}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(PeopleFiltersModal);
