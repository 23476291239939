// @flow

import _ from 'underscore';

import type { FuzzyDate as FuzzyDateType } from '../types/FuzzyDate';

type FuzzyDateOutput = {
  accuracy: number,
  description: string,
  endDate: Date,
  range: boolean,
  startDate: Date
}

class FuzzyDate {
  PAYLOAD_KEYS = [
    'id',
    'accuracy',
    'range',
    'start_date',
    'end_date',
    'description',
    '_destroy'
  ];

  /**
   * Returns a copy of the passed fuzzy date.
   *
   * @param date
   *
   * @returns {*}
   */
  toCopy(date: FuzzyDateType) {
    return _.pick(date, 'accuracy', 'range', 'start_date', 'end_date', 'description');
  }

  /**
   * Returns the passed FuzzyDate property as a related object. If the date is empty, null is returned.
   *
   * @param item
   * @param property
   * @param date
   *
   * @returns {{end_date: string, accuracy: number, range: *, start_date: (string|Date|boolean)}}
   */
  toFilterDate(item: any, property: string, date: FuzzyDateOutput) {
    if (!(date.startDate && date.endDate)) {
      return null;
    }

    return this.toRelated(item, property, date);
  }

  /**
   * Returns the passed JSON date to a FuzzyDate.
   *
   * @param date
   *
   * @returns {{}|{endDate: string, accuracy: number, range: boolean, startDate: string}}
   */
  toFuzzyDate(date: FuzzyDateType) {
    if (!date) {
      return {};
    }

    return {
      ..._.pick(date, 'accuracy', 'description', 'range'),
      startDate: date.start_date,
      endDate: date.end_date
    };
  }

  /**
   * Returns the fuzzy date for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param item
   * @param property
   *
   * @returns {{[p: string]: {date_type: string}}}
   */
  toPayload(item: any, property: string) {
    return {
      [property]: {
        date_type: property,
        ..._.pick(item[property], this.PAYLOAD_KEYS)
      }
    };
  }

  /**
   * Returns the passed FuzzyDate property as a related object.
   *
   * @param item
   * @param property
   * @param date
   *
   * @returns {{end_date: string, accuracy: number, range: *, start_date: (string|Date|boolean)}}
   */
  toRelated(item: any, property: string, date: FuzzyDateOutput) {
    return {
      ...item[property] || {},
      ..._.pick(date, 'accuracy', 'range', 'description'),
      start_date: date.startDate,
      end_date: date.endDate,
      _destroy: !(date.startDate && date.endDate)
    };
  }
}

export default new FuzzyDate();
