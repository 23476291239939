// @flow

import _ from 'underscore';
import type { Issue as IssueType } from '../types/Issue';

class Issue {
  PAYLOAD_KEYS = [
    'name_arabic',
    'name_english',
    'name_transliteration',
    'parent_issue_id'
  ];

  toPayload(issue: IssueType) {
    return { issue: _.pick(issue, this.PAYLOAD_KEYS) };
  }
}

export default new Issue();
