// @flow

import _ from 'underscore';

import type { Timespan } from '../types/Timespan';

/**
 * Class for handling transforming timespan_periods records.
 */
class TimespanPeriods {
  PAYLOAD_KEYS = [
    'id',
    'period_id',
    '_destroy'
  ];

  /**
   * Returns the timespan_periods for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param timespan
   *
   * @returns {{timespan_periods: *}}
   */
  toPayload(timespan: Timespan) {
    return {
      timespan_periods: _.map(timespan.timespan_periods, (t) => _.pick(t, this.PAYLOAD_KEYS))
    };
  }
}

export default new TimespanPeriods();
