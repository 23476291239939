// @flow
import React from 'react';
import {
  Grid
} from 'semantic-ui-react';
import './AccordionListItem.css';

type Props = {
  columns: Array<{value: any, label: ?any, width: number}>
};

const AccordionListItem = (props: Props) => {
  const { columns } = props;
  return (
    <Grid
      columns={columns.length}
      className='accordion-item-grid'
    >
      {columns.map((c) => (
        <Grid.Column
          verticalAlign='middle'
          width={c.width}
        >
          {c.label
          && (
          <p className='item-label'>
            {c.label}
          </p>
          )}
          { c.value }
        </Grid.Column>
      ))}
    </Grid>
  );
};

export default AccordionListItem;
