// @flow

import React, { useMemo } from 'react';
import { TagsList } from 'react-components';
import './TimespanLabel.css';

type Props = {
  inline?: boolean,
  type: string
};

const TYPE_COLORS = {
  Period: { background: '#6B2737', color: 'white' },
  Leadership: { background: '#E08E45', color: 'white' },
  'Regional Power': { background: '#F8F4A6', color: 'grey' },
  Sultanate: { background: '#BDF7B7', color: 'grey' },
  Dynasty: { background: '#3943B7', color: 'white' }
};

const TimespanLabel = (props: Props) => {
  /**
   * Sets the class name(s) to set on the root component.
   *
   * @type {string}
   */
  const className = useMemo(() => {
    const classNames = ['timespan-label'];

    if (props.inline) {
      classNames.push('inline');
    }

    return classNames.join(' ');
  }, [props.inline]);

  return (
    <div
      className={className}
    >
      <TagsList
        config={TYPE_COLORS}
        tags={[props.type]}
      />
    </div>
  );
};

TimespanLabel.defaultProps = {
  inline: true
};

export default TimespanLabel;
