// @flow

import React, { Component } from 'react';
import { AssociatedDropdown, type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import Citations from '../transforms/Citations';
import CitationsService from '../services/Citations';
import DataFieldDropdown from './DataFieldDropdown';
import Groups from '../services/Groups';
import Group from '../transforms/Group';
import GroupModal from './GroupModal';
import PersonGroupModal from './PersonGroupModal';
import PersonModal from './PersonModal';
import People from '../services/People';
import Person from '../transforms/Person';
import PositionModal from './PositionModal';
import Positions from '../services/Positions';
import Position from '../transforms/Position';

import type { Participation } from '../types/Participation';

type Props = EditModalProps & {
  citeable_id?: number,
  citeable_type?: string,
  item: Participation
};

type State = {
  participantType: boolean
};

const PARTICIPANT_TYPE_PERSON = 'Person';
const PARTICIPANT_TYPE_GROUP = 'Group';

class ParticipationModal extends Component<Props, State> {
  /**
   * Constructs a new ParticipationModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      participantType: !props.item.participant_type
    };
  }

  /**
   * Renders the ParticipationModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        as={Form}
        className='participation-modal'
        closeIcon
        onClose={this.props.onClose}
        open
        size='small'
      >
        <Modal.Header
          content={this.props.item.id
            ? this.props.t('ParticipationModal.title.edit')
            : this.props.t('ParticipationModal.title.add')}
        />
        <Modal.Content>
          { this.renderParticipantType() }
          { this.renderPersonDropdown() }
          { this.renderGroupDropdown() }
          { this.renderRoleDropdown() }
          { this.renderPositionDropdown() }
          { this.renderCertaintyDropdown() }
          { this.renderCitationDropdown() }
        </Modal.Content>
        { this.props.children }
      </Modal>
    );
  }

  /**
   * Renders the certainty dropdown component.
   *
   * @returns {null|*}
   */
  renderCertaintyDropdown() {
    if (!(this.props.citeable_id && this.props.citeable_type) || !this.props.item.participant_type) {
      return null;
    }

    return (
      <DataFieldDropdown
        {...this.props}
        allowAdditions={false}
        column='certainty'
        label={this.props.t('ParticipationModal.labels.certainty')}
        table='participations'
      />
    );
  }

  /**
   * Renders the citation dropdown component.
   *
   * @returns {null|*}
   */
  renderCitationDropdown() {
    if (!(this.props.citeable_id && this.props.citeable_type) || !this.props.item.participant_type) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('citation_id')}
        label={this.props.t('ParticipationModal.labels.citation')}
        required={this.props.isRequired('citation_id')}
      >
        <AssociatedDropdown
          collectionName='citations'
          onSearch={(search) => CitationsService.fetchAll({
            search,
            sort_by: 'sources.searchable_name',
            citeable_id: this.props.citeable_id,
            citeable_type: this.props.citeable_type
          })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
          renderOption={Citations.toDropdown.bind(this)}
          searchQuery={this.props.item.citation
          && this.props.item.citation.source
          && this.props.item.citation.source.searchable_name}
          value={this.props.item.citation_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the group dropdown component.
   *
   * @returns {null|*}
   */
  renderGroupDropdown() {
    if (this.props.item.participant_type !== PARTICIPANT_TYPE_GROUP) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('participant_id')}
        label={this.props.t('ParticipationModal.labels.group')}
        required={this.props.isRequired('participant_id')}
      >
        <AssociatedDropdown
          collectionName='groups'
          modal={{
            component: GroupModal,
            props: {
              defaults: {
                member_class: 'Person'
              },
              modal: PersonGroupModal
            },
            onSave: (group) => Groups.save(group).then(({ data }) => data.group)
          }}
          onSearch={(search) => Groups.fetchAll({ search, member_class: 'Person', sort_by: 'name->>english' })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'participant_id', 'participant')}
          renderOption={Group.toDropdown.bind(this)}
          searchQuery={this.props.item.participant && this.props.item.participant.name_english}
          value={this.props.item.participant_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the participant type component.
   *
   * @returns {null|*}
   */
  renderParticipantType() {
    if (!this.state.participantType) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('referent_type')}
        label={this.props.t('ReferenceModal.labels.type')}
        required={this.props.isRequired('referent_type')}
      >
        <Dropdown
          fluid
          selection
          options={[{
            key: PARTICIPANT_TYPE_PERSON,
            value: PARTICIPANT_TYPE_PERSON,
            text: PARTICIPANT_TYPE_PERSON
          }, {
            key: PARTICIPANT_TYPE_GROUP,
            value: PARTICIPANT_TYPE_GROUP,
            text: PARTICIPANT_TYPE_GROUP
          }]}
          onChange={this.props.onTextInputChange.bind(this, 'participant_type')}
          value={this.props.participant_type}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the person dropdown component.
   *
   * @returns {null|*}
   */
  renderPersonDropdown() {
    if (this.props.item.participant_type !== PARTICIPANT_TYPE_PERSON) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('participant_id')}
        label={this.props.t('ParticipationModal.labels.person')}
        required={this.props.isRequired('participant_id')}
      >
        <AssociatedDropdown
          collectionName='people'
          modal={{
            component: PersonModal,
            onSave: (person) => People.save(person).then(({ data }) => data.person),
            props: {
              onInitialize: (id) => People.fetchOne(id).then(({ data }) => data.person)
            }
          }}
          onSearch={(search) => People.fetchAll({ search, sort_by: 'searchable_name' })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'participant_id', 'participant')}
          renderOption={Person.toDropdown.bind(this)}
          searchQuery={this.props.item.participant && this.props.item.participant.searchable_name}
          value={this.props.item.participant_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the position dropdown component.
   *
   * @returns {null|*}
   */
  renderPositionDropdown() {
    if (!(this.props.citeable_id && this.props.citeable_type)
      || this.props.item.participant_type !== PARTICIPANT_TYPE_PERSON) {
      return null;
    }

    return (
      <Form.Input
        error={this.props.isError('position_id')}
        label={this.props.t('ParticipationModal.labels.position')}
        required={this.props.isRequired('position_id')}
      >
        <AssociatedDropdown
          collectionName='positions'
          modal={{
            component: PositionModal,
            onSave: (position) => Positions.save(position).then(({ data }) => data.position)
          }}
          onSearch={(search) => Positions.fetchAll({ search, sort_by: 'name->>english' })}
          onSelection={this.props.onAssociationInputChange.bind(this, 'position_id', 'position')}
          renderOption={Position.toDropdown.bind(this)}
          searchQuery={this.props.item.position && this.props.item.position.name_english}
          value={this.props.item.position_id}
        />
      </Form.Input>
    );
  }

  /**
   * Renders the role dropdown component.
   *
   * @returns {null|*}
   */
  renderRoleDropdown() {
    if (!this.props.item.participant_type) {
      return null;
    }

    return (
      <DataFieldDropdown
        {...this.props}
        column='role'
        label={this.props.t('ParticipationModal.labels.role')}
        table='participations'
      />
    );
  }
}

export default withTranslation()(ParticipationModal);
