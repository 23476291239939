// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ArrowButtons,
  EmbeddedList,
  FuzzyDate,
  TabbedModal,
  type EditModalProps
} from 'react-components';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';
import CitationModal from './CitationModal';
import DataFieldDropdown from './DataFieldDropdown';
import FootnoteModal from './FootnoteModal';
import FuzzyDateTransform from '../transforms/FuzzyDate';
import LocationModal from './LocationModal';
import OccupationModal from './OccupationModal';
import TimespanCategorizationModal from './TimespanCategorizationModal';
import TimespanInfo from './TimespanInfo';
import './PersonModal.css';

import type { Person } from '../types/Person';

type Props = EditModalProps & {
  item: Person,
  externalDataContent: ?{}
};

const PersonModal = (props: Props) => (
  <div>
    <TabbedModal
      as={Form}
      centered={false}
      className='person-modal wide-modal'
      closeIcon
      onClose={props.onClose}
      header={props.item.id
        ? props.t('PersonModal.title.edit')
        : props.t('PersonModal.title.add')}
      inlineTabs={false}
      open
      size='small'
    >
      <TabbedModal.Tab
        name={props.t('PersonModal.tabs.demographics')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <Form.Input
              autoFocus
              error={props.isError('short_name')}
              label={props.t('PersonModal.labels.shortName')}
              onChange={props.onTextInputChange.bind(this, 'short_name')}
              required={props.isRequired('short_name')}
              value={props.item.short_name || ''}
            />
            <Form.Input
              error={props.isError('full_name')}
              label={props.t('PersonModal.labels.fullName')}
              onChange={props.onTextInputChange.bind(this, 'full_name')}
              required={props.isRequired('full_name')}
              value={props.item.full_name || ''}
            />
            <Form.Input
              error={props.isError('gender')}
              label={props.t('PersonModal.labels.gender')}
              onChange={props.onTextInputChange.bind(this, 'gender')}
              required={props.isRequired('gender')}
              value={props.item.gender || ''}
            />
            <Form.Group
              widths='equal'
            >
              <Form.Input
                error={props.isError('birth_date_ah')}
                label={props.t('PersonModal.labels.birthDateAh')}
                required={props.isRequired('birth_date_ah')}
              >
                <FuzzyDate
                  calendar='hijri'
                  date={FuzzyDateTransform.toFuzzyDate(props.item.birth_date_ah)}
                  onChange={(data) => props.onSetState({
                    birth_date_ah: FuzzyDateTransform.toRelated(props.item, 'birth_date_ah', data)
                  })}
                  title={props.t('PersonModal.labels.birthDateAh')}
                />
              </Form.Input>
              <ArrowButtons
                onLeft={() => props.onSetState({
                  birth_date_ah: FuzzyDateTransform.toCopy(props.item.birth_date_ce)
                })}
                onRight={() => props.onSetState({
                  birth_date_ce: FuzzyDateTransform.toCopy(props.item.birth_date_ah)
                })}
              />
              <Form.Input
                error={props.isError('birth_date_ce')}
                label={props.t('PersonModal.labels.birthDateCe')}
                required={props.isRequired('birth_date_ce')}
              >
                <FuzzyDate
                  date={FuzzyDateTransform.toFuzzyDate(props.item.birth_date_ce)}
                  onChange={(data) => props.onSetState({
                    birth_date_ce: FuzzyDateTransform.toRelated(props.item, 'birth_date_ce', data)
                  })}
                  title={props.t('PersonModal.labels.birthDateCe')}
                />
              </Form.Input>
            </Form.Group>
            <Form.Group
              widths='equal'
            >
              <Form.Input
                error={props.isError('death_date_ah')}
                label={props.t('PersonModal.labels.deathDateAh')}
                required={props.isRequired('death_date_ah')}
              >
                <FuzzyDate
                  calendar='hijri'
                  date={FuzzyDateTransform.toFuzzyDate(props.item.death_date_ah)}
                  onChange={(data) => props.onSetState({
                    death_date_ah: FuzzyDateTransform.toRelated(props.item, 'death_date_ah', data)
                  })}
                  title={props.t('PersonModal.labels.deathDateAh')}
                />
              </Form.Input>
              <ArrowButtons
                onLeft={() => props.onSetState({
                  death_date_ah: FuzzyDateTransform.toCopy(props.item.death_date_ce)
                })}
                onRight={() => props.onSetState({
                  death_date_ce: FuzzyDateTransform.toCopy(props.item.death_date_ah)
                })}
              />
              <Form.Input
                error={props.isError('death_date_ce')}
                label={props.t('PersonModal.labels.deathDateCe')}
                required={props.isRequired('death_date_ce')}
              >
                <FuzzyDate
                  date={FuzzyDateTransform.toFuzzyDate(props.item.death_date_ce)}
                  onChange={(data) => props.onSetState({
                    death_date_ce: FuzzyDateTransform.toRelated(props.item, 'death_date_ce', data)
                  })}
                  title={props.t('PersonModal.labels.deathDateCe')}
                />
              </Form.Input>
            </Form.Group>
            <Form.TextArea
              error={props.isError('bio')}
              label={props.t('PersonModal.labels.bio')}
              onChange={props.onTextInputChange.bind(this, 'bio')}
              required={props.isRequired('bio')}
              value={props.item.bio || ''}
            />
            <Form.Checkbox
              checked={props.item.modern}
              error={props.isError('modern')}
              label={props.t('PersonModal.labels.modern')}
              onChange={props.onCheckboxInputChange.bind(this, 'modern')}
              required={props.isRequired('modern')}
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab
        name={props.t('PersonModal.tabs.other')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <Form.Input
              error={props.isError('laqab_kitab')}
              label={props.t('PersonModal.labels.laqabKitab')}
              onChange={props.onTextInputChange.bind(this, 'laqab_kitab')}
              required={props.isRequired('laqab_kitab')}
              value={props.item.laqab_kitab || ''}
            />
            <Form.Input
              error={props.isError('kunya')}
              label={props.t('PersonModal.labels.kunya')}
              onChange={props.onTextInputChange.bind(this, 'kunya')}
              required={props.isRequired('kunya')}
              value={props.item.kunya || ''}
            />
            <Form.Input
              error={props.isError('nasab')}
              label={props.t('PersonModal.labels.nasab')}
              onChange={props.onTextInputChange.bind(this, 'nasab')}
              required={props.isRequired('nasab')}
              value={props.item.nasab || ''}
            />
            <Form.Input
              error={props.isError('nisba')}
              label={props.t('PersonModal.labels.nisba')}
              onChange={props.onTextInputChange.bind(this, 'nisba')}
              required={props.isRequired('nisba')}
              value={props.item.nisba || ''}
            />
            <Form.Input
              error={props.isError('vocation')}
              label={props.t('PersonModal.labels.vocation')}
              onChange={props.onTextInputChange.bind(this, 'vocation')}
              required={props.isRequired('vocation')}
              value={props.item.vocation || ''}
            />
            <Form.Input
              error={props.isError('family_vocation')}
              label={props.t('PersonModal.labels.familyVocation')}
              onChange={props.onTextInputChange.bind(this, 'family_vocation')}
              required={props.isRequired('family_vocation')}
              value={props.item.family_vocation || ''}
            />
            <Form.TextArea
              error={props.isError('books_authored')}
              label={props.t('PersonModal.labels.booksAuthored')}
              onChange={props.onTextInputChange.bind(this, 'books_authored')}
              required={props.isRequired('books_authored')}
              value={props.item.books_authored || ''}
            />
            <DataFieldDropdown
              {...props}
              column='group'
              label={props.t('PersonModal.labels.group')}
              table='people'
            />
            <DataFieldDropdown
              {...props}
              column='theological_school'
              label={props.t('PersonModal.labels.theologicalSchool')}
              table='people'
            />
            <DataFieldDropdown
              {...props}
              column='social_class'
              label={props.t('PersonModal.labels.socialClass')}
              table='people'
            />
            <Form.Field
              name='other'
            >
              <label
                htmlFor='other'
              >
                { props.t('PersonModal.labels.other')}
              </label>
              <Form.Checkbox
                checked={props.item.alid}
                inline
                label={props.t('PersonModal.labels.alid')}
                onChange={props.onCheckboxInputChange.bind(this, 'alid')}
              />
              <Form.Checkbox
                checked={props.item.mawla}
                inline
                label={props.t('PersonModal.labels.mawla')}
                onChange={props.onCheckboxInputChange.bind(this, 'mawla')}
              />
              <Form.Checkbox
                checked={props.item.slave}
                inline
                label={props.t('PersonModal.labels.slave')}
                onChange={props.onCheckboxInputChange.bind(this, 'slave')}
              />
            </Form.Field>
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab
        name={props.t('Common.tabs.citations')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <EmbeddedList
              actions={[{
                name: 'edit'
              }, {
                name: 'copy'
              }, {
                name: 'delete'
              }]}
              columns={[{
                name: 'source',
                label: props.t('Common.citations.columns.source'),
                resolve: (c) => c.source.searchable_name
              }, {
                name: 'page_reference',
                label: props.t('Common.citations.columns.pageReference')
              }, {
                name: 'volume_reference',
                label: props.t('Common.citations.columns.volumeReference')
              }, {
                name: 'citation_type',
                label: props.t('Common.citations.columns.citationType')
              }]}
              items={props.item.citations}
              modal={{
                component: CitationModal
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, 'citations')}
              onSave={props.onSaveChildAssociation.bind(this, 'citations')}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab
        name={props.t('PersonModal.tabs.occupations')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <EmbeddedList
              actions={[{
                name: 'edit'
              }, {
                name: 'copy'
              }, {
                name: 'delete'
              }]}
              columns={[{
                name: 'position_english',
                label: props.t('PersonModal.occupations.columns.position.english'),
                resolve: (o) => o.position && o.position.name_english
              }, {
                className: 'arabic',
                name: 'position_arabic',
                label: props.t('PersonModal.occupations.columns.position.arabic'),
                resolve: (o) => o.position && o.position.name_arabic,
                hidden: true
              }, {
                name: 'position_transliteration',
                label: props.t('PersonModal.occupations.columns.position.transliteration'),
                resolve: (o) => o.position && o.position.name_transliteration,
                hidden: true
              }, {
                name: 'position_type',
                label: props.t('PersonModal.occupations.columns.position.type'),
                resolve: (o) => o.position && o.position.position_type
              }, {
                name: 'appointed_by',
                label: props.t('PersonModal.occupations.columns.appointer'),
                resolve: (o) => o.appointer && o.appointer.searchable_name
              }]}
              items={props.item.occupations}
              modal={{
                component: OccupationModal,
                props: {
                  citeable_id: props.item.id,
                  citeable_type: 'Person',
                }
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, 'occupations')}
              onSave={props.onSaveChildAssociation.bind(this, 'occupations')}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab
        name={props.t('Common.tabs.timespans')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <EmbeddedList
              actions={[{
                name: 'edit'
              }, {
                name: 'copy'
              }, {
                name: 'delete'
              }]}
              columns={[{
                name: 'name',
                label: props.t('Common.timespans.columns.name'),
                render: (c) => <TimespanInfo timespan={c.category} />
              }, {
                name: 'assigner',
                label: props.t('Common.timespans.columns.assigner')
              }]}
              items={_.where(props.item.categorizations, { category_type: 'Timespan' })}
              modal={{
                component: TimespanCategorizationModal,
                props: {
                  citeable_id: props.item.id,
                  citeable_type: 'Person'
                }
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
              onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab
        name={props.t('Common.tabs.locations')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <EmbeddedList
              actions={[{
                name: 'edit'
              }, {
                name: 'copy'
              }, {
                name: 'delete'
              }]}
              columns={[{
                name: 'place_english',
                label: props.t('Common.locations.columns.place.english'),
                resolve: (p) => p.place.name_english
              }, {
                className: 'arabic',
                name: 'place_arabic',
                label: props.t('Common.locations.columns.place.arabic'),
                resolve: (p) => p.place.name_arabic,
                hidden: true
              }, {
                name: 'place_transliteration',
                label: props.t('Common.locations.columns.place.transliteration'),
                resolve: (p) => p.place.name_transliteration,
                hidden: true
              }, {
                name: 'role',
                label: props.t('Common.locations.columns.role')
              }, {
                name: 'precision',
                label: props.t('Common.locations.columns.precision')
              }, {
                name: 'certainty',
                label: props.t('Common.locations.columns.certainty')
              }]}
              items={props.item.locations}
              modal={{
                component: LocationModal
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, 'locations')}
              onSave={props.onSaveChildAssociation.bind(this, 'locations')}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab
        name={props.t('Common.tabs.footnotes')}
      >
        <div style={{ display: 'flex' }}>
          {!_.isEmpty(props.externalDataContent)
          && (
            props.externalDataContent
          )}
          <div style={{ width: '100%' }}>
            <EmbeddedList
              actions={[{
                name: 'edit'
              }, {
                name: 'copy'
              }, {
                name: 'delete'
              }]}
              columns={[{
                name: 'label',
                label: props.t('Common.footnotes.columns.label')
              }, {
                name: 'content',
                label: props.t('Common.footnotes.columns.content')
              }]}
              items={props.item.footnotes}
              modal={{
                component: FootnoteModal
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, 'footnotes')}
              onSave={props.onSaveChildAssociation.bind(this, 'footnotes')}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      { props.children }
    </TabbedModal>
  </div>
);

export default withTranslation()(PersonModal);
