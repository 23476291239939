// @flow

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  ArrowButtons,
  EmbeddedList,
  FuzzyDate,
  TabbedModal,
  type EditModalProps,
  Selectize,
} from "react-components";
import uuid from "react-uuid";
import { Button, Form, Header } from "semantic-ui-react";
import _ from "underscore";
import DataFieldDropdown from "./DataFieldDropdown";
import FootnoteModal from "./FootnoteModal";
import FuzzyDateTransform from "../transforms/FuzzyDate";
import LocationModal from "./LocationModal";
import HoldingModal from "./HoldingModal";
import ParticipationModal from "./ParticipationModal";
import OrganizationModal from "./OrganizationModal";
import Organizations from "../services/Organizations";
import Session from "../services/Session";
import SourceRelationModal from "./SourceRelationModal";
import TimespanCategorizationModal from "./TimespanCategorizationModal";
import TimespanInfo from "./TimespanInfo";

import type { Source } from "../types/Source";

type Props = EditModalProps & {
  item: Source,
};

const SourceModal = (props: Props) => {
  const [modal, setModal] = useState(false);
  const currentUsername = Session.getUsername();
  const PUBLISHER_ORGANIZATION_TYPE = "publisher";

  return (
    <TabbedModal
      as={Form}
      centered={false}
      className="source-modal wide-modal"
      closeIcon
      onClose={props.onClose}
      header={
        props.item.id
          ? props.t("SourceModal.title.edit")
          : props.t("SourceModal.title.add")
      }
      open
      size="small"
    >
      <TabbedModal.Tab name={props.t("Common.tabs.details")}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div style={{ width: "100%" }}>
            <Form.Input
              autoFocus
              error={props.isError("title")}
              label={props.t("SourceModal.labels.sourceTitle")}
              onChange={props.onTextInputChange.bind(this, "title")}
              required={props.isRequired("title")}
              value={props.item.title || ""}
            />
            <Form.Input
              error={props.isError("short_title")}
              label={props.t("SourceModal.labels.shortTitle")}
              onChange={props.onTextInputChange.bind(this, "short_title")}
              required={props.isRequired("short_title")}
              value={props.item.short_title || ""}
            />
            <Form.Input
              error={props.isError("publication_title")}
              label={props.t("SourceModal.labels.publicationTitle")}
              onChange={props.onTextInputChange.bind(this, "publication_title")}
              required={props.isRequired("publication_title")}
              value={props.item.publication_title || ""}
            />
            <DataFieldDropdown
              {...props}
              allowAdditions={false}
              column="genre"
              label={props.t("SourceModal.labels.genre")}
              multiple
              table="sources"
            />
            <DataFieldDropdown
              {...props}
              allowAdditions={false}
              column="language"
              label={props.t("SourceModal.labels.language")}
              multiple
              onChange={props.onTextInputChange.bind(this, "language")}
              table="sources"
            />
            <DataFieldDropdown
              {...props}
              column="source_type"
              label={props.t("SourceModal.labels.sourceType")}
              table="sources"
            />
            <Form.Input
              error={props.isError("call_number")}
              label={props.t("SourceModal.labels.callNumber")}
              onChange={props.onTextInputChange.bind(this, "call_number")}
              required={props.isRequired("call_number")}
              value={props.item.call_number || ""}
            />
            <Form.Input
              error={props.isError("number_volumes")}
              label={props.t("SourceModal.labels.numberVolumes")}
              onChange={props.onTextInputChange.bind(this, "number_volumes")}
              required={props.isRequired("number_volumes")}
              type="number"
              value={props.item.number_volumes || ""}
            />
            <Form.Input
              error={props.isError("number_pages")}
              label={props.t("SourceModal.labels.numberPages")}
              onChange={props.onTextInputChange.bind(this, "number_pages")}
              required={props.isRequired("number_pages")}
              type="number"
              value={props.item.number_pages || ""}
            />
            <Form.Input
              error={props.isError("isbn")}
              label={props.t("SourceModal.labels.isbn")}
              onChange={props.onTextInputChange.bind(this, "isbn")}
              required={props.isRequired("isbn")}
              value={props.item.isbn || ""}
            />
            <Form.Input
              error={props.isError("doi")}
              label={props.t("SourceModal.labels.doi")}
              onChange={props.onTextInputChange.bind(this, "doi")}
              required={props.isRequired("doi")}
              value={props.item.doi || ""}
            />
            <Form.Input
              error={props.isError("issn")}
              label={props.t("SourceModal.labels.issn")}
              onChange={props.onTextInputChange.bind(this, "issn")}
              required={props.isRequired("issn")}
              value={props.item.issn || ""}
            />
            <Form.Input
              error={props.isError("url")}
              label={props.t("SourceModal.labels.url")}
              onChange={props.onTextInputChange.bind(this, "url")}
              required={props.isRequired("url")}
              value={props.item.url || ""}
            />
            <Form.Input
              error={props.isError("edition")}
              label={props.t("SourceModal.labels.edition")}
              onChange={props.onTextInputChange.bind(this, "edition")}
              required={props.isRequired("edition")}
              value={props.item.edition || ""}
            />
            <Form.Input
              error={props.isError("series_title")}
              label={props.t("SourceModal.labels.seriesTitle")}
              onChange={props.onTextInputChange.bind(this, "series_title")}
              required={props.isRequired("series_title")}
              value={props.item.series_title || ""}
            />
            <Form.Input
              error={props.isError("series_number")}
              label={props.t("SourceModal.labels.seriesNumber")}
              onChange={props.onTextInputChange.bind(this, "series_number")}
              required={props.isRequired("series_number")}
              value={props.item.series_number || ""}
            />
            <Form.Input
              error={props.isError("archive")}
              label={props.t("SourceModal.labels.archive")}
              onChange={props.onTextInputChange.bind(this, "archive")}
              required={props.isRequired("archive")}
              value={props.item.archive || ""}
            />
            <Form.Input
              error={props.isError("archive_location")}
              label={props.t("SourceModal.labels.archiveLocation")}
              onChange={props.onTextInputChange.bind(this, "archive_location")}
              required={props.isRequired("archive_location")}
              value={props.item.archive_location || ""}
            />
            <Form.Input
              error={props.isError("manual_tags")}
              label={props.t("SourceModal.labels.manualTags")}
              onChange={props.onTextInputChange.bind(this, "manual_tags")}
              required={props.isRequired("manual_tags")}
              value={props.item.manual_tags || ""}
            />
            <Form.Group widths="equal">
              <Form.Input
                error={props.isError("publication_date_ah")}
                label={props.t("SourceModal.labels.publicationDateAh")}
                required={props.isRequired("publication_date_ah")}
              >
                <FuzzyDate
                  calendar="hijri"
                  date={FuzzyDateTransform.toFuzzyDate(
                    props.item.publication_date_ah
                  )}
                  onChange={(data) =>
                    props.onSetState({
                      publication_date_ah: FuzzyDateTransform.toRelated(
                        props.item,
                        "publication_date_ah",
                        data
                      ),
                    })
                  }
                />
              </Form.Input>
              <ArrowButtons
                onLeft={() =>
                  props.onSetState({
                    publication_date_ah: FuzzyDateTransform.toCopy(
                      props.item.publication_date_ce
                    ),
                  })
                }
                onRight={() =>
                  props.onSetState({
                    publication__date_ce: FuzzyDateTransform.toCopy(
                      props.item.publication_date_ah
                    ),
                  })
                }
              />
              <Form.Input
                error={props.isError("publication_date_ce")}
                label={props.t("SourceModal.labels.publicationDateCe")}
                required={props.isRequired("publication_date_ce")}
              >
                <FuzzyDate
                  date={FuzzyDateTransform.toFuzzyDate(
                    props.item.publication_date_ce
                  )}
                  onChange={(data) =>
                    props.onSetState({
                      publication_date_ce: FuzzyDateTransform.toRelated(
                        props.item,
                        "publication_date_ce",
                        data
                      ),
                    })
                  }
                />
              </Form.Input>
            </Form.Group>
            <DataFieldDropdown
              {...props}
              allowAdditions={false}
              column="project"
              label={props.t("SourceModal.labels.project")}
              multiple
              table="sources"
            />
            <Form.Checkbox
              checked={!!props.item.vetted_by}
              disabled={
                props.item.vetted_by && props.item.vetted_by !== currentUsername
              }
              label={
                props.item.vetted_by
                  ? `${props.t("SourceModal.labels.vettedBy")}: ${
                      props.item.vetted_by
                    }`
                  : props.t("SourceModal.labels.vetRecord")
              }
              onChange={(data, { checked }) => {
                const value = checked ? currentUsername : null;
                props.onSetState({
                  vetted_by: value,
                });
              }}
            />
            {props.item.stacklife_id && (
              <p>{`${props.t("SourceModal.labels.stacklifeId")}: ${
                props.item.stacklife_id
              }`}</p>
            )}
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("SourceModal.tabs.participations")}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div style={{ width: "100%" }}>
            <EmbeddedList
              actions={[
                {
                  name: "edit",
                },
                {
                  name: "copy",
                },
                {
                  name: "delete",
                },
              ]}
              columns={[
                {
                  name: "name",
                  label: props.t("Common.participations.columns.name"),
                  resolve: (p) =>
                    p.participant.searchable_name
                      ? p.participant.searchable_name
                      : p.participant.name_english,
                },
                {
                  name: "role",
                  label: props.t("Common.participations.columns.role"),
                },
              ]}
              items={props.item.participations}
              modal={{
                component: ParticipationModal,
              }}
              onDelete={props.onDeleteChildAssociation.bind(
                this,
                "participations"
              )}
              onSave={props.onSaveChildAssociation.bind(this, "participations")}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("Common.tabs.timespans")}>
        <div
          style={{
            display: "flex",
          }}
        >
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div
            style={{
              width: "100%",
            }}
          >
            <EmbeddedList
              actions={[
                {
                  name: "edit",
                },
                {
                  name: "copy",
                },
                {
                  name: "delete",
                },
              ]}
              columns={[
                {
                  name: "name",
                  label: props.t("Common.timespans.columns.name"),
                  render: (c) => <TimespanInfo timespan={c.category} />,
                },
                {
                  name: "assigner",
                  label: props.t("Common.timespans.columns.assigner"),
                },
              ]}
              items={_.where(props.item.categorizations, {
                category_type: "Timespan",
              })}
              modal={{
                component: TimespanCategorizationModal,
              }}
              onDelete={props.onDeleteChildAssociation.bind(
                this,
                "categorizations"
              )}
              onSave={props.onSaveChildAssociation.bind(
                this,
                "categorizations"
              )}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("SourceModal.tabs.holdings")}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div style={{ width: "100%" }}>
            <EmbeddedList
              actions={[
                {
                  name: "edit",
                },
                {
                  name: "copy",
                },
                {
                  name: "delete",
                },
              ]}
              columns={[
                {
                  name: "organization",
                  label: props.t("SourceModal.labels.organization"),
                  render: (holding) => holding.organization.name,
                },
                {
                  name: "organization_type",
                  label: props.t("SourceModal.organizations.columns.type"),
                  render: (holding) => holding.organization.organization_type,
                },
                {
                  name: "role",
                  label: props.t("SourceModal.holdings.columns.role"),
                },
                {
                  name: "identifier_type",
                  label: props.t("SourceModal.holdings.columns.identifierType"),
                },
                {
                  name: "identifier",
                  label: props.t("SourceModal.holdings.columns.identifier"),
                },
              ]}
              items={_.filter(
                props.item.holdings,
                (holding) =>
                  holding.organization.organization_type !==
                  PUBLISHER_ORGANIZATION_TYPE
              )}
              modal={{
                component: HoldingModal,
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, "holdings")}
              onSave={props.onSaveChildAssociation.bind(this, "holdings")}
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("Common.tabs.locations")}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div style={{ width: "100%" }}>
            <EmbeddedList
              actions={[
                {
                  name: "edit",
                },
                {
                  name: "copy",
                },
                {
                  name: "delete",
                },
              ]}
              columns={[
                {
                  name: "place_english",
                  label: props.t("Common.locations.columns.place.english"),
                  resolve: (p) => p.place.name_english,
                },
                {
                  className: "arabic",
                  name: "place_arabic",
                  label: props.t("Common.locations.columns.place.arabic"),
                  resolve: (p) => p.place.name_arabic,
                  hidden: true,
                },
                {
                  name: "place",
                  label: props.t(
                    "Common.locations.columns.place.transliteration"
                  ),
                  resolve: (p) => p.place.name_transliteration,
                  hidden: true,
                },
                {
                  name: "role",
                  label: props.t("Common.locations.columns.role"),
                },
                {
                  name: "precision",
                  label: props.t("Common.locations.columns.precision"),
                },
                {
                  name: "certainty",
                  label: props.t("Common.locations.columns.certainty"),
                },
              ]}
              items={props.item.locations}
              modal={{
                component: LocationModal,
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, "locations")}
              onSave={props.onSaveChildAssociation.bind(this, "locations")}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("SourceModal.tabs.publishers")}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div style={{ width: "100%" }}>
            <EmbeddedList
              actions={[
                {
                  name: "delete",
                },
              ]}
              buttons={[
                {
                  render: () => (
                    <Button
                      basic
                      content={props.t("Common.buttons.multiAdd")}
                      icon="plus"
                      key="source"
                      onClick={() => setModal(true)}
                    />
                  ),
                },
              ]}
              columns={[
                {
                  name: "name",
                  label: props.t("SourceModal.organizations.columns.name"),
                  resolve: (holding) => holding.organization.name,
                },
              ]}
              items={_.filter(
                props.item.holdings,
                (holding) =>
                  holding.organization.organization_type ===
                  PUBLISHER_ORGANIZATION_TYPE
              )}
              onDelete={props.onDeleteChildAssociation.bind(this, "holdings")}
              onSave={props.onSaveChildAssociation.bind(this, "holdings")}
            />
            {modal && (
              <Selectize
                collectionName="organizations"
                onClose={() => setModal(false)}
                onLoad={(params) =>
                  Organizations.fetchAll(
                    _.extend(params, {
                      publishers: true,
                    })
                  )
                }
                onSave={(organizations) => {
                  setModal(false);
                  const find = (item) =>
                    _.find(
                      props.item.holdings,
                      (ps) => !ps._destroy && ps.organization_id === item.id
                    );

                  props.onMultiAddChildAssociations(
                    "holdings",
                    _.map(
                      organizations,
                      (item) =>
                        find(item) || {
                          uid: uuid(),
                          organization_id: item.id,
                          organization: item,
                          organization_type: PUBLISHER_ORGANIZATION_TYPE,
                        }
                    )
                  );
                }}
                modal={{
                  component: OrganizationModal,
                  props: {
                    showSources: false,
                  },
                  onSave: (organization) =>
                    Organizations.save(organization).then(({ data }) => {
                      return data.organization;
                    }),
                }}
                multiple
                renderItem={(item) => item.name}
                selectedItems={_.map(
                  _.filter(
                    props.item.holdings,
                    (holding) =>
                      !holding._destroy &&
                      holding.organization.organization_type ===
                        PUBLISHER_ORGANIZATION_TYPE
                  ),
                  (holding) => holding.organization
                )}
                title={props.t("SourceModal.labels.publishers")}
              />
            )}
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("Common.tabs.footnotes")}>
        <div style={{ display: "flex" }}>
          {!_.isEmpty(props.externalDataContent) && props.externalDataContent}
          <div style={{ width: "100%" }}>
            <EmbeddedList
              actions={[
                {
                  name: "edit",
                },
                {
                  name: "copy",
                },
                {
                  name: "delete",
                },
              ]}
              columns={[
                {
                  name: "label",
                  label: props.t("Common.footnotes.columns.label"),
                },
                {
                  name: "content",
                  label: props.t("Common.footnotes.columns.content"),
                },
              ]}
              items={props.item.footnotes}
              modal={{
                component: FootnoteModal,
              }}
              onDelete={props.onDeleteChildAssociation.bind(this, "footnotes")}
              onSave={props.onSaveChildAssociation.bind(this, "footnotes")}
              showRecordCount
            />
          </div>
        </div>
      </TabbedModal.Tab>
      <TabbedModal.Tab name={props.t("SourceModal.tabs.relations")}>
        <Header content={props.t("SourceModal.labels.baseTexts")} />
        <EmbeddedList
          actions={[
            {
              name: "edit",
            },
            {
              name: "copy",
            },
            {
              name: "delete",
            },
          ]}
          columns={[
            {
              name: "label",
              label: props.t("Sources.columns.sourceTitle"),
              resolve: (parent) =>
                parent.parent_source && parent.parent_source.searchable_name,
            },
            {
              name: "content",
              label: props.t("SourceModal.labels.sourceType"),
              resolve: (parent) =>
                parent.parent_source && parent.parent_source.source_type,
            },
            {
              name: "relation_type",
              label: props.t("SourceModal.labels.relationType"),
            },
          ]}
          items={props.item.parent_source_relations}
          modal={{
            component: SourceRelationModal,
            props: {
              relation: "parent",
            },
          }}
          onDelete={props.onDeleteChildAssociation.bind(
            this,
            "parent_source_relations"
          )}
          onSave={props.onSaveChildAssociation.bind(
            this,
            "parent_source_relations"
          )}
          showRecordCount
        />
        <Header content={props.t("SourceModal.labels.commentaries")} />
        <EmbeddedList
          actions={[
            {
              name: "edit",
            },
            {
              name: "copy",
            },
            {
              name: "delete",
            },
          ]}
          columns={[
            {
              name: "source_title",
              label: props.t("Sources.columns.sourceTitle"),
              resolve: (child) =>
                child.child_source && child.child_source.searchable_name,
            },
            {
              name: "source_type",
              label: props.t("SourceModal.labels.sourceType"),
              resolve: (child) =>
                child.child_source && child.child_source.source_type,
            },
            {
              name: "relation_type",
              label: props.t("SourceModal.labels.relationType"),
            },
          ]}
          items={props.item.child_source_relations}
          modal={{
            component: SourceRelationModal,
            props: {
              relation: "child",
            },
          }}
          onDelete={props.onDeleteChildAssociation.bind(
            this,
            "child_source_relations"
          )}
          onSave={props.onSaveChildAssociation.bind(
            this,
            "child_source_relations"
          )}
          showRecordCount
        />
      </TabbedModal.Tab>
      {props.children}
    </TabbedModal>
  );
};

export default withTranslation()(SourceModal);
