// @flow

import _ from 'underscore';
import type { CanonType as CanonTypeType } from '../types/CanonType';

/**
 * Class for handling transforming canon type records.
 */
class CanonType {
  PAYLOAD_KEYS = [
    'name_arabic',
    'name_english',
    'name_transliteration',
    'parent_canon_type_id',
    'assigner'
  ];

  /**
   * Returns the canon type object to be sent to the server on POST/PUT requests.
   *
   * @param canonType
   *
   * @returns {*}
   */
  toPayload(canonType: CanonTypeType) {
    return { canon_type: _.pick(canonType, this.PAYLOAD_KEYS) };
  }
}

export default new CanonType();
