// @flow

import _ from 'underscore';
import type { Referable } from '../types/concerns/Referable';

/**
 * Class for handling transforming references records.
 */
class References {
  PAYLOAD_KEYS = [
    'id',
    'referent_id',
    'referent_type',
    'citation_id',
    'explicit',
    '_destroy'
  ];

  /**
   * Returns the references for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param referable
   *
   * @returns {{references: *}}
   */
  toPayload(referable: Referable) {
    return {
      references: _.map(referable.references, (r) => _.pick(r, this.PAYLOAD_KEYS))
    };
  }
}

export default new References();
