// @flow

import React from 'react';
import { FuzzyDate, type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';
import FuzzyDateTransform from '../transforms/FuzzyDate';

import type { FuzzyDate as FuzzyDateType } from '../types/FuzzyDate';

type Props = EditModalProps & {
  item: {
    case_type: Array<string>,
    end_date: FuzzyDateType,
    start_date: FuzzyDateType
  }
};

const CasesFiltersModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='cases-filters-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.t('CasesFiltersModal.title')}
    />
    <Modal.Content>
      <DataFieldDropdown
        {...props}
        column='case_type'
        label={props.t('CasesFiltersModal.labels.caseType')}
        multiple
        table='cases'
      />
      <Form.Input
        label={props.t('CasesFiltersModal.labels.startDate')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.start_date)}
          description={false}
          onChange={(data) => props.onSetState({
            start_date: FuzzyDateTransform.toFilterDate(props.item, 'start_date', data)
          })}
          title={props.t('CasesFiltersModal.labels.startDate')}
        />
      </Form.Input>
      <Form.Input
        label={props.t('CasesFiltersModal.labels.endDate')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.end_date)}
          description={false}
          onChange={(data) => props.onSetState({
            end_date: FuzzyDateTransform.toFilterDate(props.item, 'end_date', data)
          })}
          title={props.t('CasesFiltersModal.labels.endDate')}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(CasesFiltersModal);
