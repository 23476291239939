// @flow

import BaseService from './BaseService';
import Source from '../transforms/Source';

/**
 * Service class for all source based API calls.
 */
class Sources extends BaseService {
  /**
   * Returns the sources base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/sources';
  }

  /**
   * Returns the sources transform object.
   *
   * @returns {Canon}
   */
  getTransform() {
    return Source;
  }
}

export default new Sources();
