// @flow

import BaseService from './BaseService';
import Group from '../transforms/Group';

/**
 * Service class for all group based API calls.
 */
class Groups extends BaseService {
  /**
   * Returns the group base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/groups';
  }

  /**
   * Returns the group transform object.
   *
   * @returns {Group}
   */
  getTransform() {
    return Group;
  }
}

export default new Groups();
