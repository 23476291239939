// @flow

import React, { Component } from 'react';
import { type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import uuid from 'react-uuid';
import './GroupModal.css';

import type { Group } from '../types/Group';

type Props = EditModalProps & {
  item: Group,
  modal: Component<{}>
};

type State = {
  showModal: boolean
};

class GroupModal extends Component<Props, State> {
  /**
   * Constructs a new GroupModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  /**
   * Closes the multi-add modal component.
   */
  onClose() {
    this.setState({ showModal: false });
  }

  /**
   * Replces the item and the passed dragIndex with the item at the passed hoverIndex.
   *
   * @param dragIndex
   * @param hoverIndex
   */
  onDrag(dragIndex, hoverIndex) {
    const items = [...this.props.item.memberships];
    const item = items[dragIndex];

    items.splice(dragIndex, 1);
    items.splice(hoverIndex, 0, item);

    this.props.onSetState({ memberships: items });
  }

  /**
   * Opens the multi-add modal component.
   */
  onMultiAdd() {
    this.setState({ showModal: true });
  }

  /**
   * Saves the passed items after the multi-add modal component is saved.
   *
   * @param memberableType
   * @param items
   */
  onSave(memberableType, items) {
    const findMembership = (item) => _.findWhere(this.props.item.memberships, {
      memberable_id: item.id,
      memberable_type: memberableType
    });

    const createMembership = (item) => ({
      uid: uuid(),
      memberable_id: item.id,
      memberable: item,
      memberable_type: memberableType,
      assigner: 'Researcher'
    });

    // Set the selected canon types on the current item
    const memberships = _.map(items, (item) => findMembership(item) || createMembership(item));
    this.props.onMultiAddChildAssociations('memberships', memberships);

    // Hide the modal
    this.onClose();
  }

  /**
   * Renders the GroupModal component.
   *
   * @returns {*}
   */
  render() {
    const GroupModalComponent = this.props.modal;

    return (
      <GroupModalComponent
        {...this.props}
        className='group-modal'
        closeIcon
        onClose={this.onClose}
        onCloseMultiAdd={this.onClose.bind(this)}
        onDrag={this.onDrag.bind(this)}
        onMultiAdd={this.onMultiAdd.bind(this)}
        onSaveMultiAdd={this.onSave.bind(this)}
        showMultiAdd={this.state.showModal}
      />
    );
  }
}

export type GroupModalProps = {
  onCloseMultiAdd: () => void,
  onDrag: (dragIndex: number, hoverIndex: number) => void,
  onMultiAdd: () => void,
  onSaveMultiAdd: (memberableType: string, items: Array<any>) => void,
  showMultiAdd: boolean
};

export default withTranslation()(GroupModal);
