// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import CaseModal from '../components/CaseModal';
import CasesFiltersModal from '../components/CasesFiltersModal';
import CasesService from '../services/Cases';

type Props = {
  t: (key: string) => string
};

const Cases = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='cases'
    collectionName='cases'
    columns={[{
      name: 'searchable_name',
      label: props.t('Cases.columns.name'),
      sortable: true
    }, {
      name: 'case_type',
      label: props.t('Cases.columns.caseType'),
      sortable: true
    }]}
    filters={{
      component: CasesFiltersModal,
      props: {
        case_type: [],
        end_date: null,
        start_date: null
      }
    }}
    modal={{
      component: CaseModal,
      props: {
        onInitialize: (id) => CasesService.fetchOne(id).then(({ data }) => data.case)
      }
    }}
    onDelete={(c) => CasesService.delete(c)}
    onLoad={(params) => CasesService.fetchAll(params)}
    onSave={(c) => CasesService.save(c)}
    showRecordCount
  />
);

export default withTranslation()(Cases);
