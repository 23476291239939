// @flow

import BaseService from './BaseService';
import Rationale from '../transforms/Rationale';

/**
 * Service class for all rationale based API calls.
 */
class Rationales extends BaseService {
  /**
   * Returns the rationale base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/rationales';
  }

  /**
   * Returns the rationale transform object.
   *
   * @returns {Rationale}
   */
  getTransform() {
    return Rationale;
  }
}

export default new Rationales();
