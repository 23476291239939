// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import PeopleFiltersModal from '../components/PeopleFiltersModal';
import PeopleService from '../services/People';
import PersonModal from '../components/PersonModal';
import './People.css';

type Props = {
  t: (key: string) => string
};

const People = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='people'
    collectionName='people'
    columns={[{
      name: 'searchable_name',
      label: props.t('People.columns.name'),
      sortable: true
    }]}
    filters={{
      component: PeopleFiltersModal,
      props: {
        modern: '',
        birth_date_ah: null,
        birth_date_ce: null,
        death_date_ah: null,
        death_date_ce: null,
        genre: '',
        place_id: ''
      }
    }}
    modal={{
      component: PersonModal,
      props: {
        onInitialize: (id) => PeopleService.fetchOne(id).then(({ data }) => data.person)
      }
    }}
    onDelete={(person) => PeopleService.delete(person)}
    onLoad={(params) => PeopleService.fetchAll(params)}
    onSave={(person) => PeopleService.save(person)}
    showRecordCount
  />
);

export default withTranslation()(People);
