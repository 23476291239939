import {
  Button,
  Icon,
  Modal
} from 'semantic-ui-react';
import React, { useState } from 'react';
import { ListTable } from 'react-components';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import BibliographyModal from './BibliographyModal';
import BibliographyService from '../services/Bibliography';
import Session from '../services/Session';

const userName = Session.getUsername();

type Props = {
  open: boolean,
  onClose: ()=>void,
  onSave: ()=>void,
  selectedBib: {id: number},
  t: (key: string) => string,
};

const SelectBibliographyModal = (props: Props) => {
  const [selectedBib, setSelectedBib] = useState({});
  const handleBibSelect = ({ checked }, source) => {
    if (checked) {
      setSelectedBib(source);
    } else {
      setSelectedBib({});
    }
  };
  const handleClose = () => {
    setSelectedBib({});
    props.onClose();
  };

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
    >
      <Modal.Header>
        {props.t('Sources.SelectBibliographyModal.header')}
      </Modal.Header>
      <Modal.Content>
        <ListTable
          className='bibliographies'
          collectionName='bibliographies'
          columns={[{
            name: 'name->>english',
            label: props.t('Bibliographies.columns.name'),
            render: (b) => b.name_english,
            sortable: true
          }, {
            name: 'bibliographies_sources',
            className: 'narrow-column',
            label: props.t('Bibliographies.columns.totalSources'),
            render: (b) => b.bibliographies_sources.length,
            sortable: false
          }, {
            name: 'public',
            label: props.t('Bibliographies.columns.public'),
            className: 'narrow-column',
            render: (b) => (b.public
              ? (
                <p style={{ textAlign: 'center' }}>
                  <Icon name='check' />
                </p>
              )
              : ''),
            sortable: false
          }, {
            name: 'owner',
            className: 'narrow-column',
            label: props.t('Bibliographies.columns.owner'),
            sortable: true
          }]}
          modal={{
            component: BibliographyModal,
            props: {
              onInitialize: (id) => BibliographyService.fetchOne(id).then((resp) => resp.data.bibliography)
            }
          }}
          onLoad={(params) => BibliographyService.fetchAll({ ...params, owner: userName })}
          onRowSelect={handleBibSelect}
          onSave={(b) => {
            const bib = { ...b, owner: b.owner ? b.owner : userName };
            return BibliographyService.save(bib);
          }}
          selectable
          selectedRows={[selectedBib]}
          showRecordCount
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={_.isEmpty(selectedBib)}
          onClick={() => {
            handleClose();
            props.onSave(selectedBib);
          }}
          primary
        >
          {props.t('Common.buttons.save')}
        </Button>
        <Button
          color='blue'
          basic
          onClick={handleClose}
        >
          {props.t('Common.buttons.cancel')}
        </Button>
      </Modal.Actions>
    </Modal>

  );
};

export default withTranslation()(SelectBibliographyModal);
