// @flow

import React, { useEffect } from 'react';
import {
  AccordionSelector,
  AssociatedDropdown,
  ModalDropdown,
  type EditModalProps
} from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import CanonTypes from '../services/CanonTypes';
import CitationsService from '../services/Citations';
import Citations from '../transforms/Citations';
import TranslateableField from './TranslateableField';

import type { Categorization } from '../types/Categorization';

type Props = EditModalProps & {
  citeable_id: number,
  citeable_type: string,
  item: Categorization
};

const ASSIGNER_SOURCE = 'Source';
const SELECTABLE_NODE_LEVEL = 1;

const CanonTypeCategorizationModal = (props: Props) => {
  // Default the category_type to "CanonType"
  useEffect(() => {
    props.onTextInputChange('category_type', null, { value: 'CanonType' });
  }, []);

  return (
    <Modal
      as={Form}
      className='canon-type-categorization-modal'
      closeIcon
      onClose={props.onClose}
      noValidate
      open
      size='small'
    >
      <Modal.Header
        content={props.item.id
          ? props.t('CanonTypeCategorizationModal.title.edit')
          : props.t('CanonTypeCategorizationModal.title.add')}
      />
      <Modal.Content>
        <DataFieldDropdown
          {...props}
          column='assigner'
          label={props.t('CanonTypeCategorizationModal.labels.assigner')}
          table='categorizations'
        />
        <Form.Input
          error={props.isError('category_id')}
          label={props.t('CanonTypeCategorizationModal.labels.canonType')}
          required={props.isRequired('category_id')}
        >
          <ModalDropdown
            onClear={props.onAssociationInputChange.bind(this, 'category_id', 'category')}
            renderModal={({ onClose, open }) => (
              <AccordionSelector
                collectionName='canon_types'
                getChildItems={(items, item) => _.where(items, { parent_canon_type_id: item.id })}
                getRootItems={(items) => _.where(items, { parent_canon_type_id: null })}
                isSelectable={(item) => props.item.assigner === ASSIGNER_SOURCE
                  || item.node_level === SELECTABLE_NODE_LEVEL}
                onClose={onClose}
                onSave={(items) => {
                  props.onAssociationInputChange('category_id', 'category', _.first(items));
                  onClose();
                }}
                onSearch={(parentId, search) => CanonTypes.fetchAll({ parent_canon_type_id: parentId, search })}
                open={open}
                renderItem={(item) => <TranslateableField property='name' translateable={item} />}
                selectedItems={props.item.category ? [props.item.category] : []}
                showToggle={(item) => item.node_level < SELECTABLE_NODE_LEVEL}
                title={props.t('CanonTypeCategorizationModal.title.select')}
              />
            )}
            searchQuery={props.item.category && props.item.category.name_english}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('citation_id')}
          label={props.t('CanonTypeCategorizationModal.labels.citation')}
          required={props.isRequired('citation_id')}
        >
          <AssociatedDropdown
            collectionName='citations'
            onSearch={(search) => CitationsService.fetchAll({
              search,
              sort_by: 'sources.searchable_name',
              citeable_id: props.citeable_id,
              citeable_type: props.citeable_type
            })}
            onSelection={props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
            renderOption={Citations.toDropdown.bind(this)}
            searchQuery={props.item.citation
              && props.item.citation.source
              && props.item.citation.source.searchable_name}
            value={props.item.citation_id}
          />
        </Form.Input>
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(CanonTypeCategorizationModal);
