// @flow

import BaseService from './BaseService';
import DataFieldOption from '../transforms/DataFieldOption';

/**
 * Service class for all data field option based API calls.
 */
class DataFieldOptions extends BaseService {
  /**
   * Returns the data field options base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/data_field_options';
  }

  /**
   * Returns the data field options transform object.
   *
   * @returns {DataFieldOption}
   */
  getTransform() {
    return DataFieldOption;
  }
}

export default new DataFieldOptions();
