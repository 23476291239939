// @flow

import _ from 'underscore';
import Holdings from './Holdings';

import type { Organization as OrganizationType } from '../types/Organization';

/**
 * Class for handling transforming organization records.
 */
class Organization {
  PAYLOAD_KEYS = [
    'name',
    'organization_type',
    '_destroy'
  ];

  /**
   * Returns the passed organization as a dropdown option.
   *
   * @param organization
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(organization: OrganizationType) {
    return {
      key: organization.id,
      value: organization.id,
      text: organization.name
    };
  }

  /**
   * Returns the organization object to be sent to the server on POST/PUT requests.
   *
   * @param organization
   *
   * @returns {{organization: {occupations: *}}}
   */
  toPayload(organization: OrganizationType) {
    return {
      organization: {
        ..._.pick(organization, this.PAYLOAD_KEYS),
        ...Holdings.toPayload(organization)
      }
    };
  }
}

export default new Organization();
