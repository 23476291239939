// @flow

import _ from 'underscore';

import type { Source } from '../types/Source';

/**
 * Class for handling transforming parent source relations records.
 */
class ParentSourceRelations {
  PAYLOAD_KEYS = [
    'id',
    'parent_source_id',
    'relation_type',
    '_destroy'
  ];

  /**
   * Returns the parent source relations for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param item
   *
   * @returns {{parent_source_relations: *}}
   */
  toPayload(item: Source) {
    return {
      parent_source_relations: _.map(item.parent_source_relations, (psr) => _.pick(psr, this.PAYLOAD_KEYS))
    };
  }
}

export default new ParentSourceRelations();
