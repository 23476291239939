// @flow

import BaseService from './BaseService';

/**
 * Service class for all genre place based API calls.
 */
class GenrePlaces extends BaseService {
  /**
   * Returns the genre places base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/genre_places';
  }
}

export default new GenrePlaces();
