// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  GoogleMap,
  GooglePlacesSearch,
  KeyboardField,
  type EditModalProps
} from 'react-components';
import {
  Button,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import DescriptionLabel from './DescriptionLabel';
import layouts from '../utilities/customKeyboardLayouts';
import withDescriptions from './DataFieldDescription';
import './PlaceModal.css';

import type { Place } from '../types/Place';

type Props = EditModalProps & {
  item: Place
};

const DEFAULT_ZOOM = 3;
const LOCATION_ZOOM = 10;

const PlaceModal = (props: Props) => {
  /**
   * Renders the latitude and longitude coordinates.
   *
   * @param place
   * @param onSetState
   * @param t
   *
   * @returns {null|*}
   */
  const renderCoordinates = (place, onSetState, t) => {
    if (!(place.lat && place.lng)) {
      return null;
    }

    return (
      <>
        <span
          className='coordinates'
        >
          { `${place.lat}, ${place.lng}` }
        </span>
        <Button
          basic
          content={t('Common.buttons.clear')}
          icon='times'
          onClick={() => onSetState({ name_english: '', lat: null, lng: null })}
          type='button'
        />
      </>
    );
  };

  return (
    <Modal
      as={Form}
      className='place-modal'
      closeIcon
      onClose={props.onClose}
      open
      size='small'
    >
      <Modal.Header>
        <Grid
          columns={2}
          verticalAlign='middle'
        >
          <Grid.Column
            textAlign='left'
          >
            <Header
              content={props.item.id
                ? props.t('PlaceModal.title.edit')
                : props.t('PlaceModal.title.add')}
            />
          </Grid.Column>
          <Grid.Column
            textAlign='right'
          >
            { renderCoordinates(props.item, props.onSetState, props.t) }
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <GooglePlacesSearch
          containerElement={<Form.Field />}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onLocationSelection={({ name, lat, lng }) => props.onSetState({ name_english: name, lat, lng })}
        >
          <Form.Input
            autoFocus
            error={props.isError('name_english')}
            label={props.t('PlaceModal.labels.name.english')}
            placeholder=''
            onChange={props.onTextInputChange.bind(this, 'name_english')}
            required={props.isRequired('name_english')}
            value={props.item.name_english || ''}
          />
        </GooglePlacesSearch>
        <KeyboardField
          error={props.isError('name_arabic')}
          id='name_arabic'
          label={props.t('PlaceModal.labels.name.arabic')}
          layout={Arabic}
          name='name_arabic'
          onChange={props.onTextInputChange.bind(this, 'name_arabic')}
          required={props.isRequired('name_arabic')}
          value={props.item.name_arabic || ''}
        >
          <input
            className='arabic'
            dir='rtl'
            type='text'
          />
        </KeyboardField>
        <KeyboardField
          error={props.isError('name_transliteration')}
          id='name_transliteration'
          label={props.t('PlaceModal.labels.name.transliteration')}
          layout={layouts.transliterationIjmes}
          name='name_transliteration'
          onChange={props.onTextInputChange.bind(this, 'name_transliteration')}
          required={props.isRequired('name_transliteration')}
          value={props.item.name_transliteration || ''}
        />
        <DataFieldDropdown
          {...props}
          column='period'
          label={(
            <DescriptionLabel
              description={props.descriptions.period}
              htmlFor='period'
              label={props.t('PlaceModal.labels.period')}
            />
          )}
          multiple
          table='places'
        />
        <GoogleMap
          defaultZoom={DEFAULT_ZOOM}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onDragEnd={({ lat, lng }) => props.onSetState({ lat, lng })}
          position={props.item.lat && props.item.lng ? _.pick(props.item, 'lat', 'lng') : undefined}
          zoom={props.item.lat && props.item.lng ? LOCATION_ZOOM : undefined}
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(withDescriptions(PlaceModal, 'places'));
