// @flow

import React from 'react';
import { type EditContainerProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

type Props = EditContainerProps & {
  className?: string,
  title: {
    add: string,
    edit: string
  }
};

const DataFieldOptionModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className={`data-field-option-modal ${props.className ? props.className : ''}`}
    closeIcon
    onClose={props.onClose}
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id ? props.title.edit : props.title.add}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('value')}
        label={props.t('DataFieldOptionModal.labels.value')}
        onChange={props.onTextInputChange.bind(this, 'value')}
        required={props.isRequired('value')}
        value={props.item.value || ''}
      />
      <Form.Input
        error={props.isError('description')}
        label={props.t('DataFieldOptionModal.labels.description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        required={props.isRequired('description')}
        value={props.item.description || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(DataFieldOptionModal);
