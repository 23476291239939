// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import PositionModal from '../components/PositionModal';
import PositionsFiltersModal from '../components/PositionsFiltersModal';
import PositionsService from '../services/Positions';

type Props = {
  t: (key: string) => string
};

const Positions = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='positions'
    collectionName='positions'
    columns={[{
      name: 'position_type',
      label: props.t('Positions.columns.positionType'),
      sortable: true
    }, {
      name: 'name->>english',
      label: props.t('Positions.columns.name.english'),
      resolve: (position) => position.name_english,
      sortable: true
    }, {
      name: 'name->>arabic',
      label: props.t('Positions.columns.name.arabic'),
      resolve: (position) => position.name_arabic,
      sortable: true,
      hidden: true
    }, {
      name: 'name->>transliteration',
      label: props.t('Positions.columns.name.transliteration'),
      resolve: (position) => position.name_transliteration,
      sortable: true,
      hidden: true
    }]}
    filters={{
      component: PositionsFiltersModal,
      props: {
        position_type: ''
      }
    }}
    modal={{
      component: PositionModal
    }}
    onDelete={(position) => PositionsService.delete(position)}
    onLoad={(params) => PositionsService.fetchAll(params)}
    onSave={(position) => PositionsService.save(position)}
    showRecordCount
  />
);

export default withTranslation()(Positions);
