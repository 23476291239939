// @flow

import React from 'react';
import { FuzzyDate, type EditModalProps, AssociatedDropdown, ModalDropdown, AccordionSelector } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';
import FuzzyDateTransform from '../transforms/FuzzyDate';
import People from '../services/People';
import Person from '../transforms/Person';
import Places from '../services/Places';
import Place from '../transforms/Place';

import type { FuzzyDate as FuzzyDateType } from '../types/FuzzyDate';
import type { Person as PersonType } from '../types/Person';
import Timespans from '../services/Timespans';
import Timespan from '../transforms/Timespan';
import _ from 'underscore';

type Props = EditModalProps & {
  item: {
    author: PersonType,
    author_id: number,
    publication_date_ah: FuzzyDateType,
    publication_date_ce: FuzzyDateType,
    source_type: string
  }
};

const MAX_NODE_LEVEL = 3;

const SourcesFiltersModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='sources-filters-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.t('SourcesFiltersModal.title')}
    />
    <Modal.Content>
      <DataFieldDropdown
        {...props}
        allowAdditions={false}
        column='source_type'
        label={props.t('SourcesFiltersModal.labels.sourceType')}
        table='sources'
      />
      <DataFieldDropdown
        {...props}
        allowAdditions={false}
        column='genre'
        label={props.t('SourcesFiltersModal.labels.genre')}
        multiple
        table='sources'
      />
      <Form.Input
        label={props.t('SourcesFiltersModal.labels.author')}
      >
        <AssociatedDropdown
          collectionName='people'
          onSearch={(search) => People.fetchAll({ search, sort_by: 'searchable_name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'author_id', 'author')}
          renderOption={Person.toDropdown.bind(this)}
          searchQuery={props.item.author && props.item.author.searchable_name}
          value={props.item.author_id || ''}
        />
      </Form.Input>
      <Form.Input
        label={props.t('SourcesFiltersModal.labels.place')}
      >
        <AssociatedDropdown
          collectionName='places'
          onSearch={(search) => Places.fetchAll({ search, sort_by: 'name->>english' })}
          onSelection={props.onAssociationInputChange.bind(this, 'place_id', 'place')}
          renderOption={Place.toDropdown.bind(this)}
          searchQuery={props.item.place && props.item.place.name_english}
          value={props.item.place_id || ''}
        />
      </Form.Input>
      <Form.Input
        label={props.t('SourcesFiltersModal.labels.timespan')}
      >
        <ModalDropdown
          onClear={props.onAssociationInputChange.bind(this, 'timespan_id', 'timespan')}
          renderModal={({ onClose, open }) => (
            <AccordionSelector
              collectionName='timespans'
              getChildItems={(items, item) => _.where(items, { parent_timespan_id: item.id })}
              getRootItems={(items) => _.where(items, { parent_timespan_id: null })}
              isSelectable={() => true}
              onClose={onClose}
              onSave={(items) => {
                props.onAssociationInputChange('timespan_id', 'timespan', _.first(items));
                onClose();
              }}
              onSearch={(parentId, search) => Timespans.fetchAll({ parent_timespan_id: parentId, search })}
              open={open}
              renderItem={(item) => item.searchable_name}
              selectedItems={props.item.timespan ? [props.item.timespan] : []}
              showToggle={(item) => item.node_level < MAX_NODE_LEVEL}
            />
          )}
          searchQuery={props.item.timespan && props.item.timespan.searchable_name}
        />
      </Form.Input>
      <Form.Input
        label={props.t('SourcesFiltersModal.labels.publicationDateAh')}
      >
        <FuzzyDate
          calendar='hijri'
          date={FuzzyDateTransform.toFuzzyDate(props.item.publication_date_ah)}
          description={false}
          onChange={(data) => props.onSetState({
            publication_date_ah: FuzzyDateTransform.toFilterDate(props.item, 'publication_date_ah', data)
          })}
          title={props.t('SourcesFiltersModal.labels.publicationDateAh')}
        />
      </Form.Input>
      <Form.Input
        label={props.t('SourcesFiltersModal.labels.publicationDateCe')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.publication_date_ce)}
          description={false}
          onChange={(data) => props.onSetState({
            publication_date_ce: FuzzyDateTransform.toFilterDate(props.item, 'publication_date_ce', data)
          })}
          title={props.t('SourcesFiltersModal.labels.publicationDateCe')}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(SourcesFiltersModal);
