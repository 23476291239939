// @flow

import React from "react";
import { ListTable } from "react-components";
import { WithTranslation, withTranslation } from "react-i18next";
import DataFieldOptionModal from "./DataFieldOptionModal";
import DataFieldOptions from "../services/DataFieldOptions";
import Session from "../services/Session";

type Props = WithTranslation & {
  className: string,
  column: string,
  modal: any,
  table: string,
};

const DataFieldOptionsList = (props: Props) => {
  // Only allow adding/editing if the current user is an admin
  let actions = [...props.actions];
  let addButton = {};

  if (Session.isAdmin()) {
    actions = [
      ...actions,
      {
        name: "edit",
      },
      {
        name: "copy",
      },
      {
        name: "delete",
      },
    ];

    addButton = undefined;
  }

  // Resolve any validation errors
  const resolveValidationError = (error, item, status) => {
    const errors = [];

    if (status === 401) {
      errors.push(props.t("DataFieldOptionsList.errors.unauthorized"));
    }

    return errors;
  };

  return (
    <ListTable
      actions={actions}
      addButton={addButton}
      className={props.className}
      collectionName="data_field_options"
      columns={[
        {
          name: "value",
          label: props.t("DataFieldOptionsList.columns.value"),
          sortable: true,
        },
        {
          name: "description",
          label: props.t("DataFieldOptionsList.columns.description"),
          sortable: true,
        },
      ]}
      modal={{
        component: DataFieldOptionModal,
        props: {
          onInitialize: (id) =>
            DataFieldOptions.fetchOne(id).then(
              ({ data }) => data.data_field_option
            ),
          defaults: {
            table: props.table,
            column: props.column,
          },
          resolveValidationError,
          ...props.modal,
        },
      }}
      onDelete={(dfo) => DataFieldOptions.delete(dfo)}
      onLoad={(params) =>
        DataFieldOptions.fetchAll({
          ...params,
          table: props.table,
          column: props.column,
        })
      }
      onSave={(dfo) => DataFieldOptions.save(dfo)}
      showRecordCount
    />
  );
};

DataFieldOptionsList.defaultProps = {
  actions: [],
};

export default withTranslation()(DataFieldOptionsList);
