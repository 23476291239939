// @flow

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import DataFieldOptionsList from "../components/DataFieldOptionsList";

const Projects = (props: WithTranslation) => (
  <DataFieldOptionsList
    actions={[
      {
        name: "discover",
        icon: "search",
        popup: {
          content: props.t("Projects.actions.discover.content"),
          title: props.t("Projects.actions.discover.title"),
        },
        onClick: (item) => props.history.push(`/entry/projects/${item.id}`),
      },
    ]}
    className="projects"
    column="project"
    modal={{
      required: ["value"],
      title: {
        add: props.t("Projects.modal.title.add"),
        edit: props.t("Projects.modal.title.edit"),
      },
    }}
    table="sources"
  />
);

export default withTranslation()(withRouter(Projects));
