// @flow

import React, { Component } from 'react';
import {
  Button,
  Container,
  Grid,
  Icon,
  Segment
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { withRouter, type RouterHistory, Link } from 'react-router-dom';
import { LoginModal } from 'react-components';
import Authentication from '../services/Authentication';
import Session from '../services/Session';
import './Home.css';

type Props = {
  history: RouterHistory,
  t: (key: string) => string
}

type State = {
  loginFailed: boolean,
  password: ?string,
  showModal: boolean,
  username: ?string
}

class Home extends Component<Props, State> {
  /**
   * Constructs a new Public view.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      loginFailed: false,
      password: null,
      showModal: false,
      username: null
    };
  }

  /**
   * Closes the modal.
   */
  onCloseModal() {
    this.setState({ loginFailed: false, showModal: false });
  }

  /**
   * Calls the API to authenticate the user, creates the session, and navigates to the admin page.
   */
  onLogin() {
    const { username, password } = this.state;

    Authentication
      .login(username, password)
      .then((response) => {
        Session.create(response);
        this.props.history.push('/entry');
      })
      .catch(() => {
        this.setState({ loginFailed: true });
        Session.destroy();
      });
  }

  /**
   * Navigates to the admin page if the user is logged in, otherwise opens the login modal.
   *
   * @returns {*}
   */
  onLoginButton() {
    return Session.isAuthenticated() ? this.props.history.push('/entry') : this.openModal();
  }

  /**
   * Updates the password in the state.
   *
   * @param event
   */
  onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  /**
   * Updates the username in the state.
   *
   * @param event
   */
  onUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  /**
   * Opens the modal.
   */
  openModal() {
    this.setState({ showModal: true });
  }

  /**
   * Renders the Public view.
   *
   * @returns {*}
   */
  render() {
    return (
      <Segment
        className='home'
        textAlign='center'
        vertical
      >
        <Container
          className='content'
        >
          <Segment
            padded='very'
          >
            <Grid
              columns={1}
            >
              <Grid.Row
                verticalAlign='middle'
              >
                <Grid.Column
                  as='h1'
                  textAlign='left'
                >
                  <Icon
                    bordered
                    circular
                    inverted
                    name='balance'
                    size='small'
                  />
                  { this.props.t('Home.title') }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  as='p'
                  textAlign='justified'
                >
                  { this.props.t('Home.description') }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  textAlign='right'
                >
                  <LoginModal
                    disabled={!(this.state.username && this.state.password)}
                    loginFailed={this.state.loginFailed}
                    onClose={this.onCloseModal.bind(this)}
                    onLogin={this.onLogin.bind(this)}
                    onPasswordChange={this.onPasswordChange.bind(this)}
                    onUsernameChange={this.onUsernameChange.bind(this)}
                    open={this.state.showModal}
                    trigger={this.renderLoginButton()}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  textAlign='left'
                >
                  <Link to={`/rights`}>Intellectual Property Rights</Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Segment>
    );
  }

  renderLoginButton() {
    return (
      <Button
        className='login-button'
        onClick={this.onLoginButton.bind(this)}
        primary
        size='huge'
      >
        { this.props.t('Home.buttons.login') }
        <Icon name='right arrow' />
      </Button>
    );
  }
}

export default withTranslation()(withRouter(Home));
