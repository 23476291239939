// @flow

import _ from 'underscore';
import FuzzyDate from './FuzzyDate';

import type { Person } from '../types/Person';

/**
 * Class for handling transforming occupation records.
 */
class Occupations {
  PAYLOAD_KEYS = [
    'id',
    'position_id',
    'appointer_id',
    'citation_id',
    'narrative',
    '_destroy'
  ];

  /**
   * Returns the occupations for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param person
   *
   * @returns {{occupations: *}}
   */
  toPayload(person: Person) {
    return {
      occupations: _.map(person.occupations, (o) => ({
        ..._.pick(o, this.PAYLOAD_KEYS),
        ...FuzzyDate.toPayload(o, 'start_date_ah'),
        ...FuzzyDate.toPayload(o, 'start_date_ce'),
        ...FuzzyDate.toPayload(o, 'end_date_ah'),
        ...FuzzyDate.toPayload(o, 'end_date_ce'),
      }))
    };
  }
}

export default new Occupations();
