// @flow

import _ from 'underscore';
import type { Footnoteable } from '../types/concerns/Footnoteable';

/**
 * Class for handling transforming footnotes records.
 */
class Footnotes {
  PAYLOAD_KEYS = [
    'id',
    'label',
    'content',
    '_destroy'
  ];

  /**
   * Returns the footnotes for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param footnoteable
   *
   * @returns {{footnotes: *}}
   */
  toPayload(footnoteable: Footnoteable) {
    return {
      footnotes: _.map(footnoteable.footnotes, (f) => _.pick(f, this.PAYLOAD_KEYS))
    };
  }
}

export default new Footnotes();
