// @flow

import React, { useEffect, useState } from 'react';
import { type EditContainerProps, EmbeddedList, Selectize } from 'react-components';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Modal
} from 'semantic-ui-react';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import SourceModal from './SourceModal';
import Sources from '../services/Sources';
import Organizations from '../services/Organizations';

import type { Organization } from '../types/Organization';

type Props = EditContainerProps & {
  item: Organization,
  showSources: boolean
};

const OrganizationModal = (props: Props) => {
  const [modal, setModal] = useState(false);

  return (
    <Modal
      as={Form}
      centered={false}
      className='organization-modal'
      noValidate
      open
      size='small'
    >
      <Modal.Header
        content={props.item.id
          ? props.t('OrganizationModal.title.edit')
          : props.t('OrganizationModal.title.add')}
      />
      <Modal.Content>
        <Form.Input
          autoFocus
          error={props.isError('name')}
          label={props.t('OrganizationModal.labels.name')}
          onChange={props.onTextInputChange.bind(this, 'name')}
          required={props.isRequired('name')}
          value={props.item.name || ''}
        />
        <DataFieldDropdown
          {...props}
          column='organization_type'
          label={props.t('OrganizationModal.labels.organizationType')}
          table='organizations'
        />
        { props.showSources && (
          <>
            <Header
              content={props.t('OrganizationModal.labels.sources')}
            />
            <EmbeddedList
              actions={[{
                name: 'delete'
              }]}
              buttons={[{
                render: () => (
                  <Button
                    basic
                    content={props.t('Common.buttons.multiAdd')}
                    icon='plus'
                    key='source'
                    onClick={() => setModal(true)}
                  />
                )
              }]}
              columns={[{
                name: 'searchable_name',
                label: props.t('OrganizationModal.sources.columns.title'),
                resolve: (holding) => holding.source.searchable_name
              }, {
                name: 'type',
                label: props.t('OrganizationModal.sources.columns.type'),
                resolve: (holding) => holding.source.source_type
              }, {
                name: 'genre',
                label: props.t('OrganizationModal.sources.columns.genre'),
                resolve: (holding) => holding.source.genre
              }]}
              items={props.item.holdings}
              onDelete={props.onDeleteChildAssociation.bind(this, 'holdings')}
              onSave={props.onSaveChildAssociation.bind(this, 'holdings')}
              showRecordCount
            />
            { modal && (
              <Selectize
                collectionName='sources'
                onClose={() => setModal(false)}
                onLoad={(params) => Sources.fetchAll(params)}
                onSave={(sources) => {
                  const find = (item) => _.find(props.item.holdings,
                    (holding) => !holding._destroy && holding.source_id === item.id);

                  props.onMultiAddChildAssociations('holdings',
                    _.map(sources, (item) => find(item) || ({ uid: uuid(), source_id: item.id, source: item })));

                  setModal(false);
                }}
                modal={{
                  component: SourceModal,
                  onSave: (source) => {
                    Sources
                      .save(source)
                      .then(({ data }) => data.source)
                  }
                }}
                multiple
                renderItem={(item) => item.searchable_name}
                selectedItems={_.map(_.filter(props.item.holdings, (holding) => !holding._destroy), (holding) => holding.source)}
                title={props.t('OrganizationModal.labels.selectSources')}
              />
            )}
          </>
        )}
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

OrganizationModal.defaultProps = {
  showSources: true
};

export default withTranslation()(OrganizationModal);
