// @flow

import React from 'react';
import { useDragDrop } from 'react-components';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import AuthenticatedRoute from '../routes/AuthenticatedRoute';
import Entry from './Entry';
import Home from './Home';
import Rights from './Rights';

const App = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Route exact path='/' component={Home} />
    <Route exact path='/rights' component={Rights} />
    <AuthenticatedRoute path='/entry' component={Entry} />
  </Router>
);

export default useDragDrop(App);
