// @flow

import BaseService from './BaseService';
import Bibliography from '../transforms/Bibliography';

/**
 * Service class for all bibliography based API calls.
 */
class Bibliographies extends BaseService {
  /**
   * Returns the bibliography base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/bibliographies';
  }

  /**
   * Returns the bibliography transform object.
   *
   * @returns {Bibliography}
   */
  getTransform() {
    return Bibliography;
  }
}

export default new Bibliographies();
