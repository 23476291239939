// @flow

import {
  Button,
  Header,
  Icon,
  Segment
} from 'semantic-ui-react';
import { EditModal, ListTable } from 'react-components';
import React, { useState } from 'react';
import _ from 'underscore';
import uuid from 'react-uuid';
import { withTranslation } from 'react-i18next';
import ExternalRecordVisualizer from './ExternalRecordVisualizer';
import PeopleFiltersModal from './PeopleFiltersModal';
import PeopleService from '../services/People';
import PersonModal from './PersonModal';
import PlaceModal from './PlaceModal';
import PlacesService from '../services/Places';
import SourceModal from './SourceModal';
import SourcesService from '../services/Sources';
import StepperModal from './StepperModal';

const concatenateArray = (value: any) => (_.isArray(value) ? value.join(' | ') : value);

const createPersonObject = (externalSource: {
  author_death_date_isim: ?Array<number>,
  author_ssm: ?Array<string>,
}) => {
  const personObj = {
    short_name: concatenateArray(externalSource.author_ssm),
    full_name: concatenateArray(externalSource.author_ssm),
    death_date_ce: {},
  };

  const deathDate = !_.isEmpty(externalSource.author_death_date_isim) && externalSource.author_death_date_isim
    ? new Date(externalSource.author_death_date_isim[0], 0, 1)
    : null;
  if (deathDate) {
    personObj.death_date_ce = {
      start_date: deathDate,
      end_date: deathDate,
    };
  }
  return personObj;
};

const createPlaceObject = (externalSource) => (
  {
    name_english: _.isEmpty(externalSource.published_ssim) ? '' : _.first(externalSource.published_ssim),
  }
);

const toParticipant = (person: {id: number, searchable_name: string}) => ({
  participant_id: person.id,
  uid: uuid(),
  participant_type: 'Person',
  role: 'Author',
  participant: {
    id: person.id,
    searchable_name: person.searchable_name
  },
});

const toLocation = (place: {
  id: number,
  name_arabic: string,
  name_english: string,
  name_transliteration: string,
  lat: number,
  long: number,
}) => ({
  role: 'Publication location',
  uid: uuid(),
  place_id: place.id,
  place: {
    id: place.id,
    name_arabic: place.name_arabic,
    name_english: place.name_english,
    name_transliteration: place.name_transliteration,
    lat: place.lat,
    lng: place.long
  },
});

type Props = {
  initialSourceObject: {},
  onAddSource: ()=>void,
  onChangeSelectionClick: ({})=>void,
  onClose: ()=>void,
  onResetSearch: ()=>void,
  open: boolean,
  rawExternalSource: {author_ssm: ?any, published_ssim: ?any, author_death_date_isim: ?Array<number>},
  selectedPerson: {searchable_name: string, id: number},
  selectedPlace: {
    name_english: string,
    id: number,
    lat: number,
    long: number,
    name_arabic: string,
    name_transliteration: string,
  },
  selectedString: String,
  setSelectedPerson: ({})=>void,
  setSelectedPlace: ({})=>void,
  setSourceToImport: ({})=>void,
  sourceToImport: {participations: Array<{}>},
  t: (key: string) => string,
};

const ImportSourceStepper = (props: Props) => {
  const [activeStep, setActiveStep] = useState('person');

  const SelectedRecordBlock = ({ selectedString, onChangeSelectionClick }) => (
    <Segment style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name='check' color='green' size='large' />
        <Header style={{ margin: 0 }} color='green'>
          {`${props.t('ImportSourceStepper.steps.common.selected')}:`}
        </Header>
        <p style={{ fontWeight: 'bold', fontSize: 16, margin: '0 0 0 10px' }}>
          {selectedString}
        </p>
      </div>
      <Button
        compact
        size='tiny'
        icon='undo'
        content={props.t('ImportSourceStepper.steps.common.changeSelection')}
        onClick={onChangeSelectionClick}
      />
    </Segment>
  );

  const resetStepper = () => {
    setActiveStep('person');
    props.onResetSearch();
    props.setSourceToImport({ ...props.initialSourceObject, participations: props.sourceToImport.participations });
    props.setSelectedPlace({});
  };

  const StackLifeRecord = () => (
    <ExternalRecordVisualizer
      record={props.rawExternalSource}
      title={props.t('ImportSourceStepper.externalRecords.stacklife.header')}
      resourceStringLabelsPath='ImportSourceStepper.externalRecords.stacklife.labels'
      style={{ maxWidth: '30%' }}
    />
  );

  const renderPersonStep = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <StackLifeRecord />
      <div style={{ width: '100%' }}>
        {_.isEmpty(props.selectedPerson)
          ? (
            <>
              <Header size='large' content={props.t('ImportSourceStepper.steps.person.tableTitle')} />
              <ListTable
                key='people'
                defaultSearch={_.isEmpty(props.rawExternalSource.author_ssm)
                  ? ''
                  : _.first(props.rawExternalSource.author_ssm)}
                collectionName='people'
                actions={[{
                  name: 'edit'
                }]}
                columns={[{
                  name: 'searchable_name',
                  label: props.t('People.columns.name'),
                  sortable: true
                }]}
                filters={{
                  component: PeopleFiltersModal,
                  props: {
                    modern: '',
                    birth_date_ah: null,
                    birth_date_ce: null,
                    death_date_ah: null,
                    death_date_ce: null,
                    genre: '',
                    place_id: ''
                  }
                }}
                modal={{
                  component: PersonModal,
                  props: {
                    defaults: createPersonObject(props.rawExternalSource),
                    onInitialize: (id) => PeopleService.fetchOne(id).then(({ data }) => data.person),
                    externalDataContent: <StackLifeRecord />,
                  }
                }}
                onLoad={(params) => PeopleService.fetchAll(params)}
                onRowSelect={({ checked }, person) => (checked
                  ? props.setSelectedPerson(person)
                  : props.setSelectedPerson({}))}
                onSave={(person) => PeopleService.save(person).then(({ data }) => {
                  if (person.id) return data.person;
                  props.setSelectedPerson(data.person);
                  return data;
                  // this only sets person as selected person if adding new not if editing
                })}
                selectable
                selectedRows={[props.selectedPerson]}
                showRecordCount
              />
            </>
          )
          : (
            <SelectedRecordBlock
              onChangeSelectionClick={() => props.setSelectedPerson({})}
              selectedString={props.selectedPerson.searchable_name}
            />
          )}
      </div>
    </div>
  );

  const renderPlaceStep = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <StackLifeRecord />
      <div style={{ width: '100%' }}>
        <Header size='large' content={props.t('ImportSourceStepper.steps.place.tableTitle')} />
        {_.isEmpty(props.selectedPlace)
          ? (
            <ListTable
              key='places'
              className='places'
              collectionName='places'
              columns={[{
                name: 'name->>english',
                label: props.t('Places.columns.name.english'),
                resolve: (place) => place.name_english,
                sortable: true
              }, {
                name: 'name->>arabic',
                label: props.t('Places.columns.name.arabic'),
                resolve: (place) => place.name_arabic,
                sortable: true,
                hidden: true
              }, {
                name: 'name->>transliteration',
                label: props.t('Places.columns.name.transliteration'),
                resolve: (place) => place.name_transliteration,
                sortable: true,
                hidden: true
              }, {
                name: 'lat',
                label: props.t('Places.columns.lat'),
                sortable: true
              }, {
                name: 'lng',
                label: props.t('Places.columns.lng'),
                sortable: true
              }]}
              defaultSearch={_.isEmpty(props.rawExternalSource.published_ssim)
                ? ''
                : _.first(props.rawExternalSource.published_ssim)}
              modal={!_.isEmpty(props.selectedPlace) ? null : {
                component: PlaceModal,
                props: {
                  item: createPlaceObject(props.rawExternalSource),
                  externalDataContent: <StackLifeRecord />,
                }
              }}
              onLoad={(params) => PlacesService.fetchAll(params)}
              onSave={(place) => PlacesService.save(place).then(({ data }) => {
                props.setSelectedPlace(data.place);
              })}
              onRowSelect={({ checked }, place) => {
                if (checked) props.setSelectedPlace(place);
                else props.setSelectedPlace({});
              }}
              selectable
              selectedRows={[props.selectedPlace]}
              showRecordCount
            />
          )
          : (
            <SelectedRecordBlock
              onChangeSelectionClick={() => props.setSelectedPlace({})}
              selectedString={props.selectedPlace.name_english}
            />
          )}
      </div>
    </div>
  );

  const renderSourceStep = () => (
    <EditModal
      onClose={() => setActiveStep('place')}
      onSave={(source) => {
        props.onClose();
        resetStepper();
        return SourcesService.save(source);
      }}
      item={props.sourceToImport}
      component={(componentProps) => (
        <SourceModal
          {...componentProps}
          externalDataContent={<StackLifeRecord />}
        />
      )}
    />
  );

  return (
    <StepperModal
      style={{ maxHeight: '95%', overflow: 'auto' }}
      open={props.open}
      header={props.t('ImportSourceStepper.header')}
      activeStep={activeStep}
      steps={[{
        name: 'person',
        title: props.t('ImportSourceStepper.steps.person.title'),
        renderContent: renderPersonStep,
        description: props.selectedPerson.searchable_name
          ? props.selectedPerson.searchable_name
          : props.t('ImportSourceStepper.steps.person.selectPerson'),
        backButtonProps: {
          onClick: () => {
            props.onClose();
            resetStepper();
          },
        },
        nextButtonProps: {
          disabled: _.isEmpty(props.selectedPerson),
          onClick: () => {
            setActiveStep('place');
            props.setSourceToImport({
              ...props.sourceToImport,
              participations: [toParticipant(props.selectedPerson)]
            });
          },
        }
      }, {
        name: 'place',
        title: props.t('ImportSourceStepper.steps.place.title'),
        description: props.selectedPlace.name_english
          ? props.selectedPlace.name_english
          : props.t('ImportSourceStepper.steps.place.selectPlace'),
        renderContent: renderPlaceStep,
        backButtonProps: {
          onClick: () => {
            setActiveStep('person');
          },
        },
        nextButtonProps: {
          disabled: _.isEmpty(props.selectedPlace),
          onClick: () => {
            setActiveStep('source');
            props.setSourceToImport({
              ...props.sourceToImport,
              locations: [toLocation(props.selectedPlace)]
            });
          },
        }
      }, {
        name: 'source',
        title: props.t('ImportSourceStepper.steps.source.title'),
        renderContent: renderSourceStep
      }]}
    />
  );
};

export default withTranslation()(ImportSourceStepper);
