// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import _ from 'underscore';
import GroupsService from '../services/Groups';
import GroupModal from '../components/GroupModal';
import PersonGroupModal from '../components/PersonGroupModal';

type Props = {
  t: (key: string) => string
};

const PersonGroups = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='groups'
    collectionName='groups'
    columns={[{
      name: 'group_type',
      label: props.t('PersonGroups.columns.type'),
      sortable: true
    }, {
      name: 'name->>english',
      label: props.t('PersonGroups.columns.name'),
      resolve: (group) => group.name_english,
      sortable: true
    }]}
    modal={{
      component: GroupModal,
      props: {
        defaults: {
          member_class: 'Person'
        },
        modal: PersonGroupModal,
        onInitialize: (id) => GroupsService.fetchOne(id).then(({ data }) => data.group)
      }
    }}
    onDelete={(group) => GroupsService.delete(group)}
    onLoad={(params) => GroupsService.fetchAll(_.extend(params, { member_class: 'Person' }))}
    onSave={(group) => GroupsService.save(group)}
    showRecordCount
  />
);

export default withTranslation()(PersonGroups);
