// @flow

import React, { useState } from 'react';
import { AccordionList, EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {
  Button,
  Form,
  Header, Icon, Modal
} from 'semantic-ui-react';
import _, { isEmpty } from 'underscore';
import Session from '../services/Session';
import SourcesService from '../services/Sources';
import type { Bibliography } from '../types/Bibliography';
import SourceItem from './AccordionListItem';
import SelectableSourceAccordion from './SelectableSourceAccordion';

type Props = EditModalProps & {
  item: Bibliography,
  t: (key: string) => string
};

const BibliographyModal = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initSelectedSources = props.item.bibliographies_sources
    ? props.item.bibliographies_sources.map((bs) => ({ id: bs.source_id })) : [];
  const [selectedSources, setSelectedSources] = useState(initSelectedSources);
  const selectedSourceIds = selectedSources.map((bs) => (bs.source_id ? bs.source_id : bs.id));

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSaveBibSources = () => {
    const find = (item) => _.find(props.item.bibliographies_sources,
      (bs) => !bs._destroy && bs.source_id === item.id);

    props.onMultiAddChildAssociations(
      'bibliographies_sources',
      _.map(selectedSources, (item) => find(item)
            || ({
              uid: uuid(),
              source_id: item.id,
            }))
    );
    handleModalClose();
  };

  const updatedDate = new Date(props.item.updated_at);
  return (
    <div id='bibliography-modal-container'>
      <Modal
        as={Form}
        className='bibliography-modal wide-modal'
        closeIcon
        onClose={props.onClose}
        noValidate
        open
        size='small'
      >
        <Modal.Header
          content={props.item.id
            ? props.t('BibliographyModal.title.edit')
            : props.t('BibliographyModal.title.add')}
        />
        <Modal.Content>
          <Form.Input
            autoFocus
            error={props.isError('name_english')}
            label={props.t('BibliographyModal.labels.name')}
            onChange={props.onTextInputChange.bind(this, 'name_english')}
            required={props.isRequired('v')}
            value={props.item.name_english || ''}
          />
          <Form.Input
            autoFocus
            error={props.isError('description')}
            label={props.t('BibliographyModal.labels.description')}
            onChange={props.onTextInputChange.bind(this, 'description')}
            required={props.isRequired('v')}
            value={props.item.description || ''}
          />
          <Form.Checkbox
            checked={props.item.public}
            disabled={props.item.owner !== Session.getUsername() && !!props.item.owner}
            label={props.t('BibliographyModal.labels.public')}
            onChange={props.onCheckboxInputChange.bind(this, 'public')}
          />
          {props.item.updated_at
          && (
          <p>
            {`
              ${props.t('BibliographyModal.labels.lastUpdatedAt')} 
              ${updatedDate.toLocaleDateString()} 
              - ${updatedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            `}
          </p>
          )}
          <Header
            content={props.t('BibliographyModal.tableTitle')}
          />
          <AccordionList
            buttons={[{
              render: () => (
                <Button
                  basic
                  content={props.t('Common.buttons.addRemove')}
                  icon='pencil'
                  key='addRemove'
                  onClick={() => setIsModalOpen(true)}
                  style={{ marginLeft: 10 }}
                />
              )
            }]}
            canAddItem={() => false}
            canEditItem={() => false}
            canDeleteItem={() => false}
            canCopyItem={() => false}
            className='selectable-sources-accordion'
            collectionName='sources'
            getChildItems={(items, item) => item.child_source_relations && item.child_source_relations.map((rel) => ({
              relation_type: rel.relation_type,
              activeIndex: `child-${rel.id}`,
              ...rel.child_source
            }))}
            hideAddRootButton
            getRootItems={(items) => items}
            key={isModalOpen ? '0' : '1'}
            // key prop provided to force table to re-render
            lazyLoad={false}
            onRowSelect={() => {}}
            onSearch={(search, page) => SourcesService.fetchAll({
              search,
              page,
              source_ids: !isEmpty(selectedSourceIds) ? selectedSourceIds : [null]
            })}
            onSave={(source) => SourcesService.save(source)}
            pagination
            renderItem={(item) => (
              <SourceItem columns={[
                { value: item.searchable_name, label: item.relation_type, width: 10 },
                { value: item.source_type, label: '', width: 4 },
                { value: _.contains(selectedSourceIds, item.id) ? <Icon name='check' /> : '', width: 2, label: '' },
              ]}
              />
            )}
            showRecordCount
            showToggle={(item) => (item.child_source_relations ? item.child_source_relations.length > 0 : 0)}
          />
        </Modal.Content>
        { props.children }
      </Modal>
      <Modal
        open={isModalOpen}
        size='large'
      >
        <Modal.Header content={props.t('BibliographyModal.selectableSourceModalTitle')} />
        <Modal.Content>
          <SelectableSourceAccordion
            handleRowSelect={({ checked }, source) => {
              if (checked) {
                setSelectedSources([...selectedSources, source]);
              } else {
                setSelectedSources(selectedSources.filter((r) => r.id !== source.id));
              }
            }}
            selectedRows={selectedSources}
            onSelectAll={(sourcesToBeSelected, sourcesOnPage) => {
              if (sourcesToBeSelected.length) {
                setSelectedSources([...selectedSources, ...sourcesToBeSelected]);
              } else {
                const sourceIdsToUnSelect = sourcesOnPage.map((s) => s.id);
                setSelectedSources(selectedSources.filter((s) => !sourceIdsToUnSelect.includes(s.id)));
              }
            }}
          />
        </Modal.Content>
        <Modal.Actions
          actions={[
            <Button primary onClick={handleSaveBibSources}>{props.t('Common.buttons.save')}</Button>,
            <Button onClick={() => {
              setSelectedSources(initSelectedSources);
              handleModalClose();
            }}
            >
              {props.t('Common.buttons.cancel')}
            </Button>,
          ]}
        />
      </Modal>

    </div>
  );
};

export default withTranslation()(BibliographyModal);
