// @flow

import React from 'react';
import _ from 'underscore';
import TranslateableField from '../components/TranslateableField';
import Categorizations from './Categorizations';
import Citations from './Citations';
import Footnotes from './Footnotes';
import Locations from './Locations';
import Participations from './Participations';

import type { Canon as CanonType } from '../types/Canon';

/**
 * Class for handling transforming canon records.
 */
class Canon {
  PAYLOAD_KEYS = [
    'legal_formula_arabic',
    'legal_formula_english',
    'legal_formula_transliteration',
    'title_arabic',
    'title_english',
    'title_transliteration',
    'title_origin',
    'meaning',
    'function',
    'origin',
    'origin_detail',
    'application'
  ];

  /**
   * Returns the passed canon as a dropdown option.
   *
   * @param canon
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(canon: CanonType) {
    return {
      key: canon.id,
      value: canon.id,
      text: (
        <TranslateableField
          config={{
            content: 'arabic',
            popupContent: 'transliteration',
            popupHeader: 'english'
          }}
          property='legal_formula'
          translateable={canon}
        />
      )
    };
  }

  /**
   * Returns the canon object to be sent to the server on POST/PUT requests.
   *
   * @param canon
   *
   * @returns {*}
   */
  toPayload(canon: CanonType) {
    return {
      canon: {
        ..._.pick(canon, this.PAYLOAD_KEYS),
        ...Categorizations.toPayload(canon),
        ...Citations.toPayload(canon),
        ...Footnotes.toPayload(canon),
        ...Locations.toPayload(canon),
        ...Participations.toPayload(canon)
      }
    };
  }
}

export default new Canon();
