// @flow

import _ from 'underscore';
import Memberships from './Memberships';

import type { Group as GroupType } from '../types/Group';

/**
 * Class for handling transforming group records.
 */
class Group {
  PAYLOAD_KEYS = [
    'group_type',
    'member_class',
    'name_arabic',
    'name_english',
    'name_transliteration'
  ];

  /**
   * Returns the passed group as a dropdown option.
   *
   * @param group
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(group: GroupType) {
    return {
      key: group.id,
      value: group.id,
      text: group.name_english
    };
  }

  /**
   * Returns the group object to be sent to the server on POST/PUT requests.
   *
   * @param group
   *
   * @returns {*}
   */
  toPayload(group: GroupType) {
    return {
      group: {
        ..._.pick(group, this.PAYLOAD_KEYS),
        ...Memberships.toPayload(group)
      }
    };
  }
}

export default new Group();
