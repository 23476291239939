// @flow

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import DataFieldOptionsList from '../components/DataFieldOptionsList';

const Genres = (props: WithTranslation) => (
  <DataFieldOptionsList
    actions={[{
      name: 'discover',
      icon: 'search',
      popup: {
        content: props.t('Genres.actions.discover.content'),
        title: props.t('Genres.actions.discover.title')
      },
      onClick: (item) => props.history.push(`/entry/genres/${item.id}`)
    }]}
    className='genres'
    column='genre'
    modal={{
      required: ['value'],
      title: {
        add: props.t('Genres.modal.title.add'),
        edit: props.t('Genres.modal.title.edit')
      }
    }}
    table='sources'
  />
);

export default withTranslation()(withRouter(Genres));
