// @flow

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import './DescriptionLabel.css';

type Props = {
  description: string,
  htmlFor: string,
  label: string
};

const DescriptionLabel = (props: Props) => (
  <>
    <label
      className='description-label'
      htmlFor={props.htmlFor}
    >
      { props.label }
    </label>
    { props.description && (
      <Popup
        content={props.description}
        trigger={<Icon name='question circle outline' />}
        wide='very'
      />
    )}
  </>
);

export default DescriptionLabel;
