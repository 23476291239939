// @flow

import BaseService from './BaseService';
import Position from '../transforms/Position';

/**
 * Service class for all position based API calls.
 */
class Positions extends BaseService {
  /**
   * Returns the positions base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/positions';
  }

  /**
   * Returns the positions transform object.
   *
   * @returns {Position}
   */
  getTransform() {
    return Position;
  }
}

export default new Positions();
