// @flow

import React from 'react';
import _ from 'underscore';
import type { Position as PositionType } from '../types/Position';
import TranslateableField from '../components/TranslateableField';

/**
 * Class for handling transforming position records.
 */
class Position {
  PAYLOAD_KEYS = [
    'name_arabic',
    'name_english',
    'name_transliteration',
    'position_type'
  ];

  /**
   * Returns the passed position as a dropdown option.
   *
   * @param position
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(position: PositionType) {
    return {
      key: position.id,
      value: position.id,
      text: <TranslateableField property='name' translateable={position} />
    };
  }

  /**
   * Returns the position object to be sent to the server on POST/PUT requests.
   *
   * @param position
   *
   * @returns {*}
   */
  toPayload(position: PositionType) {
    return { position: _.pick(position, this.PAYLOAD_KEYS) };
  }
}

export default new Position();
