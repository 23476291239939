// @flow

import BaseService from './BaseService';
import Case from '../transforms/Case';

/**
 * Service class for all case based API calls.
 */
class Cases extends BaseService {
  /**
   * Returns the locations base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/cases';
  }

  /**
   * Returns the locations transform object.
   *
   * @returns {Case}
   */
  getTransform() {
    return Case;
  }
}

export default new Cases();
