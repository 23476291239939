// @flow

import React, { useEffect } from 'react';
import {
  AssociatedDropdown,
  ModalDropdown,
  type EditModalProps, Selectize
} from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import CitationsService from '../services/Citations';
import Citations from '../transforms/Citations';
import Rationales from '../services/Rationales';
import RationaleModal from './RationaleModal';
import TranslateableField from './TranslateableField';

import type { Categorization } from '../types/Categorization';

type Props = EditModalProps & {
  citeable_id: number,
  citeable_type: string,
  item: Categorization
};

const RationaleCategorizationModal = (props: Props) => {
  // Default the category_type to "Rationale"
  useEffect(() => {
    props.onTextInputChange('category_type', null, { value: 'Rationale' });
  }, []);

  return (
    <Modal
      as={Form}
      className='rationale-categorization-modal'
      closeIcon
      onClose={props.onClose}
      noValidate
      open
      size='small'
    >
      <Modal.Header
        content={props.item.id
          ? props.t('RationaleCategorizationModal.title.edit')
          : props.t('RationaleCategorizationModal.title.add')}
      />
      <Modal.Content>
        <DataFieldDropdown
          {...props}
          column='assigner'
          label={props.t('RationaleCategorizationModal.labels.assigner')}
          table='categorizations'
        />
        <Form.Input
          error={props.isError('category_id')}
          label={props.t('RationaleCategorizationModal.labels.rationale')}
          required={props.isRequired('category_id')}
        >
          <ModalDropdown
            onClear={props.onAssociationInputChange.bind(this, 'category_id', 'category')}
            renderModal={({ onClose, open }) => open && (
              <Selectize
                collectionName='rationales'
                onClose={onClose}
                onLoad={(params) => Rationales.fetchAll(_.extend(params, { sort_by: 'name->>english' }))}
                onSave={(items) => {
                  props.onAssociationInputChange('category_id', 'category', _.first(items));
                  onClose();
                }}
                modal={{
                  component: RationaleModal,
                  onSave: (rationale) => Rationales.save(rationale)
                }}
                multiple={false}
                renderItem={(item) => <TranslateableField property='name' translateable={item} />}
                selectedItems={props.item.category ? [props.item.category] : []}
                title={props.t('RationaleCategorizationModal.title.select')}
              />
            )}
            searchQuery={props.item.category && props.item.category.name_english}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('citation_id')}
          label={props.t('RationaleCategorizationModal.labels.citation')}
          required={props.isRequired('citation_id')}
        >
          <AssociatedDropdown
            collectionName='citations'
            onSearch={(search) => CitationsService.fetchAll({
              search,
              sort_by: 'sources.searchable_name',
              citeable_id: props.citeable_id,
              citeable_type: props.citeable_type
            })}
            onSelection={props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
            renderOption={Citations.toDropdown.bind(this)}
            searchQuery={props.item.citation
              && props.item.citation.source
              && props.item.citation.source.searchable_name}
            value={props.item.citation_id}
          />
        </Form.Input>
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(RationaleCategorizationModal);
