// @flow

import axios from 'axios';
import _ from 'underscore';

// axios.defaults.baseURL = process.env.PUBLIC_URL;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Sets the authentication token as a request header
axios.interceptors.request.use((config) => {
  const user = sessionStorage.getItem('user');

  if (user) {
    _.extend(config.headers, JSON.parse(user));
  }

  return config;
}, (error) => Promise.reject(error));
