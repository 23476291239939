// @flow

import React, { type Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Header } from 'semantic-ui-react';
import {
  BooleanIcon,
  EmbeddedList,
  FuzzyDate,
  TabbedModal,
  type EditModalProps
} from 'react-components';
import _ from 'underscore';
import CitationModal from './CitationModal';
import DataFieldDropdown from './DataFieldDropdown';
import FootnoteModal from './FootnoteModal';
import FuzzyDateTransform from '../transforms/FuzzyDate';
import IssueCategorizationModal from './IssueCategorizationModal';
import LocationModal from './LocationModal';
import ParticipationModal from './ParticipationModal';
import RationaleCategorizationModal from './RationaleCategorizationModal';
import ReferenceModal from './ReferenceModal';
import TimespanCategorizationModal from './TimespanCategorizationModal';
import TimespanInfo from './TimespanInfo';

import type { Case } from '../types/Case';

type Props = EditModalProps & {
  children: Component<{}>,
  item: Case,
  t: (key: string) => string
};

const CaseModal = (props: Props) => (
  <TabbedModal
    as={Form}
    centered={false}
    className='case-modal wide-modal'
    closeIcon
    onClose={props.onClose}
    header={props.item.id
      ? props.t('CaseModal.title.edit')
      : props.t('CaseModal.title.add')}
    inlineTabs={false}
    open
    size='small'
  >
    <TabbedModal.Tab
      name={props.t('Common.tabs.general')}
    >
      <Form.Input
        autoFocus
        error={props.isError('short_name')}
        label={props.t('CaseModal.labels.shortName')}
        onChange={props.onTextInputChange.bind(this, 'short_name')}
        required={props.isRequired('short_name')}
        value={props.item.short_name || ''}
      />
      <DataFieldDropdown
        {...props}
        column='case_type'
        label={props.t('CaseModal.labels.caseType')}
        multiple
        table='cases'
      />
      <Form.TextArea
        error={props.isError('description')}
        label={props.t('CaseModal.labels.description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        required={props.isRequired('description')}
        value={props.item.description || ''}
      />
      <Form.TextArea
        error={props.isError('issue_presented')}
        label={props.t('CaseModal.labels.issuePresented')}
        onChange={props.onTextInputChange.bind(this, 'issue_presented')}
        required={props.isRequired('issue_presented')}
        value={props.item.issue_presented || ''}
      />
      <Form.TextArea
        error={props.isError('holding')}
        label={props.t('CaseModal.labels.holding')}
        onChange={props.onTextInputChange.bind(this, 'holding')}
        required={props.isRequired('holding')}
        value={props.item.holding || ''}
      />
      <Form.Input
        error={props.isError('start_date')}
        label={props.t('CaseModal.labels.startDate')}
        required={props.isRequired('start_date')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.start_date)}
          onChange={(data) => props.onSetState({
            start_date: FuzzyDateTransform.toRelated(props.item, 'start_date', data)
          })}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('end_date')}
        label={props.t('CaseModal.labels.endDate')}
        required={props.isRequired('end_date')}
      >
        <FuzzyDate
          date={FuzzyDateTransform.toFuzzyDate(props.item.end_date)}
          onChange={(data) => props.onSetState({
            end_date: FuzzyDateTransform.toRelated(props.item, 'end_date', data)
          })}
        />
      </Form.Input>
      <Form.TextArea
        error={props.isError('date_description')}
        label={props.t('CaseModal.labels.dateDescription')}
        onChange={props.onTextInputChange.bind(this, 'date_description')}
        required={props.isRequired('date_description')}
        value={props.item.date_description || ''}
      />
      <Form.Checkbox
        checked={props.item.exact_date}
        label={props.t('CaseModal.labels.exactDate')}
        onChange={props.onCheckboxInputChange.bind(this, 'exact_date')}
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.issues')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.issues.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.issues.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.issues.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Issue' })}
        modal={{
          component: IssueCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Case'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.rationales')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.rationales.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.rationales.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.rationales.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }, {
          name: 'assigner',
          label: props.t('Common.rationales.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Rationale' })}
        modal={{
          component: RationaleCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Case'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.citations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'source',
          label: props.t('Common.citations.columns.source'),
          resolve: (c) => c.source.searchable_name
        }, {
          name: 'page_reference',
          label: props.t('Common.citations.columns.pageReference')
        }, {
          name: 'volume_reference',
          label: props.t('Common.citations.columns.volumeReference')
        }, {
          name: 'citation_type',
          label: props.t('Common.citations.columns.citationType')
        }]}
        items={props.item.citations}
        modal={{
          component: CitationModal
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'citations')}
        onSave={props.onSaveChildAssociation.bind(this, 'citations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.participations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.participations.columns.name'),
          resolve: (p) => (p.participant_type === 'Person' ? p.participant.searchable_name : p.participant.name_english)
        }, {
          name: 'participant_type',
          label: props.t('Common.participations.columns.type')
        }, {
          name: 'role',
          label: props.t('Common.participations.columns.role')
        }]}
        items={props.item.participations}
        modal={{
          component: ParticipationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Case'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'participations')}
        onSave={props.onSaveChildAssociation.bind(this, 'participations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.locations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'place_english',
          label: props.t('Common.locations.columns.place.english'),
          resolve: (l) => l.place.name_english
        }, {
          className: 'arabic',
          name: 'place_arabic',
          label: props.t('Common.locations.columns.place.arabic'),
          resolve: (l) => l.place.name_arabic,
          hidden: true
        }, {
          name: 'place_transliteration',
          label: props.t('Common.locations.columns.place.transliteration'),
          resolve: (l) => l.place.name_transliteration,
          hidden: true
        }, {
          name: 'role',
          label: props.t('Common.locations.columns.role')
        }, {
          name: 'precision',
          label: props.t('Common.locations.columns.precision')
        }, {
          name: 'certainty',
          label: props.t('Common.locations.columns.certainty')
        }]}
        items={props.item.locations}
        modal={{
          component: LocationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Case'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'locations')}
        onSave={props.onSaveChildAssociation.bind(this, 'locations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.timespans')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.timespans.columns.name'),
          render: (c) => <TimespanInfo timespan={c.category} />
        }, {
          name: 'assigner',
          label: props.t('Common.timespans.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Timespan' })}
        modal={{
          component: TimespanCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Case'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.references')}
    >
      <Header
        content={props.t('Common.references.types.canons')}
      />
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'title_english',
          label: props.t('Common.references.columns.title.english'),
          resolve: (r) => r.referent.title_english
        }, {
          className: 'arabic',
          name: 'title_arabic',
          label: props.t('Common.references.columns.title.arabic'),
          resolve: (r) => r.referent.title_arabic,
          hidden: true
        }, {
          name: 'title_transliteration',
          label: props.t('Common.references.columns.title.transliteration'),
          resolve: (r) => r.referent.title_transliteration,
          hidden: true
        }, {
          className: 'arabic',
          name: 'legal_formula_arabic',
          label: props.t('Common.references.columns.legalFormula.arabic'),
          resolve: (r) => r.referent.legal_formula_arabic
        }, {
          name: 'legal_formula_english',
          label: props.t('Common.references.columns.legalFormula.english'),
          resolve: (r) => r.referent.legal_formula_english,
          hidden: true
        }, {
          name: 'legal_formula_transliteration',
          label: props.t('Common.references.columns.legalFormula.transliteration'),
          resolve: (r) => r.referent.legal_formula_transliteration,
          hidden: true
        }, {
          name: 'explicit',
          label: props.t('Common.references.columns.explicit'),
          render: (r) => <BooleanIcon value={r.explicit} />
        }]}
        items={props.item.references}
        modal={{
          component: ReferenceModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Case',
            defaults: {
              referent_type: 'Canon'
            }
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'references')}
        onSave={props.onSaveChildAssociation.bind(this, 'references')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.footnotes')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'label',
          label: props.t('Common.footnotes.columns.label')
        }, {
          name: 'content',
          label: props.t('Common.footnotes.columns.content')
        }]}
        items={props.item.footnotes}
        modal={{
          component: FootnoteModal
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'footnotes')}
        onSave={props.onSaveChildAssociation.bind(this, 'footnotes')}
        showRecordCount
      />
    </TabbedModal.Tab>
    { props.children }
  </TabbedModal>
);

export default withTranslation()(CaseModal);
