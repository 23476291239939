// @flow

import BaseService from './BaseService';
import Place from '../transforms/Place';

/**
 * Service class for all place based API calls.
 */
class Places extends BaseService {
  /**
   * Returns the places base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/places';
  }

  /**
   * Returns the places transform object.
   *
   * @returns {Canon}
   */
  getTransform() {
    return Place;
  }
}

export default new Places();
