// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import RationaleModal from '../components/RationaleModal';
import TranslateableField from '../components/TranslateableField';
import RationalesService from '../services/Rationales';

type Props = {
  t: (key: string) => string
};

const Rationales = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='rationales'
    collectionName='rationales'
    columns={[{
      name: 'name->>english',
      label: props.t('Rationales.columns.name'),
      render: (rationale) => <TranslateableField property='name' translateable={rationale} />,
      sortable: true
    }]}
    modal={{
      component: RationaleModal
    }}
    onDelete={(rationale) => RationalesService.delete(rationale)}
    onLoad={(params) => RationalesService.fetchAll(params)}
    onSave={(rationale) => RationalesService.save(rationale)}
    showRecordCount
  />
);

export default withTranslation()(Rationales);
