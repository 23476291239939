// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  AccordionSelector,
  ArrowButtons,
  EmbeddedList,
  FuzzyDate,
  KeyboardField,
  ModalDropdown,
  Selectize,
  TabbedModal,
  type EditModalProps
} from 'react-components';
import {
  Button,
  Dropdown,
  Form
} from 'semantic-ui-react';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import uuid from 'react-uuid';
import _ from 'underscore';
import LocationModal from './LocationModal';
import Session from '../services/Session';
import Timespans from '../services/Timespans';
import FuzzyDateTransform from '../transforms/FuzzyDate';

import type { Timespan } from '../types/Timespan';
import layouts from '../utilities/customKeyboardLayouts';

type Props = EditModalProps & {
  item: Timespan
};

type State = {
  modal: boolean,
  rootLevel: boolean
};

const MAX_PARENT_NODE_LEVEL = 2;

const TYPE_LEADERSHIP = 'Leadership';
const TYPE_PERIOD = 'Period';

class TimespanModal extends Component<Props, State> {
  /**
   * Constructs a new TimespanModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      rootLevel: true
    };
  }

  /**
   * Initializes the state.
   */
  componentDidMount() {
    if (this.props.item.parent_timespan_id) {
      this.setState({ rootLevel: false });
    }
  }

  /**
   * Closes the selectize modal.
   */
  onCloseMultiAdd() {
    this.setState({ modal: false });
  }

  /**
   * Opens the selectize modal.
   */
  onMultiAdd() {
    this.setState({ modal: true });
  }

  /**
   * Saves the passed items and closes the selectize modal.
   *
   * @param items
   */
  onSaveMultiAdd(items) {
    const findTimespan = (item) => _.findWhere(this.props.item.timespan_periods, { period_id: item.id });
    const createTimespan = (item) => ({ uid: uuid(), period_id: item.id, period: item });

    // Set the selected periods on the current item
    const periods = _.map(items, (item) => findTimespan(item) || createTimespan(item));
    this.props.onMultiAddChildAssociations('timespan_periods', periods);

    this.onCloseMultiAdd();
  }

  /**
   * Renders the TimespanModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <TabbedModal
        as={Form}
        centered={false}
        closeIcon
        onClose={this.props.onClose}
        className='timespan-modal wide-modal'
        header={this.props.item.id
          ? this.props.t('TimespanModal.title.edit')
          : this.props.t('TimespanModal.title.add')}
        noValidate
        open
        size='small'
      >
        <TabbedModal.Tab
          name={this.props.t('Common.tabs.details')}
        >
          { this.state.rootLevel && Session.isAdmin() && (
            <Form.Input
              error={this.props.isError('timespan_type')}
              label={this.props.t('TimespanModal.labels.timespanType')}
              required={this.props.isRequired('timespan_type')}
            >
              <Dropdown
                fluid
                selection
                options={[{
                  key: TYPE_LEADERSHIP,
                  value: TYPE_LEADERSHIP,
                  text: TYPE_LEADERSHIP
                }, {
                  key: TYPE_PERIOD,
                  value: TYPE_PERIOD,
                  text: TYPE_PERIOD
                }]}
                onChange={this.props.onTextInputChange.bind(this, 'timespan_type')}
                selectOnBlur={false}
                value={this.props.item.timespan_type}
              />
            </Form.Input>
          )}
          { !this.state.rootLevel && (
            <Form.Input
              error={this.props.isError('parent_timespan_id')}
              label={this.props.t('TimespanModal.labels.parentTimespan')}
              required={this.props.isRequired('parent_timespan_id')}
            >
              <ModalDropdown
                onClear={this.props.onAssociationInputChange.bind(this, 'parent_timespan_id', 'parent_timespan_id')}
                renderModal={({ onClose, open }) => (
                  <AccordionSelector
                    collectionName='timespans'
                    getChildItems={(items, item) => _.where(items, { parent_timespan_id: item.id })}
                    getRootItems={(items) => _.where(items, { parent_timespan_id: null })}
                    isSelectable={(item) => item.node_level <= MAX_PARENT_NODE_LEVEL}
                    onClose={onClose}
                    onSave={(items) => {
                      this.props.onAssociationInputChange('parent_timespan_id', 'parent_timespan', _.first(items));
                      onClose();
                    }}
                    onSearch={(parentId, search) => Timespans.fetchAll({ parent_timespan_id: parentId, search })}
                    open={open}
                    renderItem={(item) => item.searchable_name}
                    selectedItems={this.props.item.parent_timespan ? [this.props.item.parent_timespan] : []}
                    showToggle={(item) => item.child_count}
                    title={this.props.t('TimespanModal.title.selectTimespan')}
                  />
                )}
                searchQuery={this.props.item.parent_timespan && this.props.item.parent_timespan.searchable_name}
              />
            </Form.Input>
          )}
          <Form.Input
            autoFocus
            error={this.props.isError('name_english')}
            label={this.props.t('TimespanModal.labels.name.english')}
            onChange={this.props.onTextInputChange.bind(this, 'name_english')}
            required={this.props.isRequired('name_english')}
            value={this.props.item.name_english || ''}
          />
          <KeyboardField
            error={this.props.isError('name_arabic')}
            id='name_arabic'
            label={this.props.t('TimespanModal.labels.name.arabic')}
            layout={Arabic}
            name='title_arabic'
            onChange={this.props.onTextInputChange.bind(this, 'name_arabic')}
            required={this.props.isRequired('name_arabic')}
            value={this.props.item.name_arabic || ''}
          >
            <input
              className='arabic'
              dir='rtl'
              type='text'
            />
          </KeyboardField>
          <KeyboardField
            error={this.props.isError('name_transliteration')}
            id='name_transliteration'
            label={this.props.t('TimespanModal.labels.name.transliteration')}
            layout={layouts.transliterationIjmes}
            name='name_transliteration'
            onChange={this.props.onTextInputChange.bind(this, 'name_transliteration')}
            required={this.props.isRequired('name_transliteration')}
            value={this.props.item.name_transliteration || ''}
          />
          <Form.Group
            widths='equal'
          >
            <Form.Input
              error={this.props.isError('start_date_ah')}
              label={this.props.t('TimespanModal.labels.startDateAh')}
              required={this.props.isRequired('start_date_ah')}
            >
              <FuzzyDate
                calendar='hijri'
                date={FuzzyDateTransform.toFuzzyDate(this.props.item.start_date_ah)}
                onChange={(data) => this.props.onSetState({
                  start_date_ah: FuzzyDateTransform.toRelated(this.props.item, 'start_date_ah', data)
                })}
                title={this.props.t('TimespanModal.labels.startDateAh')}
              />
            </Form.Input>
            <ArrowButtons
              onLeft={() => this.props.onSetState({
                start_date_ah: FuzzyDateTransform.toCopy(this.props.item.start_date_ce)
              })}
              onRight={() => this.props.onSetState({
                start_date_ce: FuzzyDateTransform.toCopy(this.props.item.start_date_ah)
              })}
            />
            <Form.Input
              error={this.props.isError('start_date_ce')}
              label={this.props.t('TimespanModal.labels.startDateCe')}
              required={this.props.isRequired('start_date_ce')}
            >
              <FuzzyDate
                date={FuzzyDateTransform.toFuzzyDate(this.props.item.start_date_ce)}
                onChange={(data) => this.props.onSetState({
                  start_date_ce: FuzzyDateTransform.toRelated(this.props.item, 'start_date_ce', data)
                })}
                title={this.props.t('TimespanModal.labels.startDateCe')}
              />
            </Form.Input>
          </Form.Group>
          <Form.Group
            widths='equal'
          >
            <Form.Input
              error={this.props.isError('end_date_ah')}
              label={this.props.t('TimespanModal.labels.endDateAh')}
              required={this.props.isRequired('end_date_ah')}
            >
              <FuzzyDate
                calendar='hijri'
                date={FuzzyDateTransform.toFuzzyDate(this.props.item.end_date_ah)}
                onChange={(data) => this.props.onSetState({
                  end_date_ah: FuzzyDateTransform.toRelated(this.props.item, 'end_date_ah', data)
                })}
                title={this.props.t('TimespanModal.labels.endDateAh')}
              />
            </Form.Input>
            <ArrowButtons
              onLeft={() => this.props.onSetState({
                end_date_ah: FuzzyDateTransform.toCopy(this.props.item.end_date_ce)
              })}
              onRight={() => this.props.onSetState({
                end_date_ce: FuzzyDateTransform.toCopy(this.props.item.end_date_ah)
              })}
            />
            <Form.Input
              error={this.props.isError('end_date_ce')}
              label={this.props.t('TimespanModal.labels.endDateCe')}
              required={this.props.isRequired('end_date_ce')}
            >
              <FuzzyDate
                date={FuzzyDateTransform.toFuzzyDate(this.props.item.end_date_ce)}
                onChange={(data) => this.props.onSetState({
                  end_date_ce: FuzzyDateTransform.toRelated(this.props.item, 'end_date_ce', data)
                })}
                title={this.props.t('TimespanModal.labels.endDateCe')}
              />
            </Form.Input>
          </Form.Group>
          { this.props.item.timespan_type === TYPE_LEADERSHIP && (
            <EmbeddedList
              actions={[{
                name: 'delete'
              }]}
              buttons={[{
                render: () => (
                  <Button
                    basic
                    content={this.props.t('Common.buttons.multiAdd')}
                    icon='plus'
                    key='source'
                    onClick={this.onMultiAdd.bind(this)}
                  />
                )
              }]}
              columns={[{
                name: 'period',
                label: this.props.t('TimespanModal.timespans.columns.period'),
                resolve: (tp) => tp.period.searchable_name
              }]}
              items={this.props.item.timespan_periods}
              onDelete={this.props.onDeleteChildAssociation.bind(this, 'timespan_periods')}
              onSave={this.props.onSaveChildAssociation.bind(this, 'timespan_periods')}
              showRecordCount
            />
          )}
          { this.state.modal && (
            <Selectize
              collectionName='timespans'
              onClose={this.onCloseMultiAdd.bind(this)}
              onLoad={(params) => Timespans.fetchAll(_.extend(params, {
                sort_by: 'name->>english',
                timespan_type: TYPE_PERIOD
              }))}
              onSave={this.onSaveMultiAdd.bind(this)}
              renderItem={(t) => t.searchable_name}
              selectedItems={_.map(this.props.item.timespan_periods, (tp) => tp.period)}
              title={this.props.t('TimespanModal.title.selectTimespan')}
            />
          )}
        </TabbedModal.Tab>
        <TabbedModal.Tab
          name={this.props.t('Common.tabs.locations')}
        >
          <EmbeddedList
            actions={[{
              name: 'edit'
            }, {
              name: 'copy'
            }, {
              name: 'delete'
            }]}
            columns={[{
              name: 'place_english',
              label: this.props.t('Common.locations.columns.place.english'),
              resolve: (l) => l.place.name_english
            }, {
              className: 'arabic',
              name: 'place_arabic',
              label: this.props.t('Common.locations.columns.place.arabic'),
              resolve: (l) => l.place.name_arabic,
              hidden: true
            }, {
              name: 'place_transliteration',
              label: this.props.t('Common.locations.columns.place.transliteration'),
              resolve: (l) => l.place.name_transliteration,
              hidden: true
            }, {
              name: 'role',
              label: this.props.t('Common.locations.columns.role')
            }, {
              name: 'precision',
              label: this.props.t('Common.locations.columns.precision')
            }, {
              name: 'certainty',
              label: this.props.t('Common.locations.columns.certainty')
            }]}
            items={this.props.item.locations}
            modal={{
              component: LocationModal
            }}
            onDelete={this.props.onDeleteChildAssociation.bind(this, 'locations')}
            onSave={this.props.onSaveChildAssociation.bind(this, 'locations')}
            showRecordCount
          />
        </TabbedModal.Tab>
        { this.props.children }
      </TabbedModal>
    );
  }
}

export default withTranslation()(TimespanModal);
