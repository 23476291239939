// @flow

import React, { useState } from 'react';
import { AccordionList } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';
import _ from 'underscore';
import Session from '../services/Session';
import SourcesService from '../services/Sources';
import SourceTransform from '../transforms/Source';
import type { Source } from '../types/Source';
import SourceItem from './AccordionListItem';
import SourceModal from './SourceModal';

type Props = {
    t: (key: string) => string,
    selectedRows: Array<Source>,
    handleRowSelect: (source: Source) => void,
    onSelectAll: (Array<Source>, Array<Source>) => void,
};

const SelectableSourceAccordion = (props: Props) => {
  const [showSelected, setShowSelected] = useState(false);
  const handleShowSelected = (e, { checked }) => setShowSelected(checked);
  const handleSelectAll = (el, toBeSelected, sourcesOnPage) => props.onSelectAll(toBeSelected, sourcesOnPage);
  return (
    <AccordionList
      buttons={[
        {
          render: () => (
            <Checkbox
              checked={showSelected}
              className='show-selected-toggle'
              disabled={!props.selectedRows.length}
              style={{ marginLeft: 10 }}
              key='toggle'
              label={props.t(showSelected
                ? 'SelectableSourceAccordion.buttons.showAll'
                : 'SelectableSourceAccordion.buttons.showSelected')}
              onClick={props.selectedRows.length ? handleShowSelected : () => {}}
              // Added conditional onClick b/c disabled prop is not preventing onClick event from firing
              toggle
            />
          )
        }
      ]}
      canAddItem={() => false}
      canEditItem={() => Session.isAdmin()}
      canDeleteItem={() => Session.isAdmin()}
      canCopyItem={() => Session.isAdmin()}
      className='selectable-sources-accordion'
      collectionName='sources'
      getChildItems={(items, item) => item.child_source_relations && item.child_source_relations.map((rel) => ({
        relation_type: rel.relation_type,
        activeIndex: `child-${rel.id}`,
        ...rel.child_source
      }))}
      getRootItems={(items) => items}
      // key prop assigned to force the list to re-render when showSelected is toggled
      key={showSelected ? 'selected' : 'all'}
      lazyLoad={false}
      modal={{
        component: SourceModal,
        props: {
          onInitialize: (id) => SourcesService.fetchOne(id).then(({ data }) => data.source)
        }
      }}
      onCopy={(b) => SourcesService.fetchOne(b.id).then(({ data }) => SourceTransform.toCopy(data.source))}
      onDelete={(item) => SourcesService.delete(item).then(({ data }) => data.source)}
      onRowSelect={props.handleRowSelect}
      onSave={(item) => SourcesService.save(item).then(({ data }) => data.source)}
      onSearch={(search, page) => SourcesService.fetchAll({
        search,
        page,
        source_ids: showSelected ? _.pluck(props.selectedRows, 'id') : undefined
      })}
      onSelectAll={handleSelectAll}
      pagination
      renderItem={(item) => (
        <SourceItem columns={[
          { value: item.searchable_name, label: item.relation_type, width: 12 },
          { value: item.source_type, width: 4, label: '' }]}
        />
      )}
      selectable
      selectedRows={props.selectedRows}
      showToggle={(item) => (item.child_source_relations ? item.child_source_relations.length > 0 : 0)}
      showRecordCount
    />
  );
};

export default withTranslation()(SelectableSourceAccordion);
