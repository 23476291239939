// @flow

import React, { useMemo } from 'react';
import { Icon, Item, Popup } from 'semantic-ui-react';
import _ from 'underscore';
import TimespanLabel from './TimespanLabel';
import './TimespanInfo.css';

import type { Timespan } from '../types/Timespan';

type Props = {
  timespan: Timespan
};

const TimespanInfo = (props: Props) => {
  /**
   * Recursively builds the hierarchy of timespans.
   *
   * @param timespan
   * @param array
   */
  const buildTimespans = (timespan, array) => {
    array.push(timespan);

    if (timespan.parent_timespan && timespan.parent_timespan_id) {
      buildTimespans(timespan.parent_timespan, array);
    }
  };

  /**
   * Sets the timespans to display on the popup.
   *
   * @type {unknown}
   */
  const timespans = useMemo(() => {
    if (_.isEmpty(props.timespan.parent_timespan)) {
      return null;
    }

    const array = [];
    buildTimespans(props.timespan, array);

    return _.sortBy(array, (t) => t.node_level);
  }, [props.timespan.parent_timespan]);

  return (
    <div
      className='timespan-info'
    >
      <TimespanLabel type={props.timespan.timespan_type} />
      { props.timespan.searchable_name }
      { timespans && (
        <Popup
          trigger={(
            <Icon
              className='popup-icon'
              name='sitemap'
            />
          )}
          wide='very'
        >
          <Popup.Content>
            <Item.Group>
              { _.map(timespans, (t, index) => (
                <Item
                  style={{
                    marginLeft: `${index * 2}em`,
                    paddingRight: `${index * 2}em`
                  }}
                >
                  <TimespanLabel
                    type={t.timespan_type}
                  />
                  { t.searchable_name }
                </Item>
              ))}
            </Item.Group>
          </Popup.Content>
        </Popup>
      )}
    </div>
  );
};

export default TimespanInfo;
