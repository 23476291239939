// @flow

import BaseService from './BaseService';
import Organization from '../transforms/Organization';
import axios from 'axios';
/**
 * Service class for all organization based API calls.
 */
class Organizations extends BaseService {
  /**
   * Returns the organizations base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/organizations';
  }

  /**
   * Returns the organizations transform object.
   *
   * @returns {Canon}
   */
  getTransform() {
    return Organization;
  }
}

export default new Organizations();
