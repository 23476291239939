// @flow

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Accordion, Header, Icon, Segment
} from 'semantic-ui-react';
import _ from 'underscore';
import formatArrayValue from '../utilities/formatArrayValue';

type Props = {
  title: string,
  record: {},
  resourceStringLabelsPath: string,
  className: string,
  style: Object,
  t: (string)=>void
};

const ExternalRecordVisualizer = (props: Props) => {
  const {
    title, record, className, style
  } = props;
  const [activeIndices, setActiveIndices] = useState([]);

  const handleClick = (e, { index }) => {
    const newIndices = activeIndices.includes(index)
      ? activeIndices.filter((idx) => idx !== index)
      : [...activeIndices, index];
    setActiveIndices(newIndices);
  };
  const items = Object.keys(record).map((k) => {
    const value = record[k];
    const name = props.t(`${props.resourceStringLabelsPath}.${k}`);
    const key = k;
    return { name, value, key };
  });

  return (
    <Segment style={{ marginRight: 30, marginBottom: 10, ...style }} className={className}>
      <Header
        size='tiny'
        color='grey'
        content={title}
      />
      {_.isEmpty(items) ? (
        <p>{props.t('ExternalRecordVisualizer.noData')}</p>
      )
        : (
          <Accordion>
            {items.map((item, idx) => (
              <div key={item.key}>
                {item.value ? (
                  <>
                    <Accordion.Title
                      active={activeIndices.includes(idx)}
                      index={idx}
                      onClick={handleClick}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Icon name='dropdown' />
                      <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                        {item.name}
                      </span>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndices.includes(idx)}>
                      <p style={{ marginLeft: 25, marginBottom: 10, wordWrap: 'break-word' }}>
                        {_.isArray(item.value) ? formatArrayValue(item.value) : item.value }
                      </p>
                    </Accordion.Content>
                  </>
                ) : ''}
              </div>
            ))}
          </Accordion>
        )}
    </Segment>
  );
};

export default withTranslation()(ExternalRecordVisualizer);
