// @flow

import _ from 'underscore';
import Categorizations from './Categorizations';
import Citations from './Citations';
import Footnotes from './Footnotes';
import Locations from './Locations';
import Participations from './Participations';
import References from './References';

import type { Ruling as RulingType } from '../types/Ruling';

/**
 * Class for handling transforming ruling records.
 */
class Ruling {
  PAYLOAD_KEYS = [
    'name',
    'description',
    'answer',
    'basis',
    'subsequent_action',
    'fiqh_ruling_result',
    'original_text',
    'translation',
    'significance',
    'link'
  ];

  /**
   * Returns the ruling object to be sent to the server on POST/PUT requests.
   *
   * @param ruling
   *
   * @returns {*}
   */
  toPayload(ruling: RulingType) {
    return {
      ruling: {
        ..._.pick(ruling, this.PAYLOAD_KEYS),
        ...Categorizations.toPayload(ruling),
        ...Citations.toPayload(ruling),
        ...Footnotes.toPayload(ruling),
        ...Locations.toPayload(ruling),
        ...Participations.toPayload(ruling),
        ...References.toPayload(ruling)
      }
    };
  }
}

export default new Ruling();
