// @flow

/**
 * Service class responsible for all session based functions.
 */
class Session {
  /**
   * Creates a new session based on the passed response.
   *
   * @param response
   */
  static create(response: any) {
    const { admin, uid } = response.data.data;

    sessionStorage.setItem('user',
      JSON.stringify({
        'access-token': response.headers['access-token'],
        client: response.headers.client,
        admin,
        uid
      }));
  }

  /**
   * Removes the current session.
   */
  static destroy() {
    sessionStorage.removeItem('user');
  }

  /**
   * Returns true if the user is currently authenticated.
   *
   * @returns {boolean}
   */
  static isAuthenticated() {
    return !!sessionStorage.getItem('user');
  }

  /**
   * Returns true if the current user is an administrator.
   *
   * @returns {boolean}
   */
  static isAdmin() {
    const user = sessionStorage.getItem('user') || '{}';
    const { admin = false } = JSON.parse(user);

    return admin;
  }

  /**
   * Returns current user's username (uid).
   *
   * @returns {string}
   */
  static getUsername() {
    const user = sessionStorage.getItem('user') || '{}';
    const { uid = null } = JSON.parse(user);

    return uid;
  }
}

export default Session;
