// @flow

import _ from 'underscore';

import type { Citeable } from '../types/concerns/Citeable';
import type { Citation } from '../types/Citation';

/**
 * Class for handling transforming citations records.
 */
class Citations {
  PAYLOAD_KEYS = [
    'id',
    'source_id',
    'page_reference',
    'volume_reference',
    'citation_type',
    '_destroy'
  ];

  /**
   * Returns the passed citation as a dropdown option.
   *
   * @param citation
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(citation: Citation) {
    return {
      key: citation.id,
      value: citation.id,
      text: citation.source.searchable_name
    };
  }
  
  /**
   * Returns the citations for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param citeable
   *
   * @returns {{citations: *}}
   */
  toPayload(citeable: Citeable) {
    return {
      citations: _.map(citeable.citations, (c) => _.pick(c, this.PAYLOAD_KEYS))
    };
  }
}

export default new Citations();
