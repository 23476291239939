// @flow

import React, { useEffect } from 'react';
import {
  AccordionSelector,
  AssociatedDropdown,
  ModalDropdown,
  type EditModalProps
} from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import CitationsService from '../services/Citations';
import Citations from '../transforms/Citations';
import TimespanLabel from './TimespanLabel';
import Timespans from '../services/Timespans';

import type { Categorization } from '../types/Categorization';

type Props = EditModalProps & {
  citeable_id: number,
  citeable_type: string,
  item: Categorization
};

const TimespanCategorizationModal = (props: Props) => {
  // Default the category_type to "Timespan"
  useEffect(() => {
    props.onTextInputChange('category_type', null, { value: 'Timespan' });
  }, []);

  return (
    <Modal
      as={Form}
      className='timespan-categorization-modal'
      closeIcon
      onClose={props.onClose}
      noValidate
      open
      size='small'
    >
      <Modal.Header
        content={props.item.id
          ? props.t('TimespanCategorizationModal.title.edit')
          : props.t('TimespanCategorizationModal.title.add')}
      />
      <Modal.Content>
        <DataFieldDropdown
          {...props}
          column='assigner'
          label={props.t('TimespanCategorizationModal.labels.assigner')}
          table='categorizations'
        />
        <Form.Input
          error={props.isError('category_id')}
          label={props.t('TimespanCategorizationModal.labels.timespan')}
          required={props.isRequired('category_id')}
        >
          <ModalDropdown
            onClear={props.onAssociationInputChange.bind(this, 'category_id', 'category')}
            renderModal={({ onClose, open }) => (
              <AccordionSelector
                collectionName='timespans'
                getChildItems={(items, item) => _.where(items, { parent_timespan_id: item.id })}
                getRootItems={(items) => _.where(items, { parent_timespan_id: null })}
                isSelectable={() => true}
                onClose={onClose}
                onSave={(items) => {
                  props.onAssociationInputChange('category_id', 'category', _.first(items));
                  onClose();
                }}
                onSearch={(parentId, search) => Timespans.fetchAll({ parent_timespan_id: parentId, search })}
                open={open}
                renderItem={(item) => (
                  <div>
                    <TimespanLabel
                      type={item.timespan_type}
                    />
                    { item.searchable_name }
                  </div>
                )}
                selectedItems={props.item.category ? [props.item.category] : []}
                showToggle={(item) => item.child_count > 0}
                title={props.t('TimespanCategorizationModal.title.selectTimespan')}
              />
            )}
            searchQuery={props.item.category && props.item.category.searchable_name}
          />
        </Form.Input>
        { props.citeable_id && props.citeable_type && (
          <Form.Input
            error={props.isError('citation_id')}
            label={props.t('IssueCategorizationModal.labels.citation')}
            required={props.isRequired('citation_id')}
          >
            <AssociatedDropdown
              collectionName='citations'
              onSearch={(search) => CitationsService.fetchAll({
                search,
                sort_by: 'sources.searchable_name',
                citeable_id: props.citeable_id,
                citeable_type: props.citeable_type
              })}
              onSelection={props.onAssociationInputChange.bind(this, 'citation_id', 'citation')}
              renderOption={Citations.toDropdown.bind(this)}
              searchQuery={props.item.citation
              && props.item.citation.source
              && props.item.citation.source.searchable_name}
              value={props.item.citation_id}
            />
          </Form.Input>
        )}
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(TimespanCategorizationModal);
