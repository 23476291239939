// @flow

import _ from 'underscore';
import type { Placeable } from '../types/concerns/Placeable';

/**
 * Class for handling transforming locations records.
 */
class Locations {
  PAYLOAD_KEYS = [
    'id',
    'place_id',
    'citation_id',
    'precision',
    'certainty',
    'role',
    '_destroy'
  ];

  /**
   * Returns the locations for the passed object to be sent to the server on POST/PUT requests.
   *
   * @param placeable
   *
   * @returns {{locations: *}}
   */
  toPayload(placeable: Placeable) {
    return {
      locations: _.map(placeable.locations, (p) => _.pick(p, this.PAYLOAD_KEYS))
    };
  }
}

export default new Locations();
