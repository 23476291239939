// @flow

import React from 'react';
import { type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';

import type { Membership } from '../types/Group';

type Props = EditModalProps & {
  item: Membership
};

const MembershipModal = (props: Props) => (
  <Modal
    as={Form}
    className='group-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('MembershipModal.title.edit')
        : props.t('MembershipModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('memberable_id')}
        label={props.t('MembershipModal.labels.member')}
        required={props.isRequired('memberable_id')}
      >
        { props.renderDropdown(props) }
      </Form.Input>
      <DataFieldDropdown
        {...props}
        column='assigner'
        label={props.t('MembershipModal.labels.assigner')}
        table='memberships'
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(MembershipModal);
