// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Header } from 'semantic-ui-react';
import {
  BooleanIcon,
  EmbeddedList,
  TabbedModal,
  type EditModalProps
} from 'react-components';
import _ from 'underscore';
import CitationModal from './CitationModal';
import DataFieldDropdown from './DataFieldDropdown';
import FootnoteModal from './FootnoteModal';
import IssueCategorizationModal from './IssueCategorizationModal';
import LocationModal from './LocationModal';
import ParticipationModal from './ParticipationModal';
import RationaleCategorizationModal from './RationaleCategorizationModal';
import ReferenceModal from './ReferenceModal';
import TimespanCategorizationModal from './TimespanCategorizationModal';
import TimespanInfo from './TimespanInfo';

import type { Ruling } from '../types/Ruling';

type Props = EditModalProps & {
  children: any,
  item: Ruling
};

const RulingModal = (props: Props) => (
  <TabbedModal
    as={Form}
    centered={false}
    className='ruling-modal wide-modal'
    closeIcon
    onClose={props.onClose}
    header={props.item.id
      ? props.t('RulingModal.title.edit')
      : props.t('RulingModal.title.add')}
    inlineTabs={false}
    open
    size='small'
  >
    <TabbedModal.Tab
      name={props.t('Common.tabs.general')}
    >
      <Form.Input
        autoFocus
        error={props.isError('name')}
        label={props.t('RulingModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('description')}
        label={props.t('RulingModal.labels.description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        required={props.isRequired('description')}
        value={props.item.description || ''}
      />
      <Form.Input
        error={props.isError('answer')}
        label={props.t('RulingModal.labels.answer')}
        onChange={props.onTextInputChange.bind(this, 'answer')}
        required={props.isRequired('answer')}
        value={props.item.answer || ''}
      />
      <DataFieldDropdown
        {...props}
        column='basis'
        label={props.t('RulingModal.labels.basis')}
        table='rulings'
      />
      <DataFieldDropdown
        {...props}
        column='subsequent_action'
        label={props.t('RulingModal.labels.subsequentAction')}
        table='rulings'
      />
      <DataFieldDropdown
        {...props}
        column='fiqh_ruling_result'
        label={props.t('RulingModal.labels.fiqhRulingResult')}
        table='rulings'
      />
      <Form.TextArea
        error={props.isError('original_text')}
        label={props.t('RulingModal.labels.originalText')}
        onChange={props.onTextInputChange.bind(this, 'original_text')}
        required={props.isRequired('original_text')}
        value={props.item.original_text || ''}
      />
      <Form.TextArea
        error={props.isError('translation')}
        label={props.t('RulingModal.labels.translation')}
        onChange={props.onTextInputChange.bind(this, 'translation')}
        required={props.isRequired('translation')}
        value={props.item.translation || ''}
      />
      <Form.TextArea
        error={props.isError('significance')}
        label={props.t('RulingModal.labels.significance')}
        onChange={props.onTextInputChange.bind(this, 'significance')}
        required={props.isRequired('significance')}
        value={props.item.significance || ''}
      />
      <Form.Input
        error={props.isError('link')}
        label={props.t('RulingModal.labels.link')}
        onChange={props.onTextInputChange.bind(this, 'link')}
        required={props.isRequired('link')}
        value={props.item.link || ''}
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.issues')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.issues.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.issues.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.issues.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Issue' })}
        modal={{
          component: IssueCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.rationales')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.rationales.columns.name.english'),
          resolve: (c) => c.category.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.rationales.columns.name.arabic'),
          resolve: (c) => c.category.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.rationales.columns.name.transliteration'),
          resolve: (c) => c.category.name_transliteration,
          hidden: true
        }, {
          name: 'assigner',
          label: props.t('Common.rationales.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Rationale' })}
        modal={{
          component: RationaleCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.citations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'source',
          label: props.t('Common.citations.columns.source'),
          resolve: (c) => c.source.searchable_name
        }, {
          name: 'page_reference',
          label: props.t('Common.citations.columns.pageReference')
        }, {
          name: 'volume_reference',
          label: props.t('Common.citations.columns.volumeReference')
        }, {
          name: 'citation_type',
          label: props.t('Common.citations.columns.citationType')
        }]}
        items={props.item.citations}
        modal={{
          component: CitationModal
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'citations')}
        onSave={props.onSaveChildAssociation.bind(this, 'citations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.participations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.participations.columns.name'),
          resolve: (p) => (p.participant_type === 'Person' ? p.participant.searchable_name : p.participant.name_english)
        }, {
          name: 'participant_type',
          label: props.t('Common.participations.columns.type')
        }, {
          name: 'role',
          label: props.t('Common.participations.columns.role')
        }]}
        items={props.item.participations}
        modal={{
          component: ParticipationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'participations')}
        onSave={props.onSaveChildAssociation.bind(this, 'participations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.locations')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name_english',
          label: props.t('Common.locations.columns.place.english'),
          resolve: (l) => l.place.name_english
        }, {
          className: 'arabic',
          name: 'name_arabic',
          label: props.t('Common.locations.columns.place.arabic'),
          resolve: (l) => l.place.name_arabic,
          hidden: true
        }, {
          name: 'name_transliteration',
          label: props.t('Common.locations.columns.place.transliteration'),
          resolve: (l) => l.place.name_transliteration,
          hidden: true
        }, {
          name: 'role',
          label: props.t('Common.locations.columns.role')
        }, {
          name: 'precision',
          label: props.t('Common.locations.columns.precision')
        }, {
          name: 'certainty',
          label: props.t('Common.locations.columns.certainty')
        }]}
        items={props.item.locations}
        modal={{
          component: LocationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'locations')}
        onSave={props.onSaveChildAssociation.bind(this, 'locations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.timespans')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.timespans.columns.name'),
          render: (c) => <TimespanInfo timespan={c.category} />
        }, {
          name: 'assigner',
          label: props.t('Common.timespans.columns.assigner')
        }]}
        items={_.where(props.item.categorizations, { category_type: 'Timespan' })}
        modal={{
          component: TimespanCategorizationModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling'
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'categorizations')}
        onSave={props.onSaveChildAssociation.bind(this, 'categorizations')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.references')}
    >
      <Header
        content={props.t('Common.references.types.canons')}
      />
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'title_english',
          label: props.t('Common.references.columns.title.english'),
          resolve: (r) => r.referent.title_english
        }, {
          className: 'arabic',
          name: 'title_arabic',
          label: props.t('Common.references.columns.title.arabic'),
          resolve: (r) => r.referent.title_arabic,
          hidden: true
        }, {
          name: 'title_transliteration',
          label: props.t('Common.references.columns.title.transliteration'),
          resolve: (r) => r.referent.title_transliteration,
          hidden: true
        }, {
          className: 'arabic',
          name: 'legal_formula_arabic',
          label: props.t('Common.references.columns.legalFormula.arabic'),
          resolve: (r) => r.referent.legal_formula_arabic
        }, {
          name: 'legal_formula_english',
          label: props.t('Common.references.columns.legalFormula.english'),
          resolve: (r) => r.referent.legal_formula_english,
          hidden: true
        }, {
          name: 'legal_formula_transliteration',
          label: props.t('Common.references.columns.legalFormula.transliteration'),
          resolve: (r) => r.referent.legal_formula_transliteration,
          hidden: true
        }, {
          name: 'explicit',
          label: props.t('Common.references.columns.explicit'),
          render: (r) => <BooleanIcon value={r.explicit} />
        }]}
        items={_.where(props.item.references, { referent_type: 'Canon' })}
        modal={{
          component: ReferenceModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling',
            defaults: {
              referent_type: 'Canon'
            }
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'references')}
        onSave={props.onSaveChildAssociation.bind(this, 'references')}
        showRecordCount
      />
      <Header
        content={props.t('Common.references.types.cases')}
      />
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'name',
          label: props.t('Common.references.columns.name'),
          resolve: (r) => r.referent.searchable_name
        }, {
          name: 'type',
          label: props.t('Common.references.columns.type'),
          resolve: (r) => r.referent.case_type
        }, {
          name: 'explicit',
          label: props.t('Common.references.columns.explicit'),
          render: (r) => <BooleanIcon value={r.explicit} />
        }]}
        items={_.where(props.item.references, { referent_type: 'Case' })}
        modal={{
          component: ReferenceModal,
          props: {
            citeable_id: props.item.id,
            citeable_type: 'Ruling',
            defaults: {
              referent_type: 'Case'
            }
          }
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'references')}
        onSave={props.onSaveChildAssociation.bind(this, 'references')}
        showRecordCount
      />
    </TabbedModal.Tab>
    <TabbedModal.Tab
      name={props.t('Common.tabs.footnotes')}
    >
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        columns={[{
          name: 'label',
          label: props.t('Common.footnotes.columns.label')
        }, {
          name: 'content',
          label: props.t('Common.footnotes.columns.content')
        }]}
        items={props.item.footnotes}
        modal={{
          component: FootnoteModal
        }}
        onDelete={props.onDeleteChildAssociation.bind(this, 'footnotes')}
        onSave={props.onSaveChildAssociation.bind(this, 'footnotes')}
        showRecordCount
      />
    </TabbedModal.Tab>
    { props.children }
  </TabbedModal>
);

export default withTranslation()(RulingModal);
