// @flow

import React, { Component } from 'react';
import { type EditContainerProps, ListTable } from 'react-components';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Header,
  Label,
  Loader,
  Menu,
  Modal
} from 'semantic-ui-react';
import PeopleFiltersModal from '../components/PeopleFiltersModal';
import SourcesFiltersModal from '../components/SourcesFiltersModal';
import SourceModal from '../components/SourceModal';
import DataFieldOptions from '../services/DataFieldOptions';
import GenrePeopleService from '../services/GenrePeople';
import GenrePlacesService from '../services/GenrePlaces';
import GenreArchivesService from '../services/GenreArchives';
import GenreTimespansService from '../services/GenreTimespans';
import PeopleService from '../services/People';
import SourcesService from '../services/Sources';
import './Genre.css';

import type { DataFieldOption } from '../types/DataFieldOption';
import type { Place } from '../types/Place';
import type { Person } from '../types/Person';
import type { Timespan } from '../types/Timespan';

type Props = EditContainerProps & {
  item: DataFieldOption
};

type State = {
  currentTab: string,
  item: ?DataFieldOption,
  selectedAuthor: ?Person,
  selectedPlace: ?Place,
  selectedTimespan: ?Timespan,
  selectedArchive: ?string,
};

const PlaceTypes = {
  author: 'author_location',
  publication: 'publication_location'
};

const Tabs = {
  sources: 'sources',
  authors: 'authors',
  timespans: 'timespans',
  publication: 'publication',
  location: 'location',
  archive: 'archive'
};

class Genre extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: Tabs.sources,
      item: null,
      selectedAuthor: null,
      selectedPlace: null,
      selectedTimespan: null,
      selectedArchive: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    DataFieldOptions
      .fetchOne(id)
      .then(({ data }) => this.setState({ item: data.data_field_option }));
  }

  render() {
    return (
      <Dimmer.Dimmable
        as='div'
        className='genre'
      >
        { !this.state.item && (
          <Dimmer
            active
            inverted
          >
            <Loader
              content={this.props.t('Common.messages.loading')}
            />
          </Dimmer>
        )}
        { this.state.item && (
          <>
            { this.renderTabMenu() }
            { this.renderSources() }
            { this.renderAuthors() }
            { this.renderTimespans() }
            { this.renderPublicationLocations() }
            { this.renderAuthorLocations() }
            { this.renderArchives() }
          </>
        )}
      </Dimmer.Dimmable>
    );
  }

  renderAuthorLocations() {
    if (this.state.currentTab !== Tabs.location) {
      return null;
    }

    return (
      <>
        <ListTable
          collectionName='genre_places'
          columns={[{
            name: 'name->>english',
            label: this.props.t('Genre.places.columns.name.english'),
            resolve: (place) => place.name_english,
            sortable: true
          }, {
            name: 'name->>arabic',
            label: this.props.t('Genre.places.columns.name.arabic'),
            resolve: (place) => place.name_arabic,
            sortable: true,
            hidden: true
          }, {
            name: 'name->>transliteration',
            label: this.props.t('Genre.places.columns.name.transliteration'),
            resolve: (place) => place.name_transliteration,
            sortable: true,
            hidden: true
          }, {
            name: 'lat',
            label: this.props.t('Genre.places.columns.lat'),
            hidden: true,
            sortable: true
          }, {
            name: 'lng',
            label: this.props.t('Genre.places.columns.lng'),
            hidden: true,
            sortable: true
          }, {
            name: 'source_total',
            label: this.props.t('Genre.places.columns.authorTotal'),
            render: (place) => (
              <Label
                as={Button}
                content={place.source_total}
                onClick={() => this.setState({ selectedPlace: place })}
              />
            ),
            sortable: true
          }]}
          onLoad={(params) => GenrePlacesService.fetchAll({
            ...params,
            genre: this.state.item && this.state.item.value,
            place_type: PlaceTypes.author
          })}
          showRecordCount
        />
        { this.state.selectedPlace && (
          <GenreModal
            header={this.state.selectedPlace.name_english}
            onClose={() => this.setState({ selectedPlace: null })}
            subheader={this.state.item && this.state.item.value}
          >
            <ListTable
              collectionName='genre_people'
              columns={[{
                name: 'searchable_name',
                label: this.props.t('Genre.authors.columns.name'),
                sortable: true
              }, {
                name: 'source_total',
                label: this.props.t('Genre.authors.columns.sourceTotal'),
                render: (person) => (
                  <Label
                    as={Button}
                    content={person.source_total}
                    onClick={() => this.setState({ selectedAuthor: person })}
                  />
                ),
                sortable: true
              }]}
              filters={{
                component: PeopleFiltersModal,
                props: {
                  modern: '',
                  birth_date_ah: null,
                  birth_date_ce: null,
                  death_date_ah: null,
                  death_date_ce: null
                }
              }}
              onLoad={(params) => GenrePeopleService.fetchAll({
                ...params,
                genre: this.state.item && this.state.item.value,
                place_id: this.state.selectedPlace && this.state.selectedPlace.id
              })}
              showRecordCount
            />
            { this.state.selectedAuthor && (
              <GenreModal
                header={this.state.selectedAuthor.searchable_name}
                onClose={() => this.setState({ selectedAuthor: null })}
                subheader={this.state.item && this.state.item.value}
              >
                <ListTable
                  collectionName='sources'
                  columns={[{
                    name: 'searchable_name',
                    label: this.props.t('Genre.sources.columns.sourceTitle'),
                    sortable: true
                  }, {
                    name: 'source_type',
                    label: this.props.t('Genre.sources.columns.type'),
                    sortable: true
                  }]}
                  onLoad={(params) => SourcesService.fetchAll({
                    ...params,
                    genre: this.state.item && this.state.item.value,
                    author_id: this.state.selectedAuthor && this.state.selectedAuthor.id
                  })}
                  showRecordCount
                />
              </GenreModal>
            )}
          </GenreModal>
        )}
      </>
    );
  }

  renderAuthors() {
    if (this.state.currentTab !== Tabs.authors) {
      return null;
    }

    return (
      <ListTable
        collectionName='people'
        columns={[{
          name: 'searchable_name',
          label: this.props.t('Genre.authors.columns.name'),
          sortable: true
        }]}
        filters={{
          component: PeopleFiltersModal,
          props: {
            modern: '',
            birth_date_ah: null,
            birth_date_ce: null,
            death_date_ah: null,
            death_date_ce: null
          }
        }}
        onLoad={(params) => PeopleService.fetchAll({
          ...params,
          genre: this.state.item && this.state.item.value
        })}
        showRecordCount
      />
    );
  }

  renderPublicationLocations() {
    if (this.state.currentTab !== Tabs.publication) {
      return null;
    }

    return (
      <>
        <ListTable
          collectionName='genre_places'
          columns={[{
            name: 'name->>english',
            label: this.props.t('Genre.places.columns.name.english'),
            resolve: (place) => place.name_english,
            sortable: true
          }, {
            name: 'name->>arabic',
            label: this.props.t('Genre.places.columns.name.arabic'),
            resolve: (place) => place.name_arabic,
            sortable: true,
            hidden: true
          }, {
            name: 'name->>transliteration',
            label: this.props.t('Genre.places.columns.name.transliteration'),
            resolve: (place) => place.name_transliteration,
            sortable: true,
            hidden: true
          }, {
            name: 'lat',
            label: this.props.t('Genre.places.columns.lat'),
            hidden: true,
            sortable: true
          }, {
            name: 'lng',
            label: this.props.t('Genre.places.columns.lng'),
            hidden: true,
            sortable: true
          }, {
            name: 'source_total',
            label: this.props.t('Genre.places.columns.sourceTotal'),
            render: (place) => (
              <Label
                as={Button}
                content={place.source_total}
                onClick={() => this.setState({ selectedPlace: place })}
              />
            ),
            sortable: true
          }]}
          onLoad={(params) => GenrePlacesService.fetchAll({
            ...params,
            genre: this.state.item && this.state.item.value,
            place_type: PlaceTypes.publication
          })}
          showRecordCount
        />
        { this.state.selectedPlace && (
          <GenreModal
            header={this.state.selectedPlace.name_english}
            onClose={() => this.setState({ selectedPlace: null })}
            open={!!this.state.selectedPlace}
            subheader={this.state.item && this.state.item.value}
          >
            <ListTable
              collectionName='sources'
              columns={[{
                name: 'searchable_name',
                label: this.props.t('Genre.sources.columns.sourceTitle'),
                sortable: true
              }, {
                name: 'source_type',
                label: this.props.t('Genre.sources.columns.type'),
                sortable: true
              }]}
              onLoad={(params) => SourcesService.fetchAll({
                ...params,
                genre: this.state.item && this.state.item.value,
                place_id: this.state.selectedPlace && this.state.selectedPlace.id
              })}
              showRecordCount
            />
          </GenreModal>
        )}
      </>
    );
  }

  renderSources() {
    if (this.state.currentTab !== Tabs.sources) {
      return null;
    }

    return (
      <ListTable
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        collectionName='sources'
        columns={[{
          name: 'searchable_name',
          label: this.props.t('Genre.sources.columns.sourceTitle'),
          sortable: true
        }, {
          name: 'source_type',
          label: this.props.t('Genre.sources.columns.type'),
          sortable: true
        }]}
        filters={{
          component: SourcesFiltersModal,
          props: {
            author_id: '',
            publication_date_ah: null,
            publication_date_ce: null,
            source_type: ''
          }
        }}
        modal={{
          component: SourceModal,
          props: {
            onInitialize: (sourceId) => SourcesService.fetchOne(sourceId).then(({ data }) => data.source),
            defaults: {
              genre: this.state.item && this.state.item.value
            }
          }
        }}
        onDelete={(source) => SourcesService.delete(source)}
        onLoad={(params) => SourcesService.fetchAll({
          ...params,
          genre: this.state.item && this.state.item.value
        })}
        onSave={(source) => SourcesService.save(source)}
        showRecordCount
      />
    );
  }

  renderArchives() {
    if (this.state.currentTab !== Tabs.archive) {
      return null;
    }

    return (
      <>
        <ListTable
          collectionName='genre_archives'
          columns={[{
            name: 'archive',
            label: this.props.t('Genre.archives.columns.archive'),
            sortable: true,
            render: (source) => (source.archive ? source.archive : (
              <span style={{ color: 'gray' }}>
                {this.props.t('Genre.archives.blank')}
              </span>
            ))
          }, {
            name: 'source_total',
            label: this.props.t('Genre.archives.columns.sourceTotal'),
            sortable: false,
            render: (source) => (
              <Label
                as={Button}
                disabled={!source.archive}
                content={source.source_total}
                onClick={() => this.setState({ selectedArchive: source.archive })}
              />
            ),
          }]}
          filters={{
            component: SourcesFiltersModal,
            props: {
              author_id: '',
              publication_date_ah: null,
              publication_date_ce: null,
              source_type: ''
            }
          }}
          modal={{
            component: SourceModal,
            props: {
              onInitialize: (sourceId) => SourcesService.fetchOne(sourceId).then(({ data }) => data.source),
              defaults: {
                genre: this.state.item && this.state.item.value
              }
            }
          }}
          onDelete={(source) => SourcesService.delete(source)}
          onLoad={(params) => GenreArchivesService.fetchAll({
            ...params,
            genre: this.state.item && this.state.item.value,
          })}
          onSave={(source) => SourcesService.save(source)}
          showRecordCount
        />
        { this.state.selectedArchive && (
          <GenreModal
            header={this.state.selectedArchive}
            onClose={() => this.setState({ selectedArchive: null })}
            subheader={this.state.item && this.state.item.value}
          >
            <ListTable
              collectionName='genre_archives'
              columns={[{
                name: 'archive_location',
                label: this.props.t('Genre.archives.columns.archiveLocation'),
                sortable: true,
                render: (source) => (source.archive_location ? source.archive_location : (
                  <span style={{ color: 'gray' }}>
                    {this.props.t('Genre.archives.blank')}
                  </span>
                ))
              }, {
                name: 'source_total',
                label: this.props.t('Genre.archives.columns.sourceTotal'),
                sortable: false,
                render: (source) => (source.archive_location ? source.source_total : (
                  <span style={{ color: 'gray' }}>
                    {source.source_total}
                  </span>
                ))
              }]}
              onLoad={(params) => GenreArchivesService.fetchAll(
                {
                  ...params,
                  archive_name: this.state.selectedArchive,
                  genre: this.state.item && this.state.item.value,
                }
              )}
              showRecordCount
            />
          </GenreModal>
        )}
      </>
    );
  }

  renderTabMenu() {
    return (
      <Menu
        pointing
        secondary
        stackable
      >
        <Menu.Item
          as='h1'
          content={this.state.item && this.state.item.value}
          header
        />
        <Menu.Item
          active={Tabs.sources === this.state.currentTab}
          key={Tabs.sources}
          name={this.props.t('Genre.tabs.sources')}
          onClick={() => this.setState({ currentTab: Tabs.sources })}
        />
        <Menu.Item
          active={Tabs.authors === this.state.currentTab}
          key={Tabs.authors}
          name={this.props.t('Genre.tabs.authors')}
          onClick={() => this.setState({ currentTab: Tabs.authors })}
        />
        <Menu.Item
          active={Tabs.timespans === this.state.currentTab}
          key={Tabs.timespans}
          name={this.props.t('Genre.tabs.timespans')}
          onClick={() => this.setState({ currentTab: Tabs.timespans })}
        />
        <Menu.Item
          active={Tabs.publication === this.state.currentTab}
          key={Tabs.publication}
          name={this.props.t('Genre.tabs.publication')}
          onClick={() => this.setState({ currentTab: Tabs.publication })}
        />
        <Menu.Item
          active={Tabs.location === this.state.currentTab}
          key={Tabs.location}
          name={this.props.t('Genre.tabs.location')}
          onClick={() => this.setState({ currentTab: Tabs.location })}
        />
        <Menu.Item
          active={Tabs.archive === this.state.currentTab}
          key={Tabs.archive}
          name={this.props.t('Genre.tabs.archives')}
          onClick={() => this.setState({ currentTab: Tabs.archive })}
        />
        <Menu.Menu
          position='right'
        >
          <Menu.Item>
            <Button
              content={this.props.t('Genre.buttons.back')}
              icon='arrow alternate circle left outline'
              onClick={() => this.props.history.goBack()}
              primary
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  renderTimespans() {
    if (this.state.currentTab !== Tabs.timespans) {
      return null;
    }

    return (
      <>
        <ListTable
          collectionName='genre_timespans'
          columns={[{
            name: 'searchable_name',
            label: this.props.t('Genre.timespans.columns.name.searchable'),
            sortable: true
          }, {
            name: 'name_arabic',
            label: this.props.t('Genre.timespans.columns.name.arabic'),
            sortable: true,
            hidden: true
          }, {
            name: 'name_transliteration',
            label: this.props.t('Genre.timespans.columns.name.transliteration'),
            sortable: true,
            hidden: true
          }, {
            name: 'source_total',
            label: this.props.t('Genre.timespans.columns.sourceTotal'),
            render: (timespan) => (
              <Label
                as={Button}
                content={timespan.source_total}
                onClick={() => this.setState({ selectedTimespan: timespan })}
              />
            ),
            sortable: true
          }]}
          onLoad={(params) => GenreTimespansService.fetchAll(({
            ...params,
            genre: this.state.item && this.state.item.value
          }))}
          showRecordCount
        />
        { this.state.selectedTimespan && (
          <GenreModal
            header={this.state.selectedTimespan.searchable_name}
            onClose={() => this.setState({ selectedTimespan: null })}
            open
            subheader={this.state.item && this.state.item.value}
          >
            <ListTable
              collectionName='sources'
              columns={[{
                name: 'searchable_name',
                label: this.props.t('Genre.sources.columns.sourceTitle'),
                sortable: true
              }, {
                name: 'source_type',
                label: this.props.t('Genre.sources.columns.type'),
                sortable: true
              }]}
              onLoad={(params) => SourcesService.fetchAll({
                ...params,
                genre: this.state.item && this.state.item.value,
                timespan_id: this.state.selectedTimespan && this.state.selectedTimespan.id
              })}
              showRecordCount
            />
          </GenreModal>
        )}
      </>
    );
  }
}

const GenreModal = withTranslation()((props) => (
  <Modal
    centered={false}
    open
  >
    <Modal.Header>
      <Header
        content={props.header}
        subheader={props.subheader}
      />
    </Modal.Header>
    <Modal.Content>
      { props.children }
    </Modal.Content>
    <Modal.Actions>
      <Button
        content={props.t('Common.buttons.close')}
        inverted
        onClick={props.onClose.bind(this)}
        primary
        size='medium'
        type='button'
      />
    </Modal.Actions>
  </Modal>
));

export default withTranslation()(withRouter(Genre));
