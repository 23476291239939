// @flow

import React from 'react';
import { ListTable } from 'react-components';
import { withTranslation } from 'react-i18next';
import CanonsFiltersModal from './CanonsFiltersModal';
import CanonModal from './CanonModal';
import CanonsService from '../services/Canons';

import type { Canon } from '../types/Canon';

type Props = {
  allowAdd?: boolean,
  onDelete: (canon: Canon) => Promise<any>,
  onLoad: (params: any) => Promise<any>,
  onSave: (canon: Canon) => Promise<any>,
  saved: boolean,
  t: (key: string) => string,
  visible: boolean
};

const CanonsList = (props: Props) => (
  <div
    className='canons-list-container'
    style={{
      display: props.visible ? 'block' : 'none'
    }}
  >
    <ListTable
      actions={[{
        name: 'edit'
      }, {
        name: 'copy'
      }, {
        name: 'delete'
      }]}
      addButton={props.allowAdd ? undefined : {}}
      className='canons-list'
      collectionName='canons'
      columns={[{
        name: 'title->>english',
        label: props.t('Canons.columns.title.english'),
        resolve: (canon) => canon.title_english,
        sortable: true
      }, {
        className: 'arabic',
        name: 'title->>arabic',
        label: props.t('Canons.columns.title.arabic'),
        resolve: (canon) => canon.title_arabic,
        sortable: true,
        hidden: true
      }, {
        name: 'title->>transliteration',
        label: props.t('Canons.columns.title.transliteration'),
        resolve: (canon) => canon.title_transliteration,
        sortable: true,
        hidden: true
      }, {
        className: 'arabic',
        name: 'legal_formula->>arabic',
        label: props.t('Canons.columns.legalFormula.arabic'),
        resolve: (canon) => canon.legal_formula_arabic,
        sortable: true
      }, {
        name: 'legal_formula->>english',
        label: props.t('Canons.columns.legalFormula.english'),
        resolve: (canon) => canon.legal_formula_english,
        sortable: true,
        hidden: true
      }, {
        name: 'legal_formula->>transliteration',
        label: props.t('Canons.columns.legalFormula.transliteration'),
        resolve: (canon) => canon.legal_formula_transliteration,
        sortable: true,
        hidden: true
      }]}
      filters={{
        component: CanonsFiltersModal,
        props: {
          function: '',
          origin: [],
          title_origin: ''
        }
      }}
      modal={{
        component: CanonModal,
        props: {
          onInitialize: (id) => (
            CanonsService
              .fetchOne(id)
              .then(({ data }) => data.canon)
          )
        }
      }}
      onDelete={props.onDelete.bind(this)}
      onLoad={props.onLoad.bind(this)}
      onSave={props.onSave.bind(this)}
      saved={props.saved}
      showRecordCount
    />
  </div>
);

CanonsList.defaultProps = {
  allowAdd: true
};

export default withTranslation()(CanonsList);
