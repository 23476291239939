// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  KeyboardField,
  type EditModalProps
} from 'react-components';
import { Form, Modal } from 'semantic-ui-react';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';

import type { Rationale } from '../types/Rationale';
import layouts from '../utilities/customKeyboardLayouts';

type Props = EditModalProps & {
  item: Rationale
};

const RationaleModal = (props: Props) => (
  <Modal
    as={Form}
    className='rationale-modal'
    closeIcon
    onClose={props.onClose}
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('RationaleModal.title.edit')
        : props.t('RationaleModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('name_english')}
        label={props.t('RationaleModal.labels.name.english')}
        onChange={props.onTextInputChange.bind(this, 'name_english')}
        required={props.isRequired('name_english')}
        value={props.item.name_english || ''}
      />
      <KeyboardField
        error={props.isError('name_arabic')}
        id='name_arabic'
        label={props.t('RationaleModal.labels.name.arabic')}
        layout={Arabic}
        name='title_arabic'
        onChange={props.onTextInputChange.bind(this, 'name_arabic')}
        required={props.isRequired('name_arabic')}
        value={props.item.name_arabic || ''}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </KeyboardField>
      <KeyboardField
        error={props.isError('name_transliteration')}
        id='name_transliteration'
        label={props.t('RationaleModal.labels.name.transliteration')}
        layout={layouts.transliterationIjmes}
        name='name_transliteration'
        onChange={props.onTextInputChange.bind(this, 'name_transliteration')}
        required={props.isRequired('name_transliteration')}
        value={props.item.name_transliteration || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(RationaleModal);
