// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  AccordionSelector,
  KeyboardField,
  ModalDropdown,
  type EditModalProps
} from 'react-components';
import { Form, Modal } from 'semantic-ui-react';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import _ from 'underscore';
import CanonTypes from '../services/CanonTypes';
import DataFieldDropdown from './DataFieldDropdown';

import type { CanonType } from '../types/CanonType';
import layouts from '../utilities/customKeyboardLayouts';

type Props = EditModalProps & {
  item: CanonType
}

const MAX_PARENT_NODE_LEVEL = 0;

const CanonTypeModal = (props: Props) => (
  <Modal
    as={Form}
    className='canon-type-modal'
    closeIcon
    onClose={props.onClose}
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('CanonTypeModal.title.edit')
        : props.t('CanonTypeModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('parent_canon_type_id')}
        label={props.t('CanonTypeModal.labels.parentType')}
        required={props.isRequired('parent_canon_type_id')}
      >
        <ModalDropdown
          onClear={props.onAssociationInputChange.bind(this, 'parent_canon_type_id', 'parent_canon_type')}
          renderModal={({ onClose, open }) => (
            <AccordionSelector
              collectionName='canon_types'
              getChildItems={(items, item) => _.where(items, { parent_canon_type_id: item.id })}
              getRootItems={(items) => _.where(items, { parent_canon_type_id: null })}
              isSelectable={(item) => item.node_level <= MAX_PARENT_NODE_LEVEL}
              onClose={onClose}
              onSave={(items) => {
                props.onAssociationInputChange('parent_canon_type_id', 'parent_canon_type', _.first(items));
                onClose();
              }}
              onSearch={(parentId, search) => CanonTypes.fetchAll({ parent_canon_type_id: parentId, search })}
              open={open}
              renderItem={(item) => item.name_english}
              selectedItems={props.item.canon_type ? [props.item.canon_type] : []}
              showToggle={(item) => item.node_level <= MAX_PARENT_NODE_LEVEL}
              title={props.t('CanonTypeModal.title.selectCanonType')}
            />
          )}
          searchQuery={props.item.parent_canon_type && props.item.parent_canon_type.name_english}
        />
      </Form.Input>
      <DataFieldDropdown
        {...props}
        column='assigner'
        label={props.t('CanonTypeModal.labels.assigner')}
        multiple
        table='canon_types'
      />
      <Form.Input
        autoFocus
        error={props.isError('name_english')}
        label={props.t('CanonTypeModal.labels.name.english')}
        onChange={props.onTextInputChange.bind(this, 'name_english')}
        required={props.isRequired('name_english')}
        value={props.item.name_english || ''}
      />
      <KeyboardField
        error={props.isError('name_arabic')}
        id='name_arabic'
        label={props.t('CanonTypeModal.labels.name.arabic')}
        layout={Arabic}
        name='title_arabic'
        onChange={props.onTextInputChange.bind(this, 'name_arabic')}
        required={props.isRequired('name_arabic')}
        value={props.item.name_arabic || ''}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </KeyboardField>
      <KeyboardField
        error={props.isError('name_transliteration')}
        id='name_transliteration'
        label={props.t('CanonTypeModal.labels.name.transliteration')}
        layout={layouts.transliterationIjmes}
        name='name_transliteration'
        onChange={props.onTextInputChange.bind(this, 'name_transliteration')}
        required={props.isRequired('name_transliteration')}
        value={props.item.name_transliteration || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(CanonTypeModal);
