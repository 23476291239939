// @flow

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import DataFieldDropdown from './DataFieldDropdown';
import Organizations from '../services/Organizations';
import OrganizationModal from './OrganizationModal';
import { AssociatedDropdown, type EditModalProps } from 'react-components';
import OrganizationTransform from '../transforms/Organization';
import type { Holding } from '../types/Holding';

type Props = EditModalProps & {
  item: Holding
};

const HoldingModal = (props: Props) => (
  <Modal
    as={Form}
    className='holding-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('HoldingModal.title.edit')
        : props.t('HoldingModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        autofocus
        error={props.isError('organization_id')}
        label={props.t('HoldingModal.labels.organization')}
        required={props.isRequired('organization_id')}
      >
        <AssociatedDropdown
          collectionName='organizations'
          onSearch={(search) => Organizations.fetchAll({
            search,
            sort_by: 'name',
            publishers: false
          })}
          modal={{
            component: OrganizationModal,
            props: {
              showSources: false
            },
            onSave: (item) => Organizations.save(item).then(({ data }) => data.organization)
          }}
          onSelection={props.onAssociationInputChange.bind(this, 'organization_id', 'organization')}
          renderOption={OrganizationTransform.toDropdown.bind(this)}
          searchQuery={props.item.organization && props.item.organization.name}
          value={props.item.organization_id || ''}
        />
      </Form.Input>
      <DataFieldDropdown
        {...props}
        column='role'
        label={props.t('SourceModal.holdings.columns.role')}
        table='holdings'
      />
      <DataFieldDropdown
        {...props}
        column='identifier_type'
        label={props.t('SourceModal.holdings.columns.identifierType')}
        table='holdings'
      />
      <Form.Input
        error={props.isError('identifier')}
        label={props.t('SourceModal.holdings.columns.identifier')}
        onChange={props.onTextInputChange.bind(this, 'identifier')}
        required={props.isRequired('identifier')}
        value={props.item.identifier || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(HoldingModal);
