// @flow

import React from 'react';
import { AssociatedDropdown, type ComponentProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';
import SourceModal from './SourceModal';
import Sources from '../services/Sources';

import type { Citation } from '../types/Citation';
import Source from '../transforms/Source';

type Props = ComponentProps & {
  item: Citation
}

const CitationModal = (props: Props) => (
  <Modal
    as={Form}
    className='citation-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('CitationModal.title.edit')
        : props.t('CitationModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('source_id')}
        label={props.t('CitationModal.labels.source')}
        required={props.isRequired('source_id')}
      >
        <AssociatedDropdown
          collectionName='sources'
          modal={{
            component: SourceModal,
            onSave: (source) => Sources.save(source).then(({ data }) => data.source)
          }}
          onSearch={(search) => Sources.fetchAll({ search, sort_by: 'searchable_name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'source_id', 'source')}
          renderOption={Source.toDropdown.bind(this)}
          searchQuery={props.item.source && props.item.source.searchable_name}
          value={props.item.source_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('page_reference')}
        label={props.t('CitationModal.labels.pageReference')}
        onChange={props.onTextInputChange.bind(this, 'page_reference')}
        required={props.isRequired('page_reference')}
        value={props.item.page_reference || ''}
      />
      <Form.Input
        error={props.isError('volume_reference')}
        label={props.t('CitationModal.labels.volumeReference')}
        onChange={props.onTextInputChange.bind(this, 'volume_reference')}
        required={props.isRequired('volume_reference')}
        value={props.item.volume_reference || ''}
      />
      <DataFieldDropdown
        {...props}
        column='citation_type'
        label={props.t('CitationModal.labels.citationType')}
        table='citations'
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(CitationModal);
