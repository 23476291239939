// @flow

import BaseService from './BaseService';

/**
 * Service class for all citation based API calls.
 */
class Citations extends BaseService {
  /**
   * Returns the citations base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/citations';
  }
}

export default new Citations();
