// @flow

import React from 'react';
import { AccordionList } from 'react-components';
import { Grid } from 'semantic-ui-react';
import _ from 'underscore';
import TimespanModal from '../components/TimespanModal';
import Session from '../services/Session';
import TimespanLabel from '../components/TimespanLabel';
import TimespansService from '../services/Timespans';
import './Timespans.css';

const MAX_NODE_LEVEL = 3;
const TYPE_LEADERSHIP = 'Leadership';
const TYPE_PERIOD = 'Period';

const Timespans = () => (
  <AccordionList
    canAddItem={(item) => item.node_level < MAX_NODE_LEVEL && item.timespan_type !== TYPE_PERIOD}
    canEditItem={(item) => Session.isAdmin() || item.timespan_type !== TYPE_PERIOD}
    canDeleteItem={(item) => Session.isAdmin() || item.timespan_type !== TYPE_PERIOD}
    className='timespans'
    collectionName='timespans'
    getChildItems={(items, item) => _.where(items, { parent_timespan_id: item.id })}
    getRootItems={(items) => _.where(items, { parent_timespan_id: null })}
    modal={{
      component: TimespanModal,
      onAddItem: (item) => ({ parent_timespan_id: item.id, parent_timespan: item }),
      props: {
        onInitialize: (id) => TimespansService.fetchOne(id).then(({ data }) => data.timespan),
        defaults: {
          timespan_type: TYPE_LEADERSHIP
        },
        required: ['timespan_type', 'name_english']
      }
    }}
    onDelete={(item) => TimespansService.delete(item).then(({ data }) => data.timespan)}
    onSave={(item) => TimespansService.save(item).then(({ data }) => data.timespan)}
    onSearch={(parentId, search) => TimespansService.fetchAll({ parent_timespan_id: parentId, search })}
    renderItem={(item) => (
      <Grid
        columns={2}
      >
        <Grid.Column
          verticalAlign='middle'
          width={12}
        >
          <TimespanLabel
            type={item.timespan_type}
          />
          { item.searchable_name }
        </Grid.Column>
        <Grid.Column
          className='arabic'
          verticalAlign='middle'
          width={4}
        >
          { item.name_arabic }
        </Grid.Column>
      </Grid>
    )}
    showToggle={(item) => item.child_count && item.timespan_type !== TYPE_PERIOD}
  />
);

export default Timespans;
