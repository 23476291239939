// @flow

import BaseService from './BaseService';

/**
 * Service class for all data field based API calls.
 */
class DataFields extends BaseService {
  /**
   * Returns the data fields base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/data_fields';
  }
}

export default new DataFields();
