// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import RulingsService from '../services/Rulings';
import RulingModal from '../components/RulingModal';

type Props = {
  t: (key: string) => string
};

const Rulings = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='rulings'
    collectionName='rulings'
    columns={[{
      name: 'name',
      label: props.t('Rulings.columns.name'),
      sortable: true
    }, {
      name: 'description',
      label: props.t('Rulings.columns.description'),
      sortable: true
    }, {
      name: 'answer',
      label: props.t('Rulings.columns.answer'),
      sortable: true
    }, {
      name: 'basis',
      label: props.t('Rulings.columns.basis'),
      sortable: true
    }]}
    modal={{
      component: RulingModal,
      props: {
        onInitialize: (id) => RulingsService.fetchOne(id).then(({ data }) => data.ruling)
      }
    }}
    onDelete={(ruling) => RulingsService.delete(ruling)}
    onLoad={(params) => RulingsService.fetchAll(params)}
    onSave={(ruling) => RulingsService.save(ruling)}
    showRecordCount
  />
);

export default withTranslation()(Rulings);
