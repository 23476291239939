// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import { Label } from 'semantic-ui-react';
import _ from 'underscore';
import PlaceModal from '../components/PlaceModal';
import PlacesFiltersModal from '../components/PlacesFiltersModal';
import PlacesService from '../services/Places';

type Props = {
  t: (key: string) => string
};

const Places = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='places'
    collectionName='places'
    columns={[{
      name: 'name->>english',
      label: props.t('Places.columns.name.english'),
      resolve: (place) => place.name_english,
      sortable: true
    }, {
      name: 'name->>arabic',
      label: props.t('Places.columns.name.arabic'),
      resolve: (place) => place.name_arabic,
      sortable: true,
      hidden: true
    }, {
      name: 'name->>transliteration',
      label: props.t('Places.columns.name.transliteration'),
      resolve: (place) => place.name_transliteration,
      sortable: true,
      hidden: true
    }, {
      name: 'lat',
      label: props.t('Places.columns.lat'),
      sortable: true
    }, {
      name: 'lng',
      label: props.t('Places.columns.lng'),
      sortable: true
    }, {
      name: 'period',
      label: props.t('Places.columns.period'),
      render: (place) => _.map(place.period, (period) => (
        <Label
          content={period}
        />
      )),
      sortable: true
    }]}
    filters={{
      component: PlacesFiltersModal,
      props: {
        period: []
      }
    }}
    modal={{
      component: PlaceModal
    }}
    onDelete={(place) => PlacesService.delete(place)}
    onLoad={(params) => PlacesService.fetchAll(params)}
    onSave={(place) => PlacesService.save(place)}
    showRecordCount
  />
);

export default withTranslation()(Places);
