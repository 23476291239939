// @flow

import _ from 'underscore';
import Categorizations from './Categorizations';
import Citations from './Citations';
import Footnotes from './Footnotes';
import FuzzyDate from './FuzzyDate';
import Locations from './Locations';
import Participations from './Participations';
import References from './References';

import type { Case as CaseType } from '../types/Case';

/**
 * Class for handling transforming case records.
 */
class Case {
  PAYLOAD_KEYS = [
    'short_name',
    'case_type',
    'description',
    'issue_presented',
    'holding',
    'date_description',
    'exact_date'
  ];

  /**
   * Returns the passed case as a dropdown option.
   *
   * @param c
   *
   * @returns {{text: string, value: *, key: *}}
   */
  toDropdown(c: CaseType) {
    return {
      key: c.id,
      value: c.id,
      text: c.searchable_name
    };
  }

  /**
   * Returns the case object to be sent to the server on POST/PUT requests.
   *
   * @param c
   *
   * @returns {*}
   */
  toPayload(c: CaseType) {
    return {
      case: {
        ..._.pick(c, this.PAYLOAD_KEYS),
        ...FuzzyDate.toPayload(c, 'start_date'),
        ...FuzzyDate.toPayload(c, 'end_date'),
        ...Categorizations.toPayload(c),
        ...Citations.toPayload(c),
        ...Footnotes.toPayload(c),
        ...Locations.toPayload(c),
        ...Participations.toPayload(c),
        ...References.toPayload(c)
      }
    };
  }
}

export default new Case();
