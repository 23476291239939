// @flow

import React from 'react';
import { AccordionList } from 'react-components';
import { Grid } from 'semantic-ui-react';
import _ from 'underscore';
import IssueModal from '../components/IssueModal';
import IssuesService from '../services/Issues';

const MAX_NODE_LEVEL = 2;

const Issues = () => (
  <AccordionList
    canAddItem={(issue) => issue.node_level < MAX_NODE_LEVEL}
    collectionName='issues'
    getChildItems={(issues, issue) => _.where(issues, { parent_issue_id: issue.id })}
    getRootItems={(issues) => _.where(issues, { parent_issue_id: null })}
    modal={{
      component: IssueModal,
      onAddItem: (issue) => ({ parent_issue_id: issue.id, parent_issue: issue })
    }}
    onDelete={(issue) => IssuesService.delete(issue).then(({ data }) => data.issue)}
    onSave={(issue) => IssuesService.save(issue).then(({ data }) => data.issue)}
    onSearch={(parentId, search) => IssuesService.fetchAll({ parent_issue_id: parentId, search })}
    renderItem={(issue) => (
      <Grid
        columns={2}
      >
        <Grid.Column
          verticalAlign='middle'
        >
          { issue.name_english }
        </Grid.Column>
        <Grid.Column
          className='arabic'
          verticalAlign='middle'
        >
          { issue.name_arabic }
        </Grid.Column>
      </Grid>
    )}
    showToggle={(issue) => issue.node_level < MAX_NODE_LEVEL}
  />
);

export default Issues;
