// @flow

import React from 'react';
import { ListTable } from 'react-components';
import { withTranslation } from 'react-i18next';
import OrganizationModal from '../components/OrganizationModal';
import OrganizationsService from '../services/Organizations';

type Props = {
  t: (key: string) => string
};

const Organizations = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='organizations'
    collectionName='organizations'
    columns={[{
      name: 'name',
      label: props.t('Organizations.columns.name'),
      sortable: true
    }]}
    modal={{
      component: OrganizationModal,
      props: {
        onInitialize: (id) => (
          OrganizationsService
            .fetchOne(id)
            .then(({ data }) => data.organization)
        ),
        required: ['name']
      }
    }}
    onDelete={(organization) => OrganizationsService.delete(organization)}
    onLoad={(params) => OrganizationsService.fetchAll(params)}
    onSave={(organization) => OrganizationsService.save(organization)}
    showRecordCount
  />
);

export default withTranslation()(Organizations);
