// @flow

import './Bibliographies.css';

import { Icon } from 'semantic-ui-react';
import { ListTable } from 'react-components';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, type RouterHistory } from 'react-router-dom';
import Session from '../services/Session';
import BibliographyService from '../services/Bibliography';
import BibliographyModal from '../components/BibliographyModal';
import Bibliography from '../transforms/Bibliography';

type Props = {
  t: (key: string) => string,
  history: RouterHistory,
};

const Bibliographies = (props: Props) => {
  const userName = Session.getUsername();

  return (
    <ListTable
      actions={[{
        name: 'discover',
        icon: 'search',
        popup: {
          content: props.t('Bibliographies.actions.discover.content'),
          title: props.t('Bibliographies.actions.discover.title')
        },
        onClick: (item) => props.history.push(`/entry/bibliographies/${item.id}`)
      }, {
        name: 'edit'
      }, {
        name: 'copy'
      }, {
        name: 'delete'
      }]}
      className='bibliographies'
      collectionName='bibliographies'
      columns={[{
        name: 'name->>english',
        label: props.t('Bibliographies.columns.name'),
        render: (b) => b.name_english,
        sortable: true
      }, {
        name: 'description',
        label: props.t('Bibliographies.columns.description'),
        render: (b) => b.description,
        sortable: false
      }, {
        name: 'bibliographies_sources',
        className: 'narrow-column',
        label: props.t('Bibliographies.columns.totalSources'),
        render: (b) => b.bibliographies_sources.length,
        sortable: false
      }, {
        name: 'public',
        label: props.t('Bibliographies.columns.public'),
        className: 'narrow-column',
        render: (b) => (b.public
          ? (
            <p style={{ textAlign: 'center' }}>
              <Icon name='check' />
            </p>
          )
          : ''),
        sortable: false
      }, {
        name: 'owner',
        className: 'narrow-column',
        label: props.t('Bibliographies.columns.owner'),
        sortable: true
      }]}
      modal={{
        component: BibliographyModal,
        props: {
          onInitialize: (id) => BibliographyService.fetchOne(id).then((resp) => resp.data.bibliography)
        }
      }}
      onCopy={(b) => Bibliography.toCopy(b)}
      onDelete={(b) => BibliographyService.delete(b)}
      onLoad={(params) => BibliographyService.fetchAll({ ...params, owner: userName })}
      onSave={(b) => {
        const bib = { ...b, owner: b.owner ? b.owner : userName };
        return BibliographyService.save(bib);
      }}
      showRecordCount
    />
  );
};

export default withTranslation()(withRouter(Bibliographies));
