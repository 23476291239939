// @flow

import BaseService from './BaseService';
import Ruling from '../transforms/Ruling';

/**
 * Service class for all ruling based API calls.
 */
class Rulings extends BaseService {
  /**
   * Returns the ruling base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/rulings';
  }

  /**
   * Returns the rulings transform object.
   *
   * @returns {Case}
   */
  getTransform() {
    return Ruling;
  }
}

export default new Rulings();
