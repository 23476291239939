// @flow

import _ from 'underscore';
import type { DataFieldOption as DataFieldOptionType } from '../types/DataFieldOption';

/**
 * Class for handling transforming data field option records.
 */
class DataFieldOption {
  PAYLOAD_KEYS = [
    'table',
    'column',
    'value',
    'description'
  ];

  /**
   * Converts the passed data field option to a dropdown option.
   *
   * @param option
   *
   * @returns {{description: string, text: string, value: string, key: string}}
   */
  toDropdown(option: DataFieldOptionType) {
    return {
      key: option.value,
      value: option.value,
      text: option.value,
      description: option.description
    };
  }

  /**
   * Returns the data field option object to be sent to the server on POST/PUT requests.
   *
   * @param option
   *
   * @returns {*}
   */
  toPayload(option: DataFieldOptionType) {
    return { data_field_option: _.pick(option, this.PAYLOAD_KEYS) };
  }
}

export default new DataFieldOption();
