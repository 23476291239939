// @flow

import _ from 'underscore';
import FuzzyDate from './FuzzyDate';
import Locations from './Locations';
import TimespanPeriods from './TimespanPeriods';

import type { Timespan as TimespanType } from '../types/Timespan';

/**
 * Class for handling transforming timespan records.
 */
class Timespan {
  PAYLOAD_KEYS = [
    'name_arabic',
    'name_english',
    'name_transliteration',
    'parent_timespan_id',
    'timespan_type'
  ];

  /**
   * Returns the timespan object to be sent to the server on POST/PUT requests.
   *
   * @param timespan
   *
   * @returns {*}
   */
  toPayload(timespan: TimespanType) {
    return {
      timespan: {
        ..._.pick(timespan, this.PAYLOAD_KEYS),
        ...FuzzyDate.toPayload(timespan, 'start_date_ah'),
        ...FuzzyDate.toPayload(timespan, 'start_date_ce'),
        ...FuzzyDate.toPayload(timespan, 'end_date_ah'),
        ...FuzzyDate.toPayload(timespan, 'end_date_ce'),
        ...Locations.toPayload(timespan),
        ...TimespanPeriods.toPayload((timespan))
      }
    };
  }
}

export default new Timespan();
