// @flow

import _ from "underscore";
import Categorizations from "./Categorizations";
import Footnotes from "./Footnotes";
import FuzzyDate from "./FuzzyDate";
import Locations from "./Locations";
import Participations from "./Participations";
import Holdings from "./Holdings";
import ParentSourceRelations from "./ParentSourceRelations";
import ChildSourceRelations from "./ChildSourceRelations";
import type { Source as SourceType } from "../types/Source";

/**
 * Class for handling transforming source records.
 */
class Source {
  PAYLOAD_KEYS = [
    "title",
    "genre",
    "project",
    "short_title",
    "language",
    "source_type",
    "call_number",
    "number_volumes",
    "number_pages",
    "isbn",
    "publication_title",
    "doi",
    "issn",
    "url",
    "edition",
    "series_title",
    "series_number",
    "archive",
    "archive_location",
    "manual_tags",
    "vetted_by",
    "stacklife_id",
  ];

  /**
   * Returns a copy of the passed source.
   *
   * @param source
   *
   * @returns {{sources: any[]}}
   */
  toCopy(source: SourceType) {
    const removeRecordIds = (arr: Array<Object>) =>
      arr.map((e) => _.omit(e, "id"));
    return {
      ..._.omit(source, "id", "vetted_by", "bibliographies_sources"),
      child_source_relations: [],
      parent_source_relations: [],
      categorizations: removeRecordIds(source.categorizations),
      footnotes: removeRecordIds(source.footnotes),
      locations: removeRecordIds(source.locations),
      participations: removeRecordIds(source.participations),
      holdings: removeRecordIds(source.holdings),
      publication_date_ah: _.omit(source.publication_date_ah, "id"),
      publication_date_ce: _.omit(source.publication_date_ce, "id"),
    };
  }

  /**
   * Returns the passed source as a dropdown option.
   *
   * @param source
   *
   * @returns {{text: string, value: *, key: *}}
   */
  toDropdown(source: SourceType) {
    return {
      key: source.id,
      value: source.id,
      text: source.searchable_name,
    };
  }

  /**
   * Returns the source object to be sent to the server on POST/PUT requests.
   *
   * @param source
   *
   * @returns {*}
   */
  toPayload(source: SourceType) {
    return {
      source: {
        ..._.pick(source, this.PAYLOAD_KEYS),
        ...FuzzyDate.toPayload(source, "publication_date_ah"),
        ...FuzzyDate.toPayload(source, "publication_date_ce"),
        ...Categorizations.toPayload(source),
        ...Footnotes.toPayload(source),
        ...Locations.toPayload(source),
        ...Participations.toPayload(source),
        ...Holdings.toPayload(source),
        ...ParentSourceRelations.toPayload(source),
        ...ChildSourceRelations.toPayload(source),
      },
    };
  }
}

export default new Source();
