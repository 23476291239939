// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import {
  AssociatedDropdown,
  type EditModalProps
} from 'react-components';
import DataFieldDropdown from './DataFieldDropdown';
import SourceTransform from '../transforms/Source';
import Sources from '../services/Sources';
import SourceModal from './SourceModal';

import type { Source } from '../types/Source';

type Props = EditModalProps & {
  item: Source
};

const SourceRelationModal = (props: Props) => {
  const { relation } = props;

  const relationLabel = {
    parent: {
      header: 'baseTextTitle',
      title: 'addBaseText'
    },
    child: {
      header: 'commentaryTitle',
      title: 'addCommentary'
    }
  };

  return (
    <Modal
      as={Form}
      className='source-relation-modal'
      closeIcon
      onClose={props.onClose}
      noValidate
      open
      size='small'
    >
      <Modal.Header
        content={props.t(`SourceModal.title.${relationLabel[relation].title}`)}
      />
      <Modal.Content>
        <Form.Input
          error={props.isError(`${relation}_source_id`)}
          label={props.t(`SourceModal.labels.${relationLabel[relation].header}`)}
          required={props.isRequired(`${relation}_source_id`)}
        >
          <AssociatedDropdown
            collectionName='sources'
            onSearch={(search) => Sources.fetchAll({
              search,
              sort_by: 'title'
            })}
            modal={{
              component: SourceModal,
              onSave: (item) => Sources.save(item).then(({ data }) => data.source),
              props: {
                onInitialize: (id) => Sources.fetchOne(id).then(({ data }) => data.source)
              }
            }}
            onSelection={props.onAssociationInputChange.bind(this, `${relation}_source_id`, `${relation}_source`)}
            renderOption={SourceTransform.toDropdown.bind(this)}
            searchQuery={props.item[`${relation}_source`] && props.item[`${relation}_source`].searchable_name}
            value={props.item[`${relation}_source_id`]}
          />
        </Form.Input>

        <DataFieldDropdown
          {...props}
          column='relation_type'
          label={props.t('SourceModal.labels.relationType')}
          table='source_relations'
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(SourceRelationModal);
