// @flow
import uuid from 'react-uuid';
import _ from 'underscore';

import type { Bibliography as BibliographyType } from '../types/Bibliography';

/**
 * Class for handling transforming bibliography records.
 */
class Bibliography {
  PAYLOAD_KEYS = [
    'id',
    'name_arabic',
    'name_english',
    'name_transliteration',
    'public',
    'search_type',
    'owner',
    'description',
    'bibliographies_sources',
  ];

  /**
   * Returns a copy of the passed bibliography.
   *
   * @param bibliography
   *
   * @returns {{bibliographies_sources: any[]}}
   */
  toCopy(bibliography: BibliographyType) {
    return {
      ..._.omit(bibliography, 'id'),
      bibliographies_sources: _.map(bibliography.bibliographies_sources, (bibliographySource) => ({
        ..._.omit(bibliographySource, 'id'),
        uid: uuid()
      }))
    };
  }

  /**
   * Returns the bibliography object to be sent to the server on POST/PUT requests.
   *
   * @param bibliography
   *
   * @returns {*}
   */
  toPayload(bibliography: BibliographyType) {
    return {
      bibliography: {
        ..._.pick(bibliography, this.PAYLOAD_KEYS),
      }
    };
  }
}

export default new Bibliography();
