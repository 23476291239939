// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListTable } from 'react-components';
import FatwaModal from '../components/FatwaModal';
import FatwasService from '../services/Fatwas';

type Props = {
  t: (key: string) => string
};

const Fatwas = (props: Props) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='fatwas'
    collectionName='fatwas'
    columns={[{
      name: 'question',
      label: props.t('Fatwas.columns.question'),
      sortable: true
    }, {
      name: 'format',
      label: props.t('Fatwas.columns.format'),
      sortable: true
    }, {
      name: 'answer',
      label: props.t('Fatwas.columns.answer'),
      sortable: true
    }]}
    modal={{
      component: FatwaModal,
      props: {
        onInitialize: (id) => FatwasService.fetchOne(id).then(({ data }) => data.fatwa)
      }
    }}
    onDelete={(fatwa) => FatwasService.delete(fatwa)}
    onLoad={(params) => FatwasService.fetchAll(params)}
    onSave={(fatwa) => FatwasService.save(fatwa)}
    showRecordCount
  />
);

export default withTranslation()(Fatwas);
