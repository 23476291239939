// @flow
import React from 'react';
import { type EditModalProps } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DataFieldDropdown from './DataFieldDropdown';

import type { Footnote } from '../types/Footnote';

type Props = EditModalProps & {
  item: Footnote
};

const FootnoteModal = (props: Props) => (
  <Modal
    as={Form}
    className='footnote-modal'
    closeIcon
    onClose={props.onClose}
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('FootnoteModal.title.edit')
        : props.t('FootnoteModal.title.add')}
    />
    <Modal.Content>
      <DataFieldDropdown
        {...props}
        column='label'
        label={props.t('FootnoteModal.labels.label')}
        table='footnotes'
      />
      <Form.TextArea
        error={props.isError('content')}
        label={props.t('FootnoteModal.labels.content')}
        onChange={props.onTextInputChange.bind(this, 'content')}
        required={props.isRequired('content')}
        value={props.item.content || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(FootnoteModal);
